import {
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import { CustomTreeView } from '../../common/CustomTreeView/CustomTreeView'
import downloadJsonFile from '../../common/Download/DownloadJson/DownloadJson'
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback } from 'react'
import { uiStartLoading } from '../../../reducers/ui/uiReducer'
import ReplayIcon from '@mui/icons-material/Replay'
import FullScreenPaper from '../../common/FullScreenPaper/FullScreenPaper'

export const NodesTelechargeNBIoTResult = React.memo(
  ({ result, history, reset, handleChangeResult, send }) => {
    const loading = useSelector(state => state.ui.loading)

    const dispatch = useDispatch()

    const handleDownload = () => {
      if (result && result.typeof === 'object') {
        downloadJsonFile(result.result, `result_${result?.date}`)
      } else {
        downloadJsonFile(
          result.result,
          `result_${result?.date}`,
          'txt',
          'text/json'
        )
      }
    }

    const handleClickList = useCallback(
      path => {
        dispatch(uiStartLoading())
        reset({
          topic: 'files',
          command: 'READ',
          path: path,
          payload: ''
        })
        send('files', {
          command: 'READ',
          path: path,
          payload: ''
        })
      },
      [send, reset, dispatch]
    )

    const handleReloadList = () => {
      dispatch(uiStartLoading())
      reset({
        topic: 'files',
        command: 'LIST',
        path: '',
        payload: ''
      })
      send('files', {
        command: 'LIST',
        path: '',
        payload: ''
      })
    }

    return (
      <>
        {/*=========================================================================================*/}
        {/*==================================== RESULTADO ==========================================*/}
        {/*=========================================================================================*/}

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Divider>LIST AND RESULTS</Divider>
            <Button
              onClick={handleReloadList}
              startIcon={<ReplayIcon />}
              variant='contained'
              color={'secondary'}
              size='small'
              fullWidth
            >
              LIST
            </Button>
          </Grid>
          {result.typeof === 'object' ? (
            <Grid item xs={12}>
              <Grid item xs={12}>
                {result.command === 'LIST' ? (
                  <CustomTreeView
                    files={result.result}
                    onCLick={handleClickList}
                  />
                ) : (
                  <FullScreenPaper>
                    <pre style={{ padding: 10 }}>
                      {loading ? (
                        <CircularProgress color='primary' />
                      ) : (
                        JSON.stringify(result, null, 3)
                      )}
                    </pre>
                  </FullScreenPaper>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Divider>RESULT</Divider>
              <FullScreenPaper>
                {loading ? (
                  <CircularProgress color='primary' />
                ) : (
                  <p style={{ padding: 10 }}>
                    {result?.result && typeof result.result === 'string'
                      ? result.result
                      : JSON.stringify(result.result)}
                  </p>
                )}
              </FullScreenPaper>
            </Grid>
          )}

          {/*=========================================================================================*/}
          {/*======================================== HISTÓRICO ======================================*/}
          {/*=========================================================================================*/}
          <Grid item xs={12}>
            <ButtonGroup size='small' variant='contained'>
              <Button
                onClick={() => handleDownload()}
                disabled={!(result && result?.result)}
              >
                DOWNLOAD
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <Divider>HISTORY</Divider>
            <List
              component='nav'
              aria-label='options'
              style={{ maxHeight: 150, overflow: 'auto' }}
            >
              {history &&
                history.sort((a, b) => new Date(b.date) - new Date(a.date)).map((item, i) => (
                  <ListItem
                    key={i}
                    button='true'
                    onClick={() => handleChangeResult(item)}
                  >
                    <ListItemText
                      primary={item.date}
                      secondary={`${item.request.data.command} - ${item.request.data.path}`}
                    />
                  </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
      </>
    )
  }
)



import React, { useState } from 'react';
import { Switch, FormControlLabel, Grid, TextField, Box, Button, Tooltip, IconButton } from '@mui/material';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Swal from 'sweetalert2'; // Importar sweetalert2 para mostrar los mensajes
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import markerIconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import GeoSearch from '../../../AdvancedMonitoringMapScreen/Map/GeoSearch/GeoSearch';
import { fetchConToken } from '../../../../helpers/fetch';
import { NewUsersContract } from './NewUsersContract/NewUsersContract';

const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIconRetina,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const LocationMarker = ({ setCoordinates }) => {
  const [position, setPosition] = useState(null);

  useMapEvents({
    click(e) {
      const { lat, lng } = e.latlng;
      setPosition([lat, lng]);
      setCoordinates(lat, lng);
    },
  });

  return position ? (
    <Marker position={position}>
      <Popup>
        Latitud: {position[0]}, Longitud: {position[1]}
      </Popup>
    </Marker>
  ) : null;
};

export const NewContractForm = () => {
  const [usersData, setUsersData] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    number_of_gateways: 1,
    shipping_frequency: 24,
    storage_frequency: 0,
    mask_of_measures_to_store: 0,
    status_holidays: 1,
    alarms_mask: 143,
    min_voltage: 200,
    max_voltage: 250,
    min_current: 20,
    max_current: 1500,
    max_temperature: 70,
    mask_of_moments_to_store: 0,
    status_flag: 0,
    location: '',
    longitude: '',
    latitude: '',
    warning_mail_1: '',
    warning_mail_2: '',
    warning_mail_3: '',
    p2p_version: 0,
    panel_version: 0,
    failure_window_in_hours: 72,
    failure_window_in_hours_panels: 24,
    default_dimming_profile_id: 0,
    default_exception_profile_id: 0,
    only_ip: 0,
    maintenance: 0,
    default_dimming: 60,
    u6me2: 0,
    smart_lighting_analytics: 0,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? (checked ? 1 : 0) : value,
    });
  };

  const setCoordinates = (lat, lng) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      latitude: lat.toString(),
      longitude: lng.toString(),
    }));
  };

  // Función para recibir y actualizar la lista de usuarios seleccionados
  const handleUserSelectionChange = (selectedUsers) => {
    const updatedUsers = selectedUsers.map((user) => ({
      ...user,
      name: formData.name, // Actualiza `name` en cada usuario
    }));
    setUsersData(updatedUsers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.location || !formData.latitude || !formData.longitude) {
      Swal.fire({
        icon: 'warning',
        title: 'Campos incompletos',
        text: 'Debes completar todos los campos del formulario antes de crear un nuevo contrato.',
      });
      return;
    }

    const payload = [formData, usersData];
    //console.log("Contratos y usuarios", payload);

    try {
      const response = await fetchConToken('/contract/newcontract', payload, 'POST');

      if (response.ok) {
        const data = await response.json();
        Swal.fire({
          icon: 'success',
          title: 'Contrato creado',
          text: `El contrato fue creado con éxito. ID del nuevo contrato: ${data.idContract}`,
        });
        //console.log('Datos del formulario enviados:', payload);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Hubo un problema al crear el contrato.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error inesperado.',
      });
    }
  };

  const handleReset = () => {
    setFormData({
      name: '',
      number_of_gateways: 1,
      shipping_frequency: 24,
      storage_frequency: 2,
      mask_of_measures_to_store: 0,
      status_holidays: 1,
      alarms_mask: 0,
      min_voltage: 220,
      max_voltage: 240,
      min_current: 10,
      max_current: 15,
      max_temperature: 60,
      mask_of_moments_to_store: 0,
      status_flag: 0,
      location: '',
      longitude: '',
      latitude: '',
      warning_mail_1: '',
      warning_mail_2: '',
      warning_mail_3: '',
      p2p_version: 0,
      panel_version: 0,
      failure_window_in_hours: 24,
      failure_window_in_hours_panels: 24,
      default_dimming_profile_id: 0,
      default_exception_profile_id: 0,
      only_ip: 0,
      maintenance: 0,
      default_dimming: 0,
      u6me2: 0,
      smart_lighting_analytics: 0,
    });
    setUsersData([]);
  };

  return (
    <Box sx={{ maxWidth: 900, mx: 'auto', p: 3 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <TextField
              fullWidth
              label="Nombre del contrato"
              name="name"
              variant="outlined"
              value={formData.name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Introduzca el nombre del contrato, que sera visible para todos los usuarios que tengan acceso" arrow>
              <IconButton>
                <HelpCenterIcon style={{ color: 'gold' }} />
              </IconButton>
            </Tooltip>
          </Grid>



          <Grid item xs={11}>
            <TextField
              fullWidth
              label="Número de Gateways"
              name="number_of_gateways"
              type="number"
              variant="outlined"
              value={formData.number_of_gateways}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Inserte el número de gateways máximo que debe tener el contrato" arrow>
              <IconButton>
                <HelpCenterIcon style={{ color: 'gold' }} />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item xs={11}>
            <TextField
              fullWidth
              label="Frecuencia de Envío"
              name="shipping_frequency"
              type="number"
              variant="outlined"
              value={formData.shipping_frequency}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Inserte la frecuencia de envío de medidas" arrow>
              <IconButton>
                <HelpCenterIcon style={{ color: 'gold' }} />
              </IconButton>
            </Tooltip>
          </Grid>
        
          {/*<Grid item xs={11}>
            <TextField
              fullWidth
              label="Refrescar Festivo"
              name="status_holidays"
              type="number"
              variant="outlined"
              inputProps={{ min: -1, max: 2 }}
              value={formData.status_holidays}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="hola" arrow>
              <IconButton>
                <HelpCenterIcon style={{ color: 'gold' }} />
              </IconButton>
            </Tooltip>
          </Grid>
          
          

          <Grid item xs={5}>
            <TextField
              fullWidth
              label="Versión P2P"
              name="p2p_version"
              type="number"
              variant="outlined"
              value={formData.p2p_version}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={5}>
            <TextField
              fullWidth
              label="Versión CM"
              name="panel_version"
              type="number"
              variant="outlined"
              value={formData.panel_version}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="column" justifyContent="center" alignItems="flex-end">
              <Tooltip title="Información sobre versiones" arrow>
                <IconButton>
                  <HelpCenterIcon style={{ color: 'gold' }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          */}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Box display="flex" alignItems="center">
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.maintenance === 1}
                          onChange={handleInputChange}
                          name="maintenance"
                        />
                      }
                      label="Mantenimiento"
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip title="Activar si el contrato incluye servicio de mantenimiento" arrow>
                      <IconButton>
                        <HelpCenterIcon style={{ color: 'gold' }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box display="flex" alignItems="center">
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.only_ip === 1}
                          onChange={handleInputChange}
                          name="only_ip"
                        />
                      }
                      label="Only_IP"
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip title="Activar si es NbIoT" arrow>
                      <IconButton>
                        <HelpCenterIcon style={{ color: 'gold' }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box display="flex" alignItems="center">
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.u6me2 === 1}
                          onChange={handleInputChange}
                          name="u6me2"
                        />
                      }
                      label="TRIDONIC"
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip title="Soporta driver TRIDONIC" arrow>
                      <IconButton>
                        <HelpCenterIcon style={{ color: 'gold' }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

    
          </Grid>

          <Grid item xs={11}>
            <TextField
              fullWidth
              label="Municipio"
              name="location"
              variant="outlined"
              value={formData.location}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={1}>
            <Grid container direction="column" justifyContent="center" alignItems="flex-end">
              <Tooltip title="Información sobre versiones" arrow>
                <IconButton>
                  <HelpCenterIcon style={{ color: 'gold' }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid item xs={5}>
            <TextField
              fullWidth
              label="GPS Latitud"
              name="latitude"
              variant="outlined"
              value={formData.latitude}
              onChange={handleInputChange}
              disabled
            />
          </Grid>

          <Grid item xs={5}>
            <TextField
              fullWidth
              label="GPS Longitud"
              name="longitude"
              variant="outlined"
              value={formData.longitude}
              onChange={handleInputChange}
              disabled
            />
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="column" justifyContent="center" alignItems="flex-end">
              <Tooltip title="Los valores se rellenaran automaticammente al hacer click sobre un punto del mapa" arrow>
                <IconButton>
                  <HelpCenterIcon style={{ color: 'gold' }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <MapContainer center={[40.40, -3.70]} zoom={6} style={{ height: 400, width: '100%' }}>
              <GeoSearch />
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <LocationMarker setCoordinates={setCoordinates} />
            </MapContainer>
          </Grid>



          <Grid item xs={12}>
            <NewUsersContract onSelectedUsersChange={handleUserSelectionChange} />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <Grid item xs={6} pr={1}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Enviar
              </Button>
            </Grid>
            <Grid item xs={6} pl={1}>
              <Button type="button" variant="outlined" color="secondary" onClick={handleReset} fullWidth>
                Resetear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form >
    </Box >
  );
};












/*
import React, { useState } from 'react';
import { Switch, FormControlLabel, Grid } from '@mui/material';
import { useForm } from '../../../../hooks/useForm';
import DynamicForm from '../../../common/DynamicForm/DynamicForm';


export const NewContractForm = () => {
  // Estado local para gestionar la selección de Num_gateways
  const [numGateways, setNumGateways] = useState('CM');
  const [p2pEnabled, setP2pEnabled] = useState(false);

  // Usamos el hook useForm para manejar el estado de los valores del formulario
  const [formData, handleInputChange, reset] = useForm({
    contract: '',
    num_gateways_type: 'CM',
    num_gateways_value: 1,
    frecuencia_envio: 0,
    refrescar_festivo: 1,
    municipio: '',
    gps_longitud: '',
    gps_latitud: '',
    version_p2p: 0,
    version_cm: 1,
    mantenimiento: 0
  });

  // Maneja el cambio de tipo de gateway (CM, P2P o P2P/CM)
  const handleNumGatewaysChange = (e) => {
    const value = e.target.value;
    setNumGateways(value);
    setP2pEnabled(value === 'P2P');
    // Actualizar los campos relacionados con num_gateways
    handleInputChange({
      target: {
        name: 'num_gateways_type',
        value: value,
      }
    });
    handleInputChange({
      target: {
        name: 'num_gateways_value',
        value: value === 'P2P' ? 1 : 0,
      }
    });
    handleInputChange({
      target: {
        name: 'frecuencia_envio',
        value: value === 'P2P' ? 0 : 0,
      }
    });
    handleInputChange({
      target: {
        name: 'version_p2p',
        value: value === 'P2P' ? 2 : 0,
      }
    });
    handleInputChange({
      target: {
        name: 'version_cm',
        value: value === 'CM' ? 1 : 0,
      }
    });
  };

  const handleMantenimientoChange = (e) => {
    const { name, checked } = e.target;
    handleInputChange({
      target: {
        name: name,
        value: checked ? 1 : 0,
      }
    });
  };

  const fields = [
    { name: 'contract', label: 'Contrato', type: 'text', value: formData.contract },
    {
      name: 'num_gateways_value',
      label: 'Número de Gateways',
      type: 'number',
      value: formData.num_gateways_value,
    },
    {
      name: 'frecuencia_envio',
      label: 'Frecuencia de Envío',
      type: 'number',
      value: formData.frecuencia_envio,
      readOnly: !p2pEnabled
    },
    { name: 'refrescar_festivo', label: 'Refrescar festivo', type: 'number', value: formData.refrescar_festivo },
    { name: 'municipio', label: 'Municipio', type: 'text', value: formData.municipio },
    { name: 'gps_longitud', label: 'GPS Longitud', type: 'text', value: formData.gps_longitud },
    { name: 'gps_latitud', label: 'GPS Latitud', type: 'text', value: formData.gps_latitud },
    {
      name: 'version_p2p',
      label: 'Versión P2P',
      type: 'number',
      value: formData.version_p2p,
      readOnly: true
    },
    {
      name: 'version_cm',
      label: 'Versión CM',
      type: 'number',
      value: formData.version_cm,
      readOnly: true
    },
    {
      name: 'mantenimiento',
      label: 'Mantenimiento',
      type: 'switch',
      value: formData.mantenimiento
    }
  ];

  return (
    <form>
      <Grid container spacing={2}>
        {fields.map((field, index) => {
          if (field.type === 'switch') {
            return (
              <Grid item xs={12} key={index}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData[field.name] === 1}
                      onChange={handleMantenimientoChange}
                      name={field.name}
                    />
                  }
                  label={field.label}
                />
              </Grid>
            );
          }

          return (
            <Grid item xs={12} key={index}>
              <DynamicForm
                fields={[field]} // Enviar solo el campo actual a DynamicForm
              />
            </Grid>
          );
        })}
      </Grid>
    </form>
  );
};
*/




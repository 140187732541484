


export const loadStatusGatewayText = (flag, gateway) => {
    const status_flag_text = [
        "",
        "{{name}} is being evaluated and sent to", { name: gateway.name },
        "¡{{name}} pending elimination!", { name: gateway.name },
    ]
    return status_flag_text[flag];
}

export const testFlagGateway = (flag) => (flag === 0);

export const canIUpdateGateway = (flag) => {
    if(testFlagGateway(flag)){
        return true;
    } else {
        return false;
    }
}
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    firsthFactorAuth: false,
    checking: true,
    user: null,
    token: null,
    error: null,
    loading: false,
    contracts: null,
    api: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authLogin: (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.contracts = action.payload.contracts;
            state.checking = false;
        },
        authCheckingFinish: (state) => {
            state.checking = false;
        },
        authSetFirsthFactorTrue: (state) => {
            state.firsthFactorAuth = true;
        },
        authSetFirsthFactorFalse: (state) => {
            state.firsthFactorAuth = false;
        },
    
        authLogout: (state) => {
            state.user = null;
            state.token = null;
            state.firsthFactorAuth = false;
            state.checking = false;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        removeError: (state) => {
            state.error = null;
        },
        startLoading: (state) => {
            state.loading = true;
        },
        finishLoading: (state) => {
            state.loading = false;
        },
    },
});

export const {
    authLogin,
    authCheckingFinish,
    authSetFirsthFactorTrue,
    authSetFirsthFactorFalse,
    authLogout,
    setError,
    removeError,
    startLoading,
    finishLoading,
} = authSlice.actions;




export default authSlice.reducer;




import React from 'react';
import { Button, Checkbox, FormControlLabel, FormHelperText, TextField, Typography } from '@mui/material';
import { useFormAuth } from '../../../../hooks/useForm';
import validator from 'validator';
//import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { uiSetError, uiRemoveError } from '../../../../reducers/ui/uiReducer';
import { startLoginEmailCode, startLoginEmailPassword } from '../../../../reducers/auth/authActions';
import { styled } from '@mui/system';
import { selectFirsthFactorAuth } from '../../../../reducers/auth/authSelectors';
import { authSetFirsthFactorFalse } from '../../../../reducers/auth/authReducer';
import { SelectApi } from '../../../common/SelectApi/SelectApi';

const FormWrapper = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

export const Form = () => {
  const dispatch = useDispatch();
  const msgError = useSelector(state => state.ui.msgError);
  const firsthFactorAuth = useSelector(selectFirsthFactorAuth);
  const oldEmail = localStorage.getItem('remember');

  const { formState, handleInputChange } = useFormAuth({
    email: oldEmail || '',
    password: '',
    remember: oldEmail ? true : false,
    code: ''
  });

  const { email, password, remember, code } = formState;

  if (remember) {
    localStorage.setItem('remember', email.trim());
  } else {
    localStorage.removeItem('remember');
  }

  const storageEmail = localStorage.getItem('email');

  const isFormValid1fa = () => {
    if (!validator.isEmail(email.trim())) {
        dispatch(uiSetError("Invalid email address"));
        return false;
    } else if (password.length === 0) {
        dispatch(uiSetError("Wrong password"));
        return false;
    }
    dispatch(uiRemoveError());
    return true;
  };

  const isFormValid2fa = () => {
    if (!validator.isEmail(storageEmail.trim())) {
        dispatch(uiSetError("Invalid email address"));
        return false;
    } else if (code.length === 0) {
        dispatch(uiSetError("Wrong code"));
        return false;
    }
    dispatch(uiRemoveError());
    return true;
  };

  const handleSubmit1fa = (e) => {
    e.preventDefault();
    if (isFormValid1fa()) {
        localStorage.setItem('email', email.trim());
        dispatch(startLoginEmailPassword({ email: email.trim(), password }));
        //dispatch(startLoginEmailPassword(email.trim(), password));
    }
  };

  const handleSubmit2fa = (e) => {
    e.preventDefault();
    if (isFormValid2fa()) {
      dispatch(startLoginEmailCode({ email: storageEmail.trim(), code }));
      //dispatch(startLoginEmailCode(storageEmail.trim(), code));
    }
  };

  const handleGoBack = () => {
    dispatch(authSetFirsthFactorFalse());
}

  return (
    <>
      {!firsthFactorAuth ? (
        <div className="animate__animated animate__zoomIn">
          <FormWrapper noValidate onSubmit={handleSubmit1fa}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={email}
              autoComplete="email"
              autoFocus
              onChange={handleInputChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              autoComplete="current-password"
              onChange={handleInputChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="remember"
                  color="primary"
                  checked={remember}
                  onChange={handleInputChange}
                />
              }
              label="Remember me"
            />
            <FormHelperText variant="outlined" error={true} disabled={!msgError}>
              {msgError}
            </FormHelperText>
            <SubmitButton type="submit" fullWidth variant="contained" color="primary">
              Login
            </SubmitButton>
          </FormWrapper>
        </div>
      ) : (
        <div>
          <Typography>
            {`${"Check your email inbox"}: ${storageEmail}`}
          </Typography>
          <form
            noValidate onSubmit={handleSubmit2fa}
          >
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="code"
                label={"code"}
                type="code"
                id="code"
                value={code}
                autoComplete="current-code"
                onChange={handleInputChange}
            />

            <Button
                type="button"
                onClick={handleGoBack}
            >Resend code</Button>

            <FormHelperText
                variant='outlined'
                error={true}
                disabled={(msgError) ? true : false}
            >
                {msgError}
            </FormHelperText>

            <SubmitButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                //className={classes.submit}
            >
                {"Login"}
            </SubmitButton>

          </form>
                   
        </div>
      )}
      <SelectApi/>
    </>
  );
};





import React, { useEffect, useState } from 'react';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';

// Función para construir el árbol inicial
const buildInitialTree = (files) => {
    if (!Array.isArray(files)) {
        console.error("El parámetro 'files' no es un array o está undefined.", files);
        return {}; // Retorna un árbol vacío si `files` no es válido
    }

    const root = {};
    files.forEach((file) => {
        const parts = file.split('/');
        let current = root;
        parts.forEach((part, index) => {
            if (!current[part]) {
                current[part] = index === parts.length - 1 ? null : {}; // Archivos son `null`
            }
            current = current[part];
        });
    });
    return root;
};

// Componente principal
export const CustomTreeView = React.memo(({ files=[], onCLick = () => {}, onReder = () => {} }) => {
    const [treeData] = useState(() => buildInitialTree(files || [])); // Si `files` es `undefined`, pasa un array vacío
    // Función recursiva para renderizar nodos

    const handleClick = (path) => {
        onCLick(path)
    }

    const renderTree = (nodes, parentPath = '') => {
        return Object.entries(nodes).map(([key, value]) => {
            const currentPath = parentPath === "" ? key : `${parentPath}/${key}`; // Generar un ID único basado en la ruta
            const isFile = value === null;
            if (isFile) {
                return <TreeItem
                    key={currentPath} // Usamos `currentPath` como clave única
                    itemId={currentPath}
                    label={key}
                    onClick={() => handleClick(currentPath)}
                />
            } else {
                return (
                    <TreeItem
                        key={currentPath} // Usamos `currentPath` como clave única
                        itemId={currentPath}
                        label={key}
                    >
                        {renderTree(value, currentPath)} {/* Renderizamos hijos solo si no es archivo */}
                    </TreeItem>
                );
            }


        })
    };

    useEffect(() => {
        onReder()
    }, [onReder])

    return (
        <SimpleTreeView
            aria-label="file system navigator"
            sx={{ height: 500, flexGrow: 1, overflowY: 'auto' }}
        >
            {renderTree(treeData)}
        </SimpleTreeView>
    );
});

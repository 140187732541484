

import React, { useEffect, useRef, useCallback } from 'react';
import {
  Avatar,
  Button,
  Container,
  Card,
  FormControlLabel,
  Grid,
  CardActions,
  Radio,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  RadioGroup,
  Typography,
  FormGroup,
  Checkbox,
  useTheme,
  CircularProgress
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import Swal from "sweetalert2";
import { styled } from '@mui/system';

import { useForm } from '../../../hooks/useForm';
import { uiRemoveError } from '../../../reducers/ui/uiReducer';
import { startContractsUpdate } from '../../../reducers/contracts/contractsActions';
import { CustomTitle } from '../../common/CustomTitle/CustomTitle';
import { GoBack } from '../../common/GoBack/GoBack';
import { CustomInputNumber } from '../../common/CustomInputNumber/CustomInputNumber';

// Uso de styled en lugar de makeStyles
const Root = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const InfoPanel = styled('div')(({ theme }) => ({
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
  },
}));

export const DeviceManagementNodesStorageSettingsScreen = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  // Acceso separado al state de Redux
  const { activeContract } = useSelector(state => state.contracts);

  const activeId = useRef(activeContract?.id);
  const isLoaded = useRef(false);

  const [values, handleInputChange, reset] = useForm({
    mask_of_moments_to_store: 0,
    mask_of_measures_to_store: 0,
    shipping_frequency: 1800,
    storage_frequency: 0,
  });

  const {
    mask_of_moments_to_store,
    mask_of_measures_to_store,
    shipping_frequency,
    storage_frequency,
  } = values;

  const handleChange = (e) => {
    if (e.target.checked) {
      handleInputChange({
        target: {
          name: 'mask_of_measures_to_store',
          value: parseInt(mask_of_measures_to_store) + parseInt(e.target.value),
        }
      });
    } else {
      handleInputChange({
        target: {
          name: 'mask_of_measures_to_store',
          value: parseInt(mask_of_measures_to_store) - parseInt(e.target.value),
        }
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    Swal.fire({
      title: "¿Estás seguro?",
      text: "Los cambios realizados tardarán un tiempo variable en llevarse a cabo",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    }).then((res) => {
      if (res.isConfirmed) {
        dispatch(startContractsUpdate({
          ...values,
          shipping_frequency: shipping_frequency / 10,
        }));
      }
    });
  };

  const activeReset = useCallback(() => {
    if (activeContract) {
      reset({
        mask_of_moments_to_store: activeContract.mask_of_moments_to_store || 0,
        mask_of_measures_to_store: activeContract.mask_of_measures_to_store || 0,
        shipping_frequency: activeContract.shipping_frequency ? activeContract.shipping_frequency * 10 : 1800,
        storage_frequency: activeContract.storage_frequency || 0,
      });
    } else {
      reset();
    }
  }, [activeContract, reset]);

  useEffect(() => {
    if ((activeContract && !isLoaded.current) || (activeContract && activeId.current !== activeContract.id)) {
      activeReset();
      activeId.current = activeContract.id;
      if (!isLoaded.current) {
        isLoaded.current = true;
      }
    }
  }, [activeContract, activeReset]);

  useEffect(() => {
    return () => {
      dispatch(uiRemoveError());
    };
  }, [dispatch]);

  return (
    <>
    <Divider/>
      <Root maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ display: 'flex' }}>
            <GoBack />
            <CustomTitle title={`Configuración de almacenamiento ~ Luminarias`} icon={<Avatar><SettingsBrightnessIcon /></Avatar>} />
          </Grid>
          <Grid container justifyContent="center">
            <Grid item className='animate__animated animate__fadeIn'>
              {activeContract ? (
                <Card>
                  <CardHeader
                    title="Configuración de almacenamiento"
                    subheader="Parámetros de almacenamiento y envío de medidas para todas las luminarias del contrato"
                    avatar={<Avatar><FontAwesomeIcon icon={faCogs} /></Avatar>}
                  />
                  <Divider />
                  <form className={InfoPanel} onSubmit={handleSubmit}>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <FormControl component="fieldset">
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox checked={!!(mask_of_measures_to_store & 1)} onChange={handleChange} value={1} />}
                                label="Tensión"
                              />
                              <FormControlLabel
                                control={<Checkbox checked={!!(mask_of_measures_to_store & 4)} onChange={handleChange} value={4} />}
                                label="Corriente"
                              />
                              <FormControlLabel
                                control={<Checkbox checked={!!(mask_of_measures_to_store & 8)} onChange={handleChange} value={8} />}
                                label="Potencia activa"
                              />
                              <FormControlLabel
                                control={<Checkbox checked={!!(mask_of_measures_to_store & 16)} onChange={handleChange} value={16} />}
                                label="Potencia reactiva"
                              />
                              <FormControlLabel
                                control={<Checkbox checked={!!(mask_of_measures_to_store & 64)} onChange={handleChange} value={64} />}
                                label="Energía activa (en mensaje de medidas)"
                              />
                              <FormControlLabel
                                control={<Checkbox checked={!!(mask_of_measures_to_store & 128)} onChange={handleChange} value={128} />}
                                label="Energía reactiva"
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='h6'>Almacenar medidas en los...</Typography>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="mask_of_moments_to_store"
                              name="mask_of_moments_to_store"
                              value={mask_of_moments_to_store}
                              onChange={handleInputChange}
                            >
                              <FormControlLabel value={1} control={<Radio />} label="Cambios de dimming" />
                              <FormControlLabel value={2} control={<Radio />} label="Encendidos" />
                              <FormControlLabel value={4} control={<Radio />} label="Apagados" />
                              <FormControlLabel value={6} control={<Radio />} label="Encendidos y apagados" />
                              <FormControlLabel value={0} control={<Radio />} label="Nunca" />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='h6'>Frecuencia de Almacenamiento de medidas</Typography>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="storage_frequency"
                              name="storage_frequency"
                              value={storage_frequency}
                              onChange={handleInputChange}
                            >
                              <FormControlLabel value={1} control={<Radio />} label="Cada 15 minutos" />
                              <FormControlLabel value={2} control={<Radio />} label="Cada hora" />
                              <FormControlLabel value={4} control={<Radio />} label="Cada 4 horas" />
                              <FormControlLabel value={6} control={<Radio />} label="Cada 24 horas" />
                              <FormControlLabel value={0} control={<Radio />} label="Nunca" />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant='h6'>Frecuencia de envío de medidas</Typography>
                          <CustomInputNumber
                            label="[min]"
                            onKeyDown={(e) => { e.preventDefault(); }}
                            name='shipping_frequency'
                            value={shipping_frequency}
                            onChange={handleInputChange}
                            min={10}
                            max={2500}
                            step={10}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <CardActions>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <Button
                            fullWidth
                            type='submit'
                            variant="contained"
                            color="primary"
                          >
                            Guardar
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Button
                            fullWidth
                            type='reset'
                            variant="contained"
                            onClick={activeReset}
                          >
                            Cancelar
                          </Button>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </form>
                </Card>
              ) : (
                <CircularProgress color="primary" />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Root>
    </>
  );
};

import {
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Fab,
  FormControl,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  NativeSelect,
  Switch
} from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FiberNewIcon from '@mui/icons-material/FiberNew'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { useForm } from '../../../../hooks/useForm'
import customTheme from '../../../../material-ui/theme'
import { useTheme } from '@emotion/react'
import DoneIcon from '@mui/icons-material/Done'
import LayersClearIcon from '@mui/icons-material/LayersClear'
import DeleteIcon from '@mui/icons-material/Delete'
import CodeIcon from '@mui/icons-material/Code'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import ReactFileReader from 'react-file-reader'

import {
  mapClearFilters,
  mapSetFilters
} from '../../../../reducers/map/mapReducer'
import TimelapseTwoToneIcon from '@mui/icons-material/TimelapseTwoTone'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import {
  uiFinishLoading,
  uiStartLoading
} from '../../../../reducers/ui/uiReducer'
import {
  nodesActive,
  nodesRemoveActive
} from '../../../../reducers/nodes/nodesReducer'
import downloadJsonFile from '../../Download/DownloadJson/DownloadJson'

const FilterList = ({ onSubmit }) => {
  const filters = useSelector(state => state.map.filters)
  const nodes = useSelector(state => state.nodes.nodes)
  const gateways = useSelector(state => state.gateways.gateways)
  const theme = useTheme()
  const loading = useSelector(state => state.ui.loading)
  const isDone = useRef(false)
  const dispatch = useDispatch()

  const template = JSON.stringify([
    { eui: '4e_4a_02_20_57_05' },
    { eui: '4E4A02205705' },
    { eui: '4e-4a-02-20-57-05' },
    { eui: '4e4a02205705' }
  ])

  const nodesIcons = [
    {
      name: 'red',
      icon: customTheme.devicesIcons.node.critical,
      activeIcon: customTheme.devicesIcons.node.active.critical
    },
    {
      name: 'blue',
      icon: customTheme.devicesIcons.node.new,
      activeIcon: customTheme.devicesIcons.node.active.new
    },
    {
      name: 'grey',
      icon: customTheme.devicesIcons.node.fail,
      activeIcon: customTheme.devicesIcons.node.active.fail
    },
    {
      name: 'yellow',
      icon: customTheme.devicesIcons.node.alarm,
      activeIcon: customTheme.devicesIcons.node.active.alarm
    },
    {
      name: 'green',
      icon: customTheme.devicesIcons.node.main,
      activeIcon: customTheme.devicesIcons.node.active.main
    },
    {
      name: 'pink',
      icon: customTheme.devicesIcons.node.auxPink,
      activeIcon: customTheme.devicesIcons.node.active.auxPink
    },
    {
      name: 'purple',
      icon: customTheme.devicesIcons.node.auxPurple,
      activeIcon: customTheme.devicesIcons.node.active.auxPurple
    },
    {
      name: 'orange',
      icon: customTheme.devicesIcons.node.auxOrange,
      activeIcon: customTheme.devicesIcons.node.active.auxOrange
    },
    {
      name: 'lemon',
      icon: customTheme.devicesIcons.node.auxLemon,
      activeIcon: customTheme.devicesIcons.node.active.auxLemon
    },
    {
      name: 'salmon',
      icon: customTheme.devicesIcons.node.auxSalmon,
      activeIcon: customTheme.devicesIcons.node.active.auxSalmon
    }
  ]

  const [state, setstate] = useState({
    firmware: -1,
    gatewayNumber: -1
  })

  const { firmware, gatewayNumber } = state

  const [values, handleInputChange, reset] = useForm({
    active: false,

    alarm: false,
    new: false,
    fail: false,

    current_gateway_number: -1,

    firmwareIcons: [],

    gatewayIcons: []
  })

  useEffect(() => {
    if (!isDone.current) {
      reset(filters)
      isDone.current = true
    }
  }, [isDone, reset, filters])

  const handleSubmit = e => {
    e.preventDefault()

    onSubmit()

    dispatch(mapSetFilters(values))
  }

  const handleFiltersClear = e => {
    e.preventDefault()

    reset()

    dispatch(mapClearFilters())
  }

  const handleDeleteFirmwareVersion = version => {
    handleInputChange({
      target: {
        name: 'firmwareIcons',
        value: [...values.firmwareIcons.filter(e => e.version !== version)]
      }
    })
  }

  const handleAddFirmwareVersion = () => {
    if (values.firmwareIcons.length < nodesIcons.length) {
      handleInputChange({
        target: {
          name: 'firmwareIcons',
          value: [
            ...values.firmwareIcons,
            {
              version: firmware,
              ...nodesIcons.filter(
                e => !values.firmwareIcons.find(v => e.name === v.name)
              )[0]
            }
          ]
        }
      })
    }
  }

  const handleChangeFirmware = e => {
    setstate(c => ({
      ...c,
      firmware: e.target.value
    }))
  }

  const firmwareVersions = useMemo(() => {
    let hash = {}
    return nodes
      .filter(o =>
        hash[o.firmware_version] ? false : (hash[o.firmware_version] = true)
      )
      .map(e => e.firmware_version)
  }, [nodes])

  const handleFiles = files => {
    dispatch(uiStartLoading())
    try {
      const fileReader = new FileReader()
      fileReader.readAsText(files[0], 'UTF-8')
      fileReader.onload = async event => {
        const content = JSON.parse(event.target.result)
        const matchs = nodes
          .filter(e =>
            content.find(
              item =>
                item.eui
                  .replaceAll('-', '')
                  .replaceAll('_', '')
                  .toLowerCase() === e.eui
            )
          )
          .map(e => {
            return {
              id: e.id
            }
          })
        await dispatch(nodesRemoveActive())
        await dispatch(nodesActive(matchs))
        await dispatch(uiFinishLoading())
      }
    } catch (err) {
      console.error(err)
      dispatch(uiFinishLoading())
    }
  }

  const handleChangeGatewayNumber = e => {
    setstate(c => ({
      ...c,
      gatewayNumber: e.target.value
    }))
  }

  const handleAddGatewayNumber = () => {
    if (values.gatewayIcons.length < nodesIcons.length) {
      handleInputChange({
        target: {
          name: 'gatewayIcons',
          value: [
            ...values.gatewayIcons,
            {
              gateway_number: gatewayNumber,
              ...nodesIcons.filter(
                e => !values.gatewayIcons.find(v => e.name === v.name)
              )[0]
            }
          ]
        }
      })
    }
  }

  const handleDeleteGatewayNumber = value => {
    handleInputChange({
      target: {
        name: 'gatewayIcons',
        value: [...values.gatewayIcons.filter(e => e.gateway_number !== value)]
      }
    })
  }

  return (
    <form
      onSubmit={handleSubmit}
      onReset={handleFiltersClear}
      style={{ paddingBottom: theme.spacing(2), paddingTop: theme.spacing(4) }}
    >
      <Backdrop open={loading}>
        <CircularProgress color='primary' />
      </Backdrop>
      <List
        subheader={<ListSubheader>Filtros</ListSubheader>}
        style={{ width: 360 }}
      >
        <ListItem
          secondaryAction={
            <Switch
              edge='end'
              onChange={handleInputChange}
              checked={values.active}
              name='active'
              inputProps={{ 'aria-labelledby': 'switch-list-label-active' }}
            />
          }
        >
          <ListItemIcon>
            <PlaylistAddCheckIcon />
          </ListItemIcon>
          <ListItemText
            id='switch-list-label-nodes'
            primary={'Equipos seleccionados'}
          />
        </ListItem>
        <ListItem
          secondaryAction={
            <Switch
              edge='end'
              onChange={handleInputChange}
              checked={values.alarm}
              name='alarm'
              inputProps={{ 'aria-labelledby': 'switch-list-label-alarm' }}
            />
          }
        >
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText id='switch-list-label-alarm' primary={'Eventos'} />
        </ListItem>

        {nodes && nodes.length > 0 && (
          <ListItem
            secondaryAction={
              <Switch
                edge='end'
                onChange={handleInputChange}
                checked={values.new}
                name='new'
                inputProps={{ 'aria-labelledby': 'switch-list-label-new' }}
              />
            }
          >
            <ListItemIcon>
              <FiberNewIcon />
            </ListItemIcon>
            <ListItemText
              id='switch-list-label-new'
              primary={'Equipos nuevos'}
            />
          </ListItem>
        )}
        <ListItem
          secondaryAction={
            <Switch
              edge='end'
              onChange={handleInputChange}
              checked={values.fail}
              name='fail'
              inputProps={{ 'aria-labelledby': 'switch-list-label-fail' }}
            />
          }
        >
          <ListItemIcon>
            <TimelapseTwoToneIcon />
          </ListItemIcon>
          <ListItemText
            id='switch-list-label-fail'
            primary={'Fallos de comunicación'}
          />
        </ListItem>
        <Divider />
        {gateways && gateways.length > 0 && (
          <ListItem>
            <ListItemIcon>
              <Avatar src={customTheme.devicesIcons.gateway.avatar} />
            </ListItemIcon>
            <FormControl>
              <NativeSelect
                value={values.current_gateway_number}
                onChange={handleInputChange}
                name='current_gateway_number'
                inputProps={{ 'aria-label': 'current_gateway_number' }}
              >
                <option value={-1}>{'Todos los equipos'}</option>
                {[...gateways]
                  .sort((x, y) => {
                    if (x.name < y.name) {
                      return -1
                    }
                    if (x.name > y.name) {
                      return 1
                    }
                    return 0
                  })
                  .map(e => (
                    <option key={e.id} value={e.gateway_number}>
                      {e.name}
                    </option>
                  ))}
              </NativeSelect>
              <FormHelperText>{'Gateways'}</FormHelperText>
            </FormControl>
          </ListItem>
        )}

<Divider />
        <ListItem
          secondaryAction={
            <IconButton
              onClick={handleAddFirmwareVersion}
              disabled={
                firmware === -1 ||
                (values.firmwareIcons.find(e => e.version === firmware)
                  ? true
                  : false) ||
                (values.gatewayIcons.length ? true : false)
              }
            >
              <AddCircleIcon />
            </IconButton>
          }
        >
          <ListItemIcon>
            <CodeIcon />
          </ListItemIcon>

          <NativeSelect
            value={firmware}
            onChange={handleChangeFirmware}
            name='firmware'
            inputProps={{ 'aria-label': 'firmware' }}
          >
            <option value={-1} disabled>
              {'firmware_version'}
            </option>
            {firmwareVersions.map(e => (
              <option key={e} value={e}>
                {e}
              </option>
            ))}
          </NativeSelect>
        </ListItem>

        <ListItem
          secondaryAction={
            <Button onClick={() => downloadJsonFile(template, 'plantilla')}>
              {'Plantilla'}
            </Button>
          }
        >
          <ListItemIcon>
            <UploadFileIcon />
          </ListItemIcon>
          <ReactFileReader handleFiles={handleFiles} fileTypes={'.csv'}>
            <Button variant='contained' size='small' color='primary' fullWidth>
              {'Cargar Luminarias'}
            </Button>
          </ReactFileReader>
        </ListItem>
        <List component='div' disablePadding>
          {values.firmwareIcons?.map(e => (
            <ListItem
              key={e.name}
              secondaryAction={
                <IconButton
                  onClick={() => handleDeleteFirmwareVersion(e.version)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemIcon>
                <Avatar
                  src={e.icon}
                  style={{ width: theme.spacing(3), height: theme.spacing(3) }}
                />
              </ListItemIcon>
              <ListItemText
                primary={`firmware_version: ${e.version}`}
                secondary={
                  nodes.filter(
                    n => parseInt(n.firmware_version) === parseInt(e.version)
                  ).length
                }
              />
            </ListItem>
          ))}
        </List>
        {gateways.length ? (
          <>
            <Divider />
            <ListItem
              secondaryAction={
                <IconButton
                  onClick={handleAddGatewayNumber}
                  disabled={
                    gatewayNumber === -1 ||
                    (values.gatewayIcons.find(
                      e => e.gateway_number === gatewayNumber
                    )
                      ? true
                      : false) ||
                    (values.firmwareIcons.length ? true : false)
                  }
                >
                  <AddCircleIcon />
                </IconButton>
              }
            >
              <ListItemIcon>
                <Avatar src={customTheme.devicesIcons.gateway.avatar} />
              </ListItemIcon>

              <NativeSelect
                value={gatewayNumber}
                onChange={handleChangeGatewayNumber}
                name='gateway'
                inputProps={{ 'aria-label': 'gateway' }}
              >
                <option value={-1} disabled>
                  {'Gateway'}
                </option>
                {gateways.map(e => (
                  <option key={e.id} value={e.gateway_number}>
                    {e.gateway_number}
                  </option>
                ))}
              </NativeSelect>
            </ListItem>

            <List component='div' disablePadding>
              {values.gatewayIcons?.map(e => (
                <ListItem
                  key={e.name}
                  secondaryAction={
                    <IconButton
                      onClick={() =>
                        handleDeleteGatewayNumber(e.gateway_number)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemIcon>
                    <Avatar src={e.icon} style={{ width: theme.spacing(3), height: theme.spacing(3) }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Gateway: ${e.gateway_number}`}
                    secondary={
                      nodes.filter(
                        n =>
                          parseInt(n.current_gateway_number) ===
                          parseInt(e.gateway_number)
                      ).length
                    }
                  />
                </ListItem>
              ))}
            </List>
          </>
        ) : null}
      </List>
      <Fab
        color='primary'
        size='small'
        aria-label='done'
        type='submit'
        style={{
          float: 'right',
          right: theme.spacing(2),
          marginLeft: theme.spacing(1)
        }}
      >
        <DoneIcon />
      </Fab>

      <Fab
        color='primary'
        size='small'
        aria-label='done'
        type='reset'
        style={{
          float: 'right',
          right: theme.spacing(2),
          marginLeft: theme.spacing(1)
        }}
      >
        <LayersClearIcon />
      </Fab>
    </form>
  )
}

export default FilterList


import { Base64 } from 'js-base64';
import { api } from '../api/api';
//import theme from '../material-ui/theme';
import { purpleTheme } from '../material-ui/theme';
import Swal from "sweetalert2";
//import { startLogout } from '../actions/auth';
import { startLogout} from '../reducers/auth/authActions'

const fetchAuth = ({ email, password, code }, check=false) => {
    const url = `${api().auth}/2fa/${check ? "check" : ""}`;

    const options = {
        method: 'POST',
        headers: {
            'Authorization': `Basic ${Base64.encode(`${email}:${check? code : password}`)}`
        },
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    }

    return fetch(url, options);
}



const fetchConToken = (endpoint, data, method = 'GET', api_type = 'data', contentType = 'application/json') => {
    
    const url = `${api()[api_type]}/${endpoint}`;
    console.log("url", url)
    const token = localStorage.getItem('token') || '';
    const headers = {
        ...(contentType ? { 'Content-type': contentType } : {}),
        'Authorization': `Basic ${Base64.encode(`${token}:`)}`
    };

    if (method === 'GET' || method === 'DELETE') {
        return fetch(url, {
            method,
            headers,
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        });
    } else {
        return fetch(url, {
            method,
            headers,
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: contentType === 'application/json' ? JSON.stringify(data) : data
        });
    }
};

/*
const fetchConToken = (endpoint, single = true , data, method = 'GET', api_type = 'data', contentType = 'application/json') => {

    const url = single
        ? `${api[api_type]}/contract${endpoint}`   
        : `${api[api_type]}/contracts${endpoint}`;

    console.log("URL: ", url)

    const token = localStorage.getItem('token') || '';
    if (method === 'GET' || method === 'DELETE') {
        return fetch(url, {
            method,
            headers: {
                'Authorization': `Basic ${Base64.encode(`${token}:`)}`
            },
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        });
    } else {
        return fetch(url, {
            method,
            headers: {
                ...(contentType? {'Content-type': contentType} : {}),
                'Authorization': `Basic ${Base64.encode(`${token}:`)}`
            },
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: contentType === 'application/json' ? JSON.stringify(data) : data
        });
    }
}
    */

const handleError = (err, dispatch, callback = null, msg = null) => {

    switch (err) {

        case 401:
            swalError(`${msg || "¡UPS! Page not found"}: ${err}`, callback);
            break;

        
        case 402:
            dispatch(startLogout());
            swalError(`${msg || "You need to update your payments to access the contract"}: ${err}`, callback);
            break;

        case 403:
            dispatch(startLogout());
            swalError(err?.message || err, callback);
            break;
        

        case 404:
            swalError(`${msg || "This item is no longer in the system"}: ${err}`, callback);
            break;

        case 405:
            swalError(`${msg || "Service Unavailable"}: ${err}`, callback);
            break;

        case 500:
            swalError(`${msg || "A problem occurred on our servers during the course of the operation."}: ${err}`, callback);
            break;

        default:
            swalError(`${msg || "Error"}: ${err}`, callback);
            break;
    }
}

const swalError = (msg, callback = null) => {
    Swal.fire({
        titleText: 'Error',
        text: msg,
        icon: 'error',
        confirmButtonColor: purpleTheme.palette.primary.main,
    }).then(function () {
        if (callback) {
            callback();
        }
    });
}

export {
    handleError,
    fetchAuth,
    fetchConToken
}
const downloadJsonFile = async (myData, fileName, extension = "json", type = "application/json") => {
    const json = extension === 'json'?syntaxHighlight(myData):myData;
    const blob = new Blob([json],{type});
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + "." + extension;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const syntaxHighlight = (json) => {
    if (typeof json != 'string') {
         json = JSON.stringify(json, undefined, 2);
    } else {
        json = JSON.stringify(JSON.parse(json), undefined, 2);
    }
    return json
}

export default downloadJsonFile
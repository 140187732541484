

import moment from 'moment';

export const thereIsAFail = (lastConnection, hoursToLimit) => {
    const deviceDate = moment(lastConnection);
    const limitDate = moment().add(-hoursToLimit, 'hours');
    if(deviceDate < limitDate) {
        return true;
    }
    return false;
}


export const rateColorMeasures = ( value, total, hours = undefined) => {
    // Lo hago para redondear el numero con 2 decimales,  multiplicando por 100, redondeando y dividiendo por 100 de nuevo.
    // De esta manera redondeo al segundo decimal
    //const percentage = Math.round((value / total) * 100 * 100) / 100;

    let percentage = 0;

    if (total !== 0){
      percentage = (value / total) * 100;
    } 
    let color = '#f2f4f4';

    if (hours < 15) {
        if (percentage > 0 && percentage <= 30) {
          color = '#f9e79f';
        } else if (percentage > 30 && percentage <= 40) {
          color = '#f39c12';
        } else if (percentage > 40) {
          color = '#e74c3c ';
        }
      } else if (hours > 15 && hours <= 30) {
        if (percentage > 0 && percentage <= 20) {
          color = '#f9e79f';
        } else if (percentage > 20 && percentage <= 30) {
          color = '#f39c12';
        } else if (percentage > 30) {
          color = '#e74c3c ';
        }
      } else if (hours > 30 || hours === undefined) {
        if (value !== 0 && percentage >= 0 && percentage <= 10) {
          color = '#f9e79f';
        } else if (percentage > 10 && percentage <= 20) {
          color = '#f39c12';
        } else if (percentage > 20) {
          color = '#f87962 ';
        }
      }
  
    return { value, color, percentage };
  };
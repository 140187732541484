
import { createAsyncThunk } from '@reduxjs/toolkit';
import Swal from 'sweetalert2';
import { fetchAuth, fetchConToken, handleError } from '../../helpers/fetch';
import validator from 'validator';
import { contractsLoad, contractsLogoutCleaning } from '../contracts/contractsReducer';
import { startContractsActive} from '../contracts/contractsActions'
import { api } from '../../api/api';
import { purpleTheme } from '../../material-ui/theme';
import { loadState } from '../../helpers/localStorage';
import { uiSetError } from '../ui/uiReducer';
import { authCheckingFinish, authLogin, authLogout, authSetFirsthFactorTrue, finishLoading, startLoading } from './authReducer';
import { nodesLogoutCleaning } from '../nodes/nodesReducer';
import { panelsLogoutCleaning } from '../panels/panelsReducer';
import { gatewaysLogoutCleaning } from '../gateways/gatewaysReducers';


export const startLoginEmailPassword = createAsyncThunk('auth/startLoginEmailPassword',
    async ({ email, password }, { dispatch }) => {
        dispatch(startLoading());

        try {
            const result = await fetchAuth({ email, password });
            if (!result.ok) {
                throw result.status;
            }

            const data = await result.json();

            if (data && data.token) {
                await _authProcess(data, dispatch, email);
            } else {
                dispatch(authSetFirsthFactorTrue());
            }
        } catch (error) {
            handleError(error, dispatch, null, "Incorrect username or password");
        } finally {
            dispatch(finishLoading());
        }
    }
);

export const startLoginEmailCode = createAsyncThunk('auth/startLoginEmailCode',
    async ({ email, code }, { dispatch }) => {
        dispatch(startLoading());

        try {
            const result = await fetchAuth({ email, code }, true);
            if (!result.ok) {
                throw result.status;
            }
            const data = await result.json();
            await _authProcess(data, dispatch, email);
        } catch (error) {
            handleError(error, dispatch, null, "Incorrect or expired access code");
        } finally {
            dispatch(finishLoading());
        }
    }
);

const _authProcess = async (data, dispatch, email) => {
    const authInfo = {
        id: data?.id,
        name: data?.name,
        email,
        company: data?.company,
        terms_of_use: data?.terms_of_use,
        date: data?.date,
    };

    localStorage.setItem('token', data.token);
    localStorage.setItem('token-init-date', new Date().getTime());
    localStorage.setItem('auth', JSON.stringify(authInfo));
    localStorage.setItem('contracts', JSON.stringify(data.contracts));

    dispatch(authLogin({ user: authInfo, token: data.token }));

    if (data?.terms_of_use === 0) {
        Swal.fire({
            title: "Insert your new password and accept the terms and conditions of use.",
            html: `
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <input type="password" id="newPassword" class="swal2-input" placeholder="New Password" style="margin-bottom: 10px; width: 100%;">
                    <input type="password" id="reNewPassword" class="swal2-input" placeholder="Repeat Password" style="margin-bottom: 10px; width: 100%;">
                    <p style="margin-top: 15px; text-align: center;">
                        To use this service, you need to accept the <a href="${api().auth}/${data?.terms_of_use_path}" target="_blank">terms and conditions of use.</a>
                    </p>
                </div>
            `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: purpleTheme.palette.primary.main,
            confirmButtonText: "Accept",
            cancelButtonText: "Cancel",
            preConfirm: async () => {
                const newPassword = Swal.getPopup().querySelector('#newPassword').value;
                const reNewPassword = Swal.getPopup().querySelector('#reNewPassword').value;
                const result = await fetchConToken("users/test-password",{ password: newPassword }, "POST", 'auth');
                try {
                    const data = await result.json();
                    if (!validator.isStrongPassword(newPassword) || newPassword !== reNewPassword || data.msg !== true) {
                        Swal.showValidationMessage("The password does not match or is inadequate. The password must contain at least 8 digits of which there must be at least one uppercase, one lowercase, one number, and one special character.");
                    } else {
                        return { password: newPassword };
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        }).then(async (res) => {
            if (res.isConfirmed) {
                fetchConToken(data.update_terms_of_use.path, res.value, data.update_terms_of_use.method, 'auth')
                    .then(result => {
                        if (!result.ok) {
                            throw result.status;
                        }
                        return result;
                    })
                    .then(result => result.json())
                    .then(async (data) => {
                        await dispatch(contractsLoad(data.contracts));
                        const lastId = localStorage.getItem('lastContract');

                        await dispatch(
                            startContractsActive(
                                (lastId && data.contracts.find(e => e.id === parseInt(lastId))) ? parseInt(lastId) : data.contracts[0].id
                            )
                        );
                    })
                    .catch(err => {
                        handleError(err, dispatch);
                    });
            }
        });
    } else {
        await dispatch(contractsLoad(data.contracts));
        //const lastId = localStorage.getItem('lastContract');

        await dispatch(
            startContractsActive(data.contracts[0].id)
        );
    }
};



export const startResetPassword = (email) => {
    return async (dispatch) => {
      dispatch(startLoading());
  
      try {
        const result = await fetchConToken("users/reset-password",{ email }, "PATCH", 'auth');
  
        if (!result.ok) {
          throw new Error(result.status);
        }
  
        const data = await result.json();
  
        if (data?.msg === false) {
          await dispatch(uiSetError("The password recovery process has been started, please check your email address."));
        } else if (data?.msg === true) {
          await dispatch(uiSetError("Please check your email address."));
        } else {
          throw new Error("Bad response");
        }
  
      } catch (err) {
        console.error(err);
        await dispatch(uiSetError("An error occurred during the password recovery process, please contact your administrator."));
      } finally {
        dispatch(finishLoading());
      }
    };
  };




  
  //ESTE FRAGMENTO MANEJA STARTCONTRACTSACTIVE CON EL ULTIMO ID
export const startChecking = () => {
    return async (dispatch) => {

        try {

            const auth = loadState('auth');
            const token = localStorage.getItem('token');
            const contracts = loadState('contracts');



            if (token && contracts) {

                const lastId = localStorage.getItem('lastContract');
                dispatch(authLogin({ user: auth, token, contracts }));
                dispatch(contractsLoad(contracts))
                dispatch(
                    startContractsActive(
                        (lastId) ? parseInt(lastId) : contracts[0].id
                    )
                )
            } else {
                dispatch(authCheckingFinish());
            }
        } catch (err) {
            dispatch(authCheckingFinish());

        }
    }
}
    



export const startLogout = () => {
    return async (dispatch) => {

        /*
        await dispatch(startUsersOperation({ text: "Logout contract and platform" }));

        await dispatch(contractsLogoutCleaning());

        await dispatch(nodesLogoutCleaning());
        await dispatch(panelsLogoutCleaning());
        await dispatch(virtualPanelsLogoutCleaning());
        await dispatch(virtualNodesLogoutCleaning());
        await dispatch(gatewaysLogoutCleaning());
        await dispatch(mapLogoutCleaning());
        await dispatch(nodesProfilesLogoutCleaning());
        await dispatch(panelsAlarmProfilesLogoutCleaning());
        await dispatch(panelsProfilesLogoutCleaning());
        await dispatch(panelsU6me2ProfilesLogoutCleaning());
        await dispatch(nodesExceptionProfilesLogoutCleaning());
        await dispatch(holidaysLogoutCleaning());
        await dispatch(usersRemoveActive());
        await dispatch(sensorsLogoutCleaning());
        await dispatch(luminairesLogoutCleaning());
        await dispatch(driversLogoutCleaning());
        await dispatch(panelsActionsLogoutCleaning());
        await dispatch(nodesActionsLogoutCleaning());
        await dispatch(workgroupsLogoutCleaning());
        await dispatch(tasksLogoutCleaning());
        await dispatch(incidencesLogoutCleaning());
        await dispatch(workgroupsLogoutCleaning());
        await dispatch(tasksHistoryLogoutCleaning());
        await dispatch(panelsCodedMainsProfilesLogoutCleaning());
        await dispatch(subcontractsLogoutCleaning());

        */
        await dispatch(nodesLogoutCleaning());
        await dispatch(panelsLogoutCleaning());
        await dispatch(gatewaysLogoutCleaning());
        await dispatch(contractsLogoutCleaning())
        localStorage.removeItem('token');
        localStorage.removeItem('lastContract')
        localStorage.removeItem('NoteBook');
        localStorage.removeItem('token-init-date');
        localStorage.removeItem('auth');
        localStorage.removeItem('contracts');

        await dispatch(authLogout());
    }
}
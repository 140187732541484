


import React, { useEffect, useRef } from 'react';
import {
    Button,
    Divider,
    Grid,
    TextField,
    CircularProgress,
    FormControl,
    FormHelperText,
    Box
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import SaveIcon from '@mui/icons-material/Save';
import validator from 'validator';
import { useForm } from '../../../../hooks/useForm';
import { uiRemoveError, uiSetError } from '../../../../reducers/ui/uiReducer';
import { startContractsUpdate } from '../../../../reducers/contracts/contractsActions';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import GeoSearch from '../../../AdvancedMonitoringMapScreen/Map/GeoSearch/GeoSearch';

export const ContractInfo = () => {
    const dispatch = useDispatch();
    const msgError = useSelector((state) => state.ui.msgError);
    const activeContract = useSelector((state) => state.contracts.activeContract);

    const activeId = useRef(activeContract?.id);
    const isLoaded = useRef(false);

    const [
        values,
        handleInputChange,
        reset
    ] = useForm({
        location: '',
        latitude: 0,
        longitude: 0,
        failure_window_in_hours: 0,
        failure_window_in_hours_panels: 0,
    });

    const {
        location = '',
        latitude = 0,
        longitude = 0,
        failure_window_in_hours = 0,
        failure_window_in_hours_panels = 0,
    } = values;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isFormValid()) {
            dispatch(startContractsUpdate({
                ...activeContract, // Mantén los valores originales de activeContract
                ...values, // Sobrescribe solo los campos modificados en el formulario
            }));
        }
    };
    

    const isFormValid = () => {
        if (!validator.isLatLong([latitude, longitude].toString())) {
            dispatch(uiSetError('Coordenadas inválidas'));
            return false;
        }
        dispatch(uiRemoveError());
        return true;
    }

    useEffect(() => {
        if ((activeContract && !isLoaded.current) || (activeContract && activeId.current !== activeContract.id)) {
            reset({
                location: activeContract.location || '',
                latitude: parseFloat(activeContract.latitude) || 0,
                longitude: parseFloat(activeContract.longitude) || 0,
                failure_window_in_hours: parseInt(activeContract.failure_window_in_hours, 10) || 0,
                failure_window_in_hours_panels: parseInt(activeContract.failure_window_in_hours_panels, 10) || 0,
            });
            dispatch(uiRemoveError());
            activeId.current = activeContract.id;
            isLoaded.current = true;
        }
    }, [activeContract, reset, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(uiRemoveError());
        }
    }, [dispatch]);



    const MapUpdater = ({ latitude, longitude, zoom = 13 }) => {
        const map = useMap();
        useEffect(() => {
            map.setView([latitude, longitude], zoom);
        }, [latitude, longitude, zoom, map]);
        return null;
    };

    return (
        <Box sx={{
            backgroundColor: 'background.paper',
            padding: 3,
            borderRadius: 1,
            boxShadow: 1
        }}>
            <div className='animate__animated animate__fadeIn'>
                {activeContract ? (
                    <>
                        <form onSubmit={handleSubmit}>
                            <FormControl fullWidth margin='dense'>
                                <TextField
                                    name="location"
                                    label="Ubicación"
                                    value={location}
                                    onChange={handleInputChange}
                                    autoComplete='off'
                                />
                            </FormControl>

                            <Divider />

                            <FormControl margin='dense'>
                                <TextField
                                    type='number'
                                    name="longitude"
                                    label="Longitud"
                                    value={longitude}
                                    onChange={handleInputChange}
                                />
                            </FormControl>

                            <FormControl margin='dense'>
                                <TextField
                                    type='number'
                                    name="latitude"
                                    label="Latitud"
                                    value={latitude}
                                    onChange={handleInputChange}
                                />
                            </FormControl>

                            <Grid item xs={12}>
                                <MapContainer center={[latitude, longitude]} zoom={8} style={{ height: 400, width: '100%' }}>
                                    <MapUpdater latitude={latitude} longitude={longitude} />
                                    <GeoSearch />
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                </MapContainer>
                            </Grid>

                            <Divider />

                            <FormControl fullWidth margin='dense'>
                                <TextField
                                    type='number'
                                    name="failure_window_in_hours"
                                    label="Ventana de fallos en horas"
                                    value={failure_window_in_hours}
                                    onChange={handleInputChange}
                                />
                            </FormControl>

                            <FormControl fullWidth margin='dense'>
                                <TextField
                                    type='number'
                                    name="failure_window_in_hours_panels"
                                    label="Ventana de fallos en paneles"
                                    value={failure_window_in_hours_panels}
                                    onChange={handleInputChange}
                                />
                            </FormControl>

                            <Divider />

                            {msgError && (
                                <FormHelperText error={true} sx={{ margin: 1 }}>
                                    {msgError}
                                </FormHelperText>
                            )}

                            <Button
                                type='submit'
                                variant="contained"
                                color="primary"
                                sx={{ margin: 1 }}
                                startIcon={<SaveIcon />}
                            >
                                Guardar
                            </Button>
                        </form>
                    </>
                ) : (
                    <CircularProgress color="primary" />
                )}
            </div>
        </Box>
    );
};



import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  IconButton,
  Tooltip,
  MenuItem,
  ListItemIcon,
  Typography,
  Button,
  Dialog
} from '@mui/material'
import TerminalIcon from '@mui/icons-material/Terminal'
import { PanelsTerminalCard } from './PanelsTerminalCard/PanelsTerminalCard'

export const TerminalButton = React.forwardRef(
  ({ device, variant = 'icon' }, ref) => {
    const panels = useSelector(state => state.panels.panels)
    const [state, setstate] = useState(false)

    const name = useMemo(
      () => panels.find(e => e.id === device?.id)?.name,
      [panels, device?.id]
    )

    const handleClick = useCallback(() => {
      setstate(state => !state)
    }, [setstate])

    const render = () => {
      switch (variant) {
        case 'button':
          return (
            <Button
              ref={ref}
              startIcon={<TerminalIcon />}
              onClick={handleClick}
            >
              {`Abrir terminal a ${name || 'Sin información'}`}
            </Button>
          )
        case 'menuItem':
          return (
            <MenuItem onClick={handleClick} ref={ref}>
              <ListItemIcon>
                <TerminalIcon fontSize='small' />
              </ListItemIcon>
              <Typography variant='inherit'>
                {`Abrir terminal a ${name || 'Sin información'}`}
              </Typography>
            </MenuItem>
          )
        default:
          return (
            <Tooltip title={`Abrir terminal a ${name || 'Sin información'}`}>
              <IconButton onClick={handleClick}>
                <TerminalIcon />
              </IconButton>
            </Tooltip>
          )
      }
    }

    return (
      <>
        {render()}
        <Dialog  open={state} onClose={handleClick}>
          <PanelsTerminalCard device={device} onClose={handleClick}/>
        </Dialog >
      </>
    )
  }
)

TerminalButton.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  variant: PropTypes.oneOf(['icon', 'button', 'menuItem']).isRequired
}

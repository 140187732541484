




import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { Switch, Grid, IconButton, TextField, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import { 
  nodesActiveItem, 
  nodesRemoveActiveItem, 
  nodesRemoveActiveItemById, 
  nodesRemoveActive 
} from '../../../../../reducers/nodes/nodesReducer';
import { CustomTable } from '../../../../common/Tables/CustomTable/CustomTable';
import { NodesOptionsMenu } from '../../../Options/NodesOptionsMenu/NodesOptionsMenu';

export const NodesList = ({ data, columns }) => {
  const [search, setSearch] = useState('');
  const activeItem = useSelector(state => state.nodes.active);
  const dispatch = useDispatch();

  const handleSelect = (nodeId, isChecked) => {
    if (isChecked) {
      dispatch(nodesActiveItem({ id: nodeId }));
    } else {
      dispatch(nodesRemoveActiveItemById(nodeId));
    }
  };

  const handleUnSelectAll = () => {
    dispatch(nodesRemoveActive([]));  
    dispatch(nodesRemoveActiveItem([]));  
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      filteredData.forEach(node => {
        if (!activeItem.some(item => item.id === node.id)) {
          dispatch(nodesActiveItem({ id: node.id }));
        }
      });
    } else {
      dispatch(nodesRemoveActiveItem());
    }
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredData = useMemo(() => {
    return data.filter((row) =>
      search.length === 0
        ? true
        : columns.some((column) => {
            const value = row[column.id];
            return value && String(value).toLowerCase().includes(search.toLowerCase());
          })
    );
  }, [data, search, columns]);

  const allSelected = useMemo(() => {
    return filteredData.length > 0 && filteredData.every(node => activeItem.some(item => item.id === node.id));
  }, [filteredData, activeItem]);

  return (
    <Grid container className='animate__animated animate__fadeIn animate__fast'>
      <Grid item sm={5}>
        <TextField
          id="search"
          label="Buscar"
          type="search"
          name="search"
          autoComplete="off"
          value={search}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>

      <Grid item sm={2}>
        <Tooltip title="Eliminar todo">
          <IconButton edge="end" aria-label="delete" onClick={handleUnSelectAll}>
            <DeleteSweepIcon style={{ fontSize: 30 }} />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item sm={5} style={{ display: 'flex', alignItems: 'center' }}>
        <FormControlLabel
          control={
            <Switch
              checked={allSelected}
              onChange={(event) => handleSelectAll(event.target.checked)}
              color="primary"
            />
          }
          label="Marcar/Desmarcar todos"
        />
      </Grid>

      <Grid item style={{ width: '100%', marginTop: '16px' }}>
        <CustomTable
          rows={filteredData.map((node) => ({
            ...node,
            select: (
              <Checkbox
                checked={activeItem.some(item => item.id === node.id)}
                onChange={(event) => handleSelect(node.id, event.target.checked)}
              />
            ),
            deleteAndSummary: <NodesOptionsMenu id={node.id} />,
          }))}
          columns={columns}
        />
      </Grid>
    </Grid>
  );
};

NodesList.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};


/*
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { Checkbox, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import { nodesActiveItem, nodesRemoveActive, nodesRemoveActiveItem, nodesRemoveActiveItemById } from '../../../../../reducers/nodes/nodesReducer';
import { CustomTable } from '../../../../common/Tables/CustomTable/CustomTable';
import { NodesOptionsMenu } from '../../../Options/NodesOptionsMenu/NodesOptionsMenu';

export const NodesList = ({ data }) => {
  const [search, setSearch] = useState('');
  const { activeItem } = useSelector(state => state.nodes); // Obtener activeItem de nodes

  const dispatch = useDispatch();

  const handleUnSelectAll = () => {
    dispatch(nodesRemoveActive([]));
    dispatch(nodesRemoveActiveItem([]));
  };

  const handleSelect = (nodeId, isChecked) => {
    if (isChecked) {
      dispatch(nodesActiveItem({ id: nodeId })); // Añadir a activeItem
    } else {
      dispatch(nodesRemoveActiveItemById(nodeId)); // Quitar de activeItem
    }
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <Grid container className='animate__animated animate__fadeIn animate__fast'>
      <Grid item sm={5}>
        <TextField
          id="search"
          label="Buscar"
          type="search"
          name="search"
          autoComplete="off"
          value={search}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>

      <Grid item sm={2}>
        <Tooltip title="Eliminar todo">
          <IconButton edge="end" aria-label="delete" onClick={handleUnSelectAll}>
            <DeleteSweepIcon />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item style={{ width: '100%', marginTop: '16px' }}>
        <CustomTable
          rows={data
            .filter((node) =>
              search.length === 0
                ? true
                : ['id', 'name', 'firmware_version', 'ip'].some((key) =>
                  String(node[key]).trim().toLowerCase().includes(search.trim().toLowerCase())
                )
            )
            .map((node) => ({
              id: node.id,
              name: node.name,
              firmware_version: node.firmware_version,
              ip: node.ip,
              select: (
                <Checkbox
                  checked={activeItem.some(item => item.id === node.id)} // Verificar si está seleccionado
                  onChange={(event) => handleSelect(node.id, event.target.checked)}
                />
              ),
              deleteAndSummary: <NodesOptionsMenu id={node.id} />,
            }))}
          columns={[
            { id: 'select', label: '' }, // Nueva columna para las casillas de selección
            { id: 'id', label: 'ID' },
            { id: 'name', label: 'Nombre' },
            { id: 'firmware_version', label: 'Versión de Firmware' },
            { id: 'ip', label: 'Dirección IP' },
            { id: 'deleteAndSummary', label: '' },
          ]}
        />
      </Grid>
    </Grid>
  );
};

NodesList.propTypes = {
  data: PropTypes.array.isRequired,
};
*/

/*
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { Grid, IconButton, TextField, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import { CustomTable } from '../../../../common/Tables/CustomTable/CustomTable';
import { nodesActive, nodesRemoveActive } from '../../../../../reducers/nodes/nodesReducer';
import { NodesOptionsMenu } from '../../../Options/NodesOptionsMenu/NodesOptionsMenu';

export const NodesList = ({ data }) => {
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();


  const handleUnSelectAll = () => {
    dispatch(nodesRemoveActive());
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <Grid container className='animate__animated animate__fadeIn animate__fast'>
      <Grid item sm={5}>
        <TextField
          id="search"
          label="Buscar"
          type="search"
          name="search"
          autoComplete="off"
          value={search}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>

      <Grid item sm={2}>
        <Tooltip title="Eliminar todo">
          <IconButton edge="end" aria-label="delete" onClick={handleUnSelectAll}>
            <DeleteSweepIcon />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item style={{ width: '100%', marginTop: '16px' }}>
        <CustomTable
          rows={data
            .filter((node) =>
              search.length === 0
                ? true
                : ['id', 'name', 'firmware_version', 'ip'].some((key) =>
                  String(node[key]).trim().toLowerCase().includes(search.trim().toLowerCase())
                )
            )
            .map((node) => ({
              id: node.id,
              name: node.name,
              firmware_version: node.firmware_version,
              ip: node.ip,
              deleteAndSummary: <NodesOptionsMenu id={node.id} />,
            }))}
          columns={[
            { id: 'id', label: 'ID' },
            { id: 'name', label: 'Nombre' },
            { id: 'firmware_version', label: 'Versión de Firmware' },
            { id: 'ip', label: 'Dirección IP' },
            { id: 'deleteAndSummary', label: '' },
          ]}
        />
      </Grid>

    </Grid>
  );
};

NodesList.propTypes = {
  data: PropTypes.array.isRequired,
};
*/




import React from 'react';
import { 
    Container,
    Grid,
    IconButton,
    Typography,
    Box
} from '@mui/material';
import { useSelector } from 'react-redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { ContractInfo } from './ContractInfo/ContractInfo';

export const ContractScreen = () => {
    const navigate = useNavigate();
    const { activeContract } = useSelector((state) => state.contracts);

    const handleRouter = () => {
        navigate(`/contracts`);
    };

    return (
        <Container sx={{ paddingTop: 4 }}>
            <Grid item xs={12}>
                {activeContract && (
                    <Box display="flex" alignItems="center">
                        <IconButton 
                            edge="end" 
                            aria-label="go-back" 
                            onClick={handleRouter} 
                            sx={{ marginLeft: 2 }}
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                        <Typography 
                            variant="h4" 
                            component="h1" 
                            sx={{ marginLeft: 2 }}
                        > 
                            {activeContract.name}
                        </Typography>
                    </Box>
                )}
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ContractInfo />
                </Grid>
            </Grid>
        </Container>
    );
};


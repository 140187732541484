import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  IconButton,
  Tooltip,
  MenuItem,
  ListItemIcon,
  Typography,
  Button
} from '@mui/material'
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck'
import { color } from '../../../helpers/socket'
import { startSocketPing } from '../../../reducers/socket/socketActions'

export const PingButton = React.forwardRef(({ type, device, variant }, ref) => {
  const panels = useSelector(state => state.panels.panels)
  const gateways = useSelector(state => state.gateways.gateways)

  const status = useSelector(state => state.socket.status)

  const dispatch = useDispatch()

  const name = useMemo(() => {
    switch (type) {
      case 'panels':
        return panels.find(e => e.id === device?.id)?.name
      case 'gateways':
        return gateways.find(e => e.id === device?.id)?.name
      default:
        return null
    }
  }, [panels, gateways, device?.id, type])

  const handleClick = async () => {
    await dispatch(startSocketPing({ip:device?.ip, name: device?.name}))
  }

  const render = () => {
    switch (variant) {
      case 'button':
        return (
          <Button
            ref={ref}
            startIcon={<NetworkCheckIcon style={{ color: color(status) }} />}
            onClick={handleClick}
          >
            {`Test de conexión a ${name || 'Sin información'}`}
          </Button>
        )
      case 'menuItem':
        return (
          <MenuItem onClick={handleClick} ref={ref}>
            <ListItemIcon>
              <NetworkCheckIcon
                fontSize='small'
                style={{ color: color(status) }}
              />
            </ListItemIcon>
            <Typography variant='inherit'>
              {`Test de conexión a ${name || 'Sin información'}`}
            </Typography>
          </MenuItem>
        )
      default:
        return (
          <Tooltip title={`Test de conexión a ${name || 'Sin información'}`}>
            <IconButton onClick={handleClick}>
              <NetworkCheckIcon style={{ color: color(status) }} />
            </IconButton>
          </Tooltip>
        )
    }
  }

  if (!device?.ip) {
    return null
  }

  return <>{render()}</>
})

PingButton.defaultProps = {
  variant: 'icon'
}

PingButton.propTypes = {
  type: PropTypes.oneOf(['panels', 'gateways']).isRequired,
  device: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  variant: PropTypes.oneOf(['icon', 'button', 'menuItem']).isRequired
}


import { createAsyncThunk } from '@reduxjs/toolkit';
import { uiFinishLoading, uiStartLoading } from '../ui/uiReducer';
import Swal from 'sweetalert2';
import { fetchConToken, handleError } from '../../helpers/fetch';
import { purpleTheme } from '../../material-ui/theme';
import { canIUpdateContract } from '../../helpers/contract/statusContract';
import { contractsUpdate, contractsActive, contractsPermissions, contractsType, contractsOnlyIp, contractsMaintenance, contractsU6me2, contractsCodedMains } from './contractsReducer';
import { startPanelsLoad } from '../panels/panelsActions';
import { startNodesLoad } from '../nodes/nodesActions';
import { startGatewaysLoad } from '../gateways/gatewaysActions';
import { panelsLogoutCleaning } from '../panels/panelsReducer';
import { nodesLogoutCleaning } from '../nodes/nodesReducer';
import { gatewaysLogoutCleaning } from '../gateways/gatewaysReducers';
import { startSocketConnect } from '../socket/socketActions';
//import { getSuperAdminContractIds } from '../../helpers/contract/getAdminContracts';

export const startContractsUpdate = createAsyncThunk(
  'contracts/startContractsUpdate',
  async (data, { dispatch, getState }) => {
    const { contracts: { activeContract } } = getState();

    if (typeof data !== 'object' || !activeContract) {
      return;
    }

    Swal.fire({
      title: 'Loading...',
      text: 'Please wait',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    //console.log("activeContract", activeContract)

    try {
      const result = await fetchConToken(`contract/${activeContract.id}`, { ...data }, 'PATCH');

      if (!result.ok && result.status !== 405) {
        throw result.status;
      }

      //console.log("data en redux", data)
      if (result.status === 405) {
        canIUpdateContract(1, activeContract);
      } else {
        dispatch(contractsUpdate(data));
        Swal.fire({
          titleText: 'Saved',
          text: ("¡{{name}} update!", { name: activeContract.name }),
          icon: 'success',
          confirmButtonColor: purpleTheme.palette.primary.main,
        });
      }
    } catch (err) {
      handleError(err, dispatch);
    }
  }
);



export const startContractsActive = (id) => {
  return async (dispatch, getState) => {
    if (!id) {
      Swal.fire({
        title: 'Error',
        text: 'No contract ID provided',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      return;
    }


    Swal.fire({
      title: 'Loading...',
      text: 'Please wait',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const { contracts } = getState().contracts;
      const foundContract = contracts.find(contract => contract.id === id);
      if (foundContract) {
        const result = await fetchConToken(`contract/${id}`);

        if (!result.ok) {
          throw new Error(result.status);
        }

        const contractData = await result.json();


        //await dispatch(panelsRemoveActive());
        //await dispatch(nodesRemoveActive());
        //await dispatch(gatewaysRemoveActive());
        await dispatch(panelsLogoutCleaning());
        await dispatch(nodesLogoutCleaning());
        await dispatch(gatewaysLogoutCleaning());
        await dispatch(uiStartLoading());
        await dispatch(contractsActive(contractData[0]));

        
        await dispatch(contractsPermissions(foundContract.permissions));
        await dispatch(contractsType(foundContract.type));
        await dispatch(contractsOnlyIp(contractData[0].only_ip === 1));
        await dispatch(contractsMaintenance(contractData[0].maintenance === 1));
        await dispatch(contractsU6me2(contractData[0].u6me2 === 1));
        await dispatch(contractsCodedMains(contractData[0].coded_mains === 1));
        await dispatch(startPanelsLoad(contractData[0].id));
        await dispatch(startNodesLoad(contractData[0].id));
        await dispatch(startGatewaysLoad(contractData[0].id));
        await dispatch(startSocketConnect()) // ==> SOCKET IO
      
      } else {
        throw new Error('Contract not found in state');
      }
      //await dispatch(contractsPermissions(found.permissions));
      //await dispatch(contractsType(found.type));
      //await dispatch(contractsOnlyIp((contract[0].only_ip === 1)));
      //await dispatch(contractsMaintenance(contract[0].maintenance === 1));
      //await dispatch(contractsU6me2(contract[0]?.u6me2 === 1));
      //await dispatch(contractsCodedMains(contract[0]?.coded_mains === 1));

      localStorage.setItem('lastContract', id);

      await dispatch(uiFinishLoading());



    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'There was an error loading the contract',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      handleError(error, dispatch);
    } finally {
      Swal.close();
    }
  };
};








/*
//ESTE FRAGMENTO MANEJA OBLIGATORIAMENTE UN ID 

export const startContractsActive = (id) => {
  return async (dispatch, getState) => {
    const { contracts } = getState();
    Swal.fire({
      title: 'Loading...',
      text: 'Please wait',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const result = await fetchConToken(`${id}`);
      if (!result.ok) {
        throw new Error(result.status);
      }
      const contract = await result.json();
      await dispatch(uiStartLoading());      
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'There was an error loading the contract',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      handleError(error, dispatch);
    } finally {
      Swal.close();
    }
  };
};
*/




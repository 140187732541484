



import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

const DisplayResponseVers = React.memo(({ result }) => {
  const panels = useSelector(state => state.panels.panels);

  return (
    <>
      {result?.result?.msg && result?.topic?.indexOf('vers') !== -1 && (
        <>
          {result.result.msg.map(e => {
            const device = panels.find(item => item.ip === e.ip);
            if (!device) {
              return null;
            }
            return (
              <Grid container key={device?.id}>
                <Grid item xs={4}>
                  <Typography>
                    {device?.name}, {device?.ip}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="caption">{e.version}</Typography>
                </Grid>
              </Grid>
            );
          })}
        </>
      )}
    </>
  );
});

export default DisplayResponseVers;


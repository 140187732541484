

export const ifError = mascara => mascara > 15;

export  const getChips = mascara => {
    switch (mascara) {
      case mascara & 1:
        return [
          { name: 'Version', status: true },
          { name: 'Telecarga', status: false },
          { name: 'Configuración', status: false },
          { name: '2º Version', status: false }
        ]
      case mascara & 2:
        return [
          { name: 'Version', status: false },
          { name: 'Telecarga', status: true },
          { name: 'Configuración', status: false },
          { name: '2º Version', status: false }
        ]
      case mascara & 3:
        return [
          { name: 'Version', status: true },
          { name: 'Telecarga', status: true },
          { name: 'Configuración', status: false },
          { name: '2º Version', status: false }
        ]
      case mascara & 4:
        return [
          { name: 'Version', status: false },
          { name: 'Telecarga', status: false },
          { name: 'Configuración', status: true },
          { name: '2º Version', status: false }
        ]
      case mascara & 5:
        return [
          { name: 'Version', status: true },
          { name: 'Telecarga', status: false },
          { name: 'Configuración', status: true },
          { name: '2º Version', status: false }
        ]
      case mascara & 6:
        return [
          { name: 'Version', status: false },
          { name: 'Telecarga', status: true },
          { name: 'Configuración', status: true },
          { name: '2º Version', status: false }
        ]
      case mascara & 7:
        return [
          { name: 'Version', status: true },
          { name: 'Telecarga', status: true },
          { name: 'Configuración', status: true },
          { name: '2º Version', status: false }
        ]
      case mascara & 8:
        return [
          { name: 'Version', status: false },
          { name: 'Telecarga', status: false },
          { name: 'Configuración', status: false },
          { name: '2º Version', status: true }
        ]
      case mascara & 9:
        return [
          { name: 'Version', status: true },
          { name: 'Telecarga', status: false },
          { name: 'Configuración', status: false },
          { name: '2º Version', status: true }
        ]
      case mascara & 10:
        return [
          { name: 'Version', status: false },
          { name: 'Telecarga', status: true },
          { name: 'Configuración', status: false },
          { name: '2º Version', status: true }
        ]
      case mascara & 11:
        return [
          { name: 'Version', status: true },
          { name: 'Telecarga', status: true },
          { name: 'Configuración', status: false },
          { name: '2º Version', status: true }
        ]
      case mascara & 12:
        return [
          { name: 'Version', status: false },
          { name: 'Telecarga', status: false },
          { name: 'Configuración', status: true },
          { name: '2º Version', status: true }
        ]
      case mascara & 13:
        return [
          { name: 'Version', status: true },
          { name: 'Telecarga', status: false },
          { name: 'Configuración', status: true },
          { name: '2º Version', status: true }
        ]
      case mascara & 14:
        return [
          { name: 'Version', status: false },
          { name: 'Telecarga', status: true },
          { name: 'Configuración', status: true },
          { name: '2º Version', status: true }
        ]
        
      case mascara & 15:
        return [
          { name: 'Version', status: true },
          { name: 'Telecarga', status: true },
          { name: 'Configuración', status: true },
          { name: '2º Version', status: true }
        ]
      case mascara & 129:
        return [
          { name: 'Version', status: true },
          { name: 'Telecarga', status: false },
          { name: 'Configuración', status: false },
          { name: '2º Version', status: false }
        ]
      case mascara & 130:
        return [
          { name: 'Version', status: false },
          { name: 'Telecarga', status: true },
          { name: 'Configuración', status: false },
          { name: '2º Version', status: false }
        ]
      case mascara & 131:
        return [
          { name: 'Version', status: true },
          { name: 'Telecarga', status: true },
          { name: 'Configuración', status: false },
          { name: '2º Version', status: false }
        ]
      case mascara & 132:
        return [
          { name: 'Version', status: false },
          { name: 'Telecarga', status: false },
          { name: 'Configuración', status: true },
          { name: '2º Version', status: false }
        ]
      case mascara & 133:
        return [
          { name: 'Version', status: true },
          { name: 'Telecarga', status: false },
          { name: 'Configuración', status: true },
          { name: '2º Version', status: false }
        ]
      case mascara & 134:
        return [
          { name: 'Version', status: false },
          { name: 'Telecarga', status: true },
          { name: 'Configuración', status: true },
          { name: '2º Version', status: false }
        ]
      case mascara & 135:
        return [
          { name: 'Version', status: true },
          { name: 'Telecarga', status: true },
          { name: 'Configuración', status: true },
          { name: '2º Version', status: false }
        ]
      case mascara & 136:
        return [
          { name: 'Version', status: false },
          { name: 'Telecarga', status: false },
          { name: 'Configuración', status: false },
          { name: '2º Version', status: true }
        ]
      case mascara & 137:
        return [
          { name: 'Version', status: true },
          { name: 'Telecarga', status: false },
          { name: 'Configuración', status: false },
          { name: '2º Version', status: true }
        ]
      case mascara & 138:
        return [
          { name: 'Version', status: false },
          { name: 'Telecarga', status: true },
          { name: 'Configuración', status: false },
          { name: '2º Version', status: true }
        ]
      case mascara & 139:
        return [
          { name: 'Version', status: true },
          { name: 'Telecarga', status: true },
          { name: 'Configuración', status: false },
          { name: '2º Version', status: true }
        ]
      case mascara & 140:
        return [
          { name: 'Version', status: false },
          { name: 'Telecarga', status: false },
          { name: 'Configuración', status: true },
          { name: '2º Version', status: true }
        ]
      case mascara & 141:
        return [
          { name: 'Version', status: true },
          { name: 'Telecarga', status: false },
          { name: 'Configuración', status: true },
          { name: '2º Version', status: true }
        ]
      case mascara & 142:
        return [
          { name: 'Version', status: false },
          { name: 'Telecarga', status: true },
          { name: 'Configuración', status: true },
          { name: '2º Version', status: true }
        ]
        
      case mascara & 143:
        return [
          { name: 'Version', status: true },
          { name: 'Telecarga', status: true },
          { name: 'Configuración', status: true },
          { name: '2º Version', status: true }
        ]
      default:
        return [
          { name: 'Version', status: false },
          { name: 'Telecarga', status: false },
          { name: 'Configuración', status: false },
          { name: '2º Version', status: false }
        ]
    }
  }
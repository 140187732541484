
import { configureStore } from "@reduxjs/toolkit";
import  authReducer  from '../reducers/auth/authReducer';
import contractsReducer from "../reducers/contracts/contractsReducer";
import uiReducer from "../reducers/ui/uiReducer";
import mapReducer from "../reducers/map/mapReducer";
import nodesReducer from "../reducers/nodes/nodesReducer";
import panelsReducer from "../reducers/panels/panelsReducer";
import gatewaysReducers from "../reducers/gateways/gatewaysReducers";
import usersReducer from "../reducers/users/usersReducer";
import socketReducer from "../reducers/socket/socketReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    contracts: contractsReducer,
    nodes: nodesReducer,
    panels: panelsReducer,
    gateways: gatewaysReducers,
    ui: uiReducer,
    users: usersReducer,
    map: mapReducer,
    socket: socketReducer
  },
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: true, 
  //devTools: process.env.NODE_ENV !== 'production',
});

//export default store;



/*
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
    auth: authReducer,
});

export const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(thunk)
    )
);
*/


import React, { useState } from 'react';
import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import { Button, Backdrop, Fade, Typography } from '@mui/material'; // Actualización a MUI
import moment from 'moment';
import { styled } from '@mui/system';

// Estilos usando styled de Material-UI
const BackdropStyled = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));

const normalize = (() => {
  var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
    to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
    mapping = {};

  for (var i = 0, j = from.length; i < j; i++)
    mapping[from.charAt(i)] = to.charAt(i);

  return function (str) {
    var ret = [];
    for (var i = 0, j = str.length; i < j; i++) {
      var c = str.charAt(i);
      if (mapping.hasOwnProperty(str.charAt(i)))
        ret.push(mapping[c]);
      else
        ret.push(c);
    }
    return ret.join('');
  };
})();

const DownloadExcel = ({ data, fileName }) => {
  const [query, setQuery] = useState('idle');

  const getDocumentExcel = async (callback) => {
    const fields = Object.keys(data[0]);
    const replacer = (key, value) => (value === null ? '' : value);
    let csv = data.map(row =>
      fields.map(fieldName => "=" + JSON.stringify(row[fieldName], replacer)).join(';')
    );
    csv.unshift(fields.join(';'));
    csv = normalize(csv.join('\r\n'));

    const blob = new Blob([csv], {
      type: "application/vnd.ms-excel;charset=utf-8",
    });
    saveAs(blob, `${fileName}.csv`);
    callback();
  };

  const handleClickQuery = () => {
    if (query !== 'idle') {
      setQuery('idle');
      return;
    }
    setQuery('progress');
    getDocumentExcel(() => {
      setQuery('success');
    });
  };

  return (
    <>
      {query === 'success' ? (
        <Typography>Éxito</Typography>
      ) : (
        <Fade
          in={query === 'progress'}
          style={{
            transitionDelay: query === 'progress' ? '800ms' : '0ms',
          }}
          unmountOnExit
        >
          <BackdropStyled open={true}>
            <Typography>Cargando</Typography>
          </BackdropStyled>
        </Fade>
      )}
      <Button
        variant="contained"
        color="primary"
        size='small'
        onClick={handleClickQuery}
      >
        {query !== 'idle' ? "Reiniciar" : "Descargar"}
      </Button>
    </>
  );
};

DownloadExcel.defaultProps = {
  data: [],
  fileName: moment().format("YYYY-MM-DDTHH:mm:ss"),
};

DownloadExcel.propTypes = {
  data: PropTypes.array.isRequired,
  fileName: PropTypes.string,
};

export default DownloadExcel;

export const api = () => {

    const api = localStorage.getItem('api')
    
    return {
        auth: api? process.env.REACT_APP_AUTH_API.split(',')[parseInt(api)]: process.env.REACT_APP_AUTH_API.split(',')[0],
        data: api? process.env.REACT_APP_DATA_API.split(',')[parseInt(api)] : process.env.REACT_APP_DATA_API.split(',')[0],
        nodeRed: api? process.env.REACT_APP_NODE_RED.split(',')[parseInt(api)] : process.env.REACT_APP_NODE_RED.split(',')[0],
    }
} 

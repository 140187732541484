import { createAsyncThunk } from '@reduxjs/toolkit';
import Swal from 'sweetalert2';
import { fetchConToken, handleError } from '../../helpers/fetch';
import theme from '../../material-ui/theme';
//import i18n from '../../i18n/i18n';
import {
  gatewaysLoad,
  gatewaysActive,
  gatewaysActiveItem,
  gatewaysRemoveActiveItem,
} from './gatewaysReducers';
import { loadStatusGatewayText } from '../../helpers/gateways/statusGateways';

const fields = [
  'id',
  'latitude',
  'longitude',
  'name',
  'gateway_number',
  'status_date',
  'dimming_profile_id',
  'exception_profile_id',
  'status_flag',
  'serial_number',
];

export const startGatewaysLoad = createAsyncThunk(
  'gateways/startGatewaysLoad',
  async (contractId, { dispatch }) => {

    if (contractId) {
      try {
        const response = await fetchConToken(`contract/${contractId}/nodes/gateways?fields=${fields.join(',')}`);
        if (!response.ok) 
          throw response.status;
        const gateways = await response.json();
        dispatch(gatewaysLoad(gateways));
      } catch (error) {
        handleError(error, dispatch);
      }
    }
  }
);

export const startGatewaysCreate = createAsyncThunk(
  'gateways/startGatewaysCreate',
  async (data, { dispatch, getState }) => {
    const { contracts: { activeContract } } = getState();

    if (activeContract && activeContract.id && typeof data === 'object') {
      Swal.fire({
        title: `${'Loading'}...`,
        text: 'Please wait',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => Swal.showLoading(),
      });

      try {
        const response = await fetchConToken(`contract/${activeContract.id}/nodes/gateways`, data, 'POST');
        if (!response.ok && response.status !== 405) throw response.status;
        //const result = await response.json();
        if (response.status === 405) {
          Swal.fire({
            titleText: 'Error',
            text: loadStatusGatewayText(1, data),
            icon: 'warning',
            confirmButtonColor: theme.palette.primary.main,
          });
        } else {
          dispatch(startGatewaysLoad());
          Swal.fire({
            titleText: 'Saved',
            text: ("¡{{name}} update!", { name: data?.name || '' }),
            icon: 'success',
            confirmButtonColor: theme.palette.primary.main,
          });
        }
      } catch (error) {
        handleError(error, dispatch);
      } finally {
        Swal.close();
      }
    }
  }
);

export const startGatewaysUpdate = createAsyncThunk(
  'gateways/startGatewaysUpdate',
  async (data, { dispatch, getState }) => {
    const { contracts: { activeContract }, gateways: { gateways } } = getState();

    if (typeof data !== 'object' || !activeContract || !gateways) {
      return;
    }

    Swal.fire({
      title: `${'Loading'}...`,
      text: 'Please wait',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => Swal.showLoading(),
    });

    try {
      const response = await fetchConToken(`contract/${activeContract.id}/nodes/gateways/${data.gateway_number}`, { ...data }, 'PATCH');
      if (!response.ok && response.status !== 405) throw response.status;
      //const result = await response.json();
      const gateway = gateways.find(e => e.gateway_number === data.gateway_number);
      if (response.status === 405) {
        Swal.fire({
          titleText: 'Error',
          text: loadStatusGatewayText(1, gateway),
          icon: 'warning',
          confirmButtonColor: theme.palette.primary.main,
        });
      } else {
        dispatch(gatewaysLoad([...gateways.map(e => e.gateway_number === data.gateway_number ? { ...e, ...data } : e)]));
        Swal.fire({
          titleText: 'Saved',
          text: ("¡{{name}} update!", { name: gateway?.name }),
          icon: 'success',
          confirmButtonColor: theme.palette.primary.main,
        });
      }
    } catch (error) {
      handleError(error, dispatch);
    } finally {
      Swal.close();
    }
  }
);


/*
export const startGatewaysMultipleUpdate = createAsyncThunk(
  'gateways/startGatewaysMultipleUpdate',
  async ({ devices, status_flag = 1 }, { dispatch, getState }) => {
    const { contracts: { active } } = getState();

    if (active && active.id && Array.isArray(devices) && devices.length > 0) {
      Swal.fire({
        title: `${i18n.t('Loading')}...`,
        text: i18n.t('Please wait'),
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => Swal.showLoading(),
      });

      try {
        const requests = [
          fetchConToken(`${active.id}/nodes/gateways?fields=${fields.toString()}`),
          fetchConToken(`${active.id}/nodes/gateways?status_flag=${status_flag}`, devices, 'PATCH'),
        ];
        const [gatewaysResponse, updateResponse] = await Promise.all(requests);

        if (!gatewaysResponse.ok && gatewaysResponse.status !== 405) throw gatewaysResponse.status;
        if (!updateResponse.ok && updateResponse.status !== 405) throw updateResponse.status;

        const [gateways, results] = await Promise.all([
          gatewaysResponse.json(),
          updateResponse.json()
        ]);

        const gatewaysNoOk = gateways.filter(e => !testFlagGateway(e.status_flag));
        const devicesNoOk = gatewaysNoOk.filter(e => devices.find(item => item.id === e.id));

        dispatch(startGatewaysLoad());

        const text = `${i18n.t("Han sido updates {{success}} de {{total}} equipos seleccionados", { success: results.changedRows, total: devices.length })} 
          ${devicesNoOk.length > 0 ? `${i18n.t('Los siguientes {{name}} no pueden ser updates por el momento, intentelo más tarde', { name: i18n.t('Gateways') })}:
          ${devicesNoOk.map(e => loadStatusGatewayText(e.status_flag, e))}` : ''}`;

        Swal.fire({
          titleText: devicesNoOk.length > 0 ? i18n.t('Atención') : i18n.t('Saved'),
          text,
          icon: devicesNoOk.length > 0 ? 'warning' : 'success',
          confirmButtonColor: theme.palette.primary.main,
        });
      } catch (error) {
        handleError(error, dispatch);
      } finally {
        Swal.close();
      }
    }
  }
);
*/

export const startGatewaysActiveItem = createAsyncThunk(
  'gateways/startGatewaysActiveItem',
  async (gateway_number, { dispatch, getState }) => {
    const { contracts: { activeContract } } = getState();

    if (activeContract && activeContract.id) {
      try {
        const response = await fetchConToken(`contract/${activeContract.id}/nodes/gateways/${gateway_number}`);
        if (!response.ok) throw response.status;
        const gateway = await response.json();
        dispatch(gatewaysActiveItem(gateway.length ? gateway[0] : null));
      } catch (error) {
        handleError(error, dispatch);
      }
    }
  }
);

export const startGatewaysDelete = createAsyncThunk(
  'gateways/startGatewaysDelete',
  async (gateway_number, { dispatch, getState }) => {
    const { contracts: { activeContract }, gateways: { gateways } } = getState();
    const gateway = gateways.find(e => parseInt(e.gateway_number) === parseInt(gateway_number));

    if (activeContract && activeContract.id && gateway) {
      Swal.fire({
        title: `${'Loading'}...`,
        text: 'Please wait',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => Swal.showLoading(),
      });

      try {
        const response = await fetchConToken(`contract/${activeContract.id}/nodes/gateways/${gateway_number}`, null, 'DELETE');
        if (!response.ok) throw response.status;
        await response.json();

        Swal.fire({
          titleText: 'Saved',
          text: ("¡{{name}} update!", { name: gateway?.name }),
          icon: 'success',
          confirmButtonColor: theme.palette.primary.main,
        });

        dispatch(gatewaysRemoveActiveItem());
        dispatch(gatewaysActive([{ id: parseInt(gateway.id) }]));
        dispatch(gatewaysLoad(gateways.filter(e => parseInt(e.gateway_number) !== parseInt(gateway_number))));
      } catch (error) {
        handleError(error, dispatch);
      } finally {
        Swal.close();
      }
    }
  }
);

export const startGatewaysPing = createAsyncThunk(
  'gateways/startGatewaysPing',
  async (gateway_number, { dispatch, getState }) => {
    const { contracts: { activeContract }, gateways: { gateways } } = getState();
    if (activeContract && activeContract.id && !isNaN(gateway_number)) {
      const name = gateways.find(e => e.gateway_number === gateway_number)?.name || "";

      Swal.fire({
        title: `${'Loading'}...`,
        text: 'Please wait',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => Swal.showLoading(),
      });

      try {
        const response = await fetchConToken(`contract/${activeContract.id}/nodes/gateways/${gateway_number}/ping`, {}, 'POST');
        if (!response.ok) throw response.status;
        const action = await response.json();
        Swal.fire({
          titleText: name,
          text: action?.msg,
          icon: action?.msg === 'is alive' ? 'success' : 'error',
          confirmButtonColor: theme.palette.primary.main,
        });
      } catch (error) {
        handleError(error, dispatch);
      } finally {
        Swal.close();
      }
    }
  }
);

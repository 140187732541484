



import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react'
import ListAltIcon from '@mui/icons-material/ListAlt';
import { InventoryDevices } from './InventoryDevices/InventoryDevices';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useDownloadSelectedDevicesJson } from '../../hooks/useDownloadSelectedDevicesJson';

export const Inventory = () => {

    const [state, setState] = useState(false);

    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpen = () => {
        setState(true)
    }

    const handleClose = () => {
        setState(false)
    }

    const downloadJson = useDownloadSelectedDevicesJson();

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={state}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
                maxWidth={false}
            >

                <DialogTitle id="responsive-dialog-title">{"Inventario"}</DialogTitle>

                <DialogContent>
                    <InventoryDevices />
                </DialogContent>

                <DialogActions>
                    <Tooltip title={'Descargar equipos seleccionados'}>
                        <FileDownloadIcon onClick={downloadJson} />
                    </Tooltip>
                    <Button onClick={handleClose} color="primary" variant="contained" autoFocus>
                        {"Cerrar"}
                    </Button>
                </DialogActions>

            </Dialog>

            <Fab
                onClick={handleOpen}
                role="presentation"
                style={{
                    position: 'fixed',
                    bottom: 24,
                    right: 128,
                    zIndex: 10,
                }}
                color="primary"
                size="small"
                aria-label="drawer"
            >
                <Tooltip title={"Inventario"} aria-label="add">
                    <ListAltIcon />
                </Tooltip>
            </Fab>
        </>
    )
}



import React from 'react';
import { TextField } from '@mui/material';  // Actualización de Material-UI a MUI
import PropTypes from 'prop-types';

export const CustomInputNumber = ({ max, min, step, onChange, ...rest }) => {

    const handleChange = (e) => {
        let value = Number(e.target.value);

        if (value > max) {
            value = max;
        }
        if (value < min) {
            value = min;
        }

        // Dispara el evento con el valor corregido
        onChange({
            ...e,
            target: {
                ...e.target,
                value,
            }
        });
    };

    return (
        <>
            <TextField
                {...rest}
                type="number"
                onChange={handleChange}
                InputProps={{
                    inputProps: {
                        max, 
                        min, 
                        step
                    }
                }}
                fullWidth
                sx={{ minWidth: 150 }}  
            />
        </>
    );
}

CustomInputNumber.propTypes = {
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

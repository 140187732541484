



import React, { useEffect, useRef } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { startChecking } from '../../reducers/auth/authActions';
import { Dashboard } from '../../components/Dashboard/Dashboard';
import { selectSuperAdminContracts } from '../../reducers/contracts/contractsSelectors';
import { SelectedDeviceTools } from '../../components/SelectedDeviceTools/SelectedDeviceTools';
import { AdvancedMonitoringMapScreen } from '../../components/AdvancedMonitoringMapScreen/AdvancedMonitoringMapScreen';
import { Inventory } from '../../components/Inventory/Inventory';
import { PanelsTelechargeScreen } from '../../components/PanelsTelechargeScreen/PanelsTelechargeScreen';
import { NavBar } from '../../components/NavBar/NavBar';
import { ResponsiveContainer } from '../../components/common/ResponsiveContainer/ResponsiveContainer'
import { DeviceManagementScreen } from '../../components/DeviceManagmentScreen/DeviceManagementScreen';
import { DeviceManagementGatewaysScreen } from '../../components/DeviceManagmentScreen/DeviceManagementGatewaysScreen/DeviceManagementGatewaysScreen';
import { DeviceManagementNodesStorageSettingsScreen } from '../../components/DeviceManagmentScreen/DeviceManagementNodesStorageSettingsScreen/DeviceManagementNodesStorageSettingsScreen';
import { FastUploadScreen } from '../../components/DeviceManagmentScreen/FastUploadScreen/FastUploadScreen';
import { NewContractScreen } from '../../components/contracts/NewContractScreen/NewContractScreen';
import { UsersManagement } from '../../components/UsersManagement/UsersManagement';
import { UserProfileScreen } from '../../components/UsersManagement/UserProfileScreen/UserProfileScreen';
import { UserUpdateScreen } from '../../components/UsersManagement/UserUpdateScreen/UserUpdateScreen';
import { ContractsScreen } from '../../components/contracts/ContractsScreen/ContractsScreen';
import { ContractScreen } from '../../components/contracts/ContractScreen/ContractScreen';
import { UserCreateScreen } from '../../components/UsersManagement/UserCreateScreen/UserCreateScreen';
import { RegistrationsGatewaysScreen } from '../../components/RegistrationsGatewaysScreen/RegistrationsGatewaysScreen';
import { NodesTelechargeNBIoTScreen } from '../../components/NodesTelechargeNBIoTScreen/NodesTelechargeNBIoTScreen';
import { NodeREDScreen } from '../../components/NodeREDScreen/NodeREDScreen';






export const AuthRoutes = () => {
  const isLoaded = useRef(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const checking = useSelector((state) => state.auth.checking);
  const user = useSelector((state) => state.auth.user);
  const superAdminContracts = useSelector(selectSuperAdminContracts);



  useEffect(() => {
    console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")
    if (!isLoaded.current) {
      dispatch(startChecking());
      isLoaded.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
    if (!checking && isLoaded.current) {
      const currentPath = location.pathname;
      if (user && superAdminContracts.length > 0) {
        if (currentPath === '/' || currentPath === '/login') {
          navigate('/dashboard', { replace: true });
        }
      } else {
        navigate('/login', { replace: true });
      }
    }
  }, [checking, user, superAdminContracts, navigate, location]);

  /*
useEffect(() => {
    console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")
    if (!isLoaded.current) {
      dispatch(startChecking());
      isLoaded.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
    if (!checking && isLoaded.current) {
      const currentPath = location.pathname;
      if (user && superAdminContracts.length > 0) {
        if (currentPath === '/' || currentPath === '/login') {
          navigate('/dashboard', { replace: true });
        }
      } else {
        navigate('/login', { replace: true });
      }
    }
  }, [checking, user, superAdminContracts, navigate, location]);
  */



  return (<>
    <NavBar>
    <SelectedDeviceTools />
    <Inventory />
    <ResponsiveContainer>
      <Routes>
        
        <Route path='/dashboard' element={<Dashboard />} />

        <Route path='/monitoring' element={<AdvancedMonitoringMapScreen />} />

        <Route path='/device-management' element={<DeviceManagementScreen />} />

        <Route path='/device-management/gateways' element={<DeviceManagementGatewaysScreen/>} />

        <Route path='/device-management/nodes/storage-settings' element={<DeviceManagementNodesStorageSettingsScreen />} />

        <Route path='/device-management/nodes/fast-upload' element={<FastUploadScreen />} />

        <Route path='/telecharge/panels' element={<PanelsTelechargeScreen />} />

        <Route path='/contracts' element={<ContractsScreen />} />

        <Route path='/contracts/settings' element={<ContractScreen />} />

        <Route path='/new-contract' element={<NewContractScreen />} />

        <Route path='/users-management' element={<UsersManagement />} />

        <Route path='/users-management/user-profile/:id' element={<UserProfileScreen/>}/>

        <Route path='/users-management/user-profile/:id/update' element={<UserUpdateScreen/>}/>

        <Route path='/users-management/create' element={<UserCreateScreen />} />

        <Route path='/registrations' element={<RegistrationsGatewaysScreen />} />

        <Route path='/telecharge/nodes/nbiot-settings' element={<NodesTelechargeNBIoTScreen />} />
        <Route path='/telecharge/nodes/node-red' element={<NodeREDScreen />} />

      </Routes>
    </ResponsiveContainer>
    </NavBar>

  </>);
};










import React, { useState, useEffect, useMemo } from 'react';
import { AppBar, Tabs, Tab, Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
import { TabPanel } from '../../common/TabPanel/TabPanel';
import { NodesList } from '../../common/Tables/SelectedLists/NodesList/NodesList';
import { PanelsList } from '../../common/Tables/SelectedLists/PanelsList/PanelsList';
import theme from '../../../material-ui/theme';
import { GatewaysList } from '../../common/Tables/SelectedLists/GatewaysList/GatewaysList';

const a11yProps = (index) => {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
};

export const CustomTableSelectedDevicesTabs = () => {
  const activeNodes = useSelector((state) => state.nodes.active);
  const nodes  = useSelector((state) => state.nodes.nodes);
  const activePanels = useSelector((state) => state.panels.active);
  const panels = useSelector((state) => state.panels.panels);
  const activeGateways = useSelector((state) => state.gateways.active);
  const gateways  = useSelector((state) => state.gateways.gateways);

  const [value, setValue] = useState(0);

  const dataPanels = useMemo(() => {
    return panels.filter((panel) => activePanels.map(ap => Number(ap.id)).includes(panel.id));
  }, [activePanels, panels]);

  const dataNodes = useMemo(() => {
    return nodes.filter((node) => activeNodes.map(an => Number(an.id)).includes(node.id));
  }, [activeNodes, nodes]);

  const dataGateways = useMemo(() => {
    return gateways.filter((gateway) => activeGateways.map(ag => Number(ag.id)).includes(gateway.id));
  }, [activeGateways, gateways]);

  const panelColumns = [
    { id: 'select', label: '' },
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'Nombre' },
    { id: 'firmware_version', label: 'Versión de Firmware' },
    { id: 'ip', label: 'Dirección IP' },
    { id: 'deleteAndSummary', label: '' }
  ];

  const nodeColumns = [
    { id: 'select', label: '' },
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'Nombre' },
    { id: 'firmware_version', label: 'Versión de Firmware' },
    { id: 'ip', label: 'Dirección IP' },
    { id: 'deleteAndSummary', label: '' }
  ];
  

  const gatewayColumns = [
    { id: 'select', label: '' },
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'Nombre' },
    { id: 'deleteAndSummary', label: '' }
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    activePanels.length > 0 && {
      title: 'Centros de mando',
      icon: <Avatar src={theme.devicesIcons?.panel?.avatar} sx={{ borderRadius: 0 }} />,
      panel: <PanelsList data={dataPanels} columns={panelColumns} />
    },
    activeNodes.length > 0 && {
      title: 'Nodos',
      icon: <Avatar src={theme.devicesIcons?.node?.avatar} sx={{ borderRadius: 0 }} />,
      panel: <NodesList data={dataNodes} columns={nodeColumns} />
    },
    activeGateways.length > 0 && {
      title: 'Gateways',
      icon: <Avatar src={theme.devicesIcons?.gateway?.avatar} sx={{ borderRadius: 0 }} />,
      panel: <GatewaysList data={dataGateways} columns={gatewayColumns} />
    }
  ].filter((e) => e !== false);

  useEffect(() => {
    if (value >= tabs.length) {
      setValue(0);
    }
  }, [value, tabs]);

  return (
    <div style={{ flexGrow: 1, width: '100%', backgroundColor: theme.palette.background.paper }}>
      <AppBar position="static" color="default" sx={{ marginBottom: theme.spacing(1) }}>
        <Tabs
          value={value >= tabs.length ? 0 : value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          {tabs.map((e, i) => (
            <Tab key={e.title} label={e.title} icon={e.icon} {...a11yProps(i)} />
          ))}
        </Tabs>
      </AppBar>

      {tabs.map((e, i) => (
        <TabPanel key={e.title} value={value} index={i}>
          {e.panel}
        </TabPanel>
      ))}
    </div>
  );
};


/*
import React, { useState, useEffect, useMemo } from 'react';
import { AppBar, Tabs, Tab, Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
import { TabPanel } from '../../common/TabPanel/TabPanel';
import { NodesList } from '../../common/Tables/SelectedLists/NodesList/NodesList';
import { PanelsList } from '../../common/Tables/SelectedLists/PanelsList/PanelsList';
import theme from '../../../material-ui/theme';
import { GatewaysList } from '../../common/Tables/SelectedLists/GatewaysList/GatewaysList';

const a11yProps = (index) => {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
};

export const CustomTableSelectedDevicesTabs = () => {

  const { active: activeNodes, nodes } = useSelector((state) => state.nodes);
  const { active: activePanels, panels } = useSelector((state) => state.panels);
  const { active: activeGateways, gateways } = useSelector((state) => state.gateways);


  const [value, setValue] = useState(0);

  const dataPanels = useMemo(() => {
    return panels.filter((panel) => activePanels.map(ap => Number(ap.id)).includes(panel.id));
  }, [activePanels, panels]);


  const dataNodes = useMemo(() => {
    return nodes.filter((node) => activeNodes.map(an => Number(an.id)).includes(node.id));
  }, [activeNodes, nodes]);

  const dataGateways = useMemo(() => {
    return gateways.filter((gateway) => activeGateways.map(ag => Number(ag.id)).includes(gateway.id));
  }, [activeGateways, gateways]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    activePanels.length > 0 && {
      title: 'Centros de mando',
      icon: <Avatar src={theme.devicesIcons?.panel?.avatar} sx={{ borderRadius: 0 }} />,
      panel: <PanelsList data={dataPanels} />,
    },
    activeNodes.length > 0 && {
      title: 'Nodos',
      icon: <Avatar src={theme.devicesIcons?.node?.avatar} sx={{ borderRadius: 0 }} />,
      panel: <NodesList data={dataNodes} />,
    },
    activeGateways.length > 0 && {
      title: 'Gateways',
      icon: <Avatar src={theme.devicesIcons?.gateway?.avatar} sx={{ borderRadius: 0 }} />,
      panel: <GatewaysList data={dataGateways} />,
    },
  ].filter((e) => e !== false);

  useEffect(() => {
    if (value >= tabs.length) {
      setValue(0);
    }
  }, [value, tabs]);

  return (
    <div style={{ flexGrow: 1, width: '100%', backgroundColor: theme.palette.background.paper }}>
      <AppBar position="static" color="default" sx={{ marginBottom: theme.spacing(1) }}>
        <Tabs
          value={value >= tabs.length ? 0 : value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          {tabs.map((e, i) => (
            <Tab key={e.title} label={e.title} icon={e.icon} {...a11yProps(i)} />
          ))}
        </Tabs>
      </AppBar>

      {tabs.map((e, i) => (
        <TabPanel key={e.title} value={value} index={i}>
          {e.panel}
        </TabPanel>
      ))}
    </div>
  );
};
*/




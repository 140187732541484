

import moment from 'moment';
import { useSelector } from 'react-redux';
import { calc_too } from '../helpers/loadSunriseAndSunset';


export const useTonightSunsetSunrise = (onlyToday = false) => {

    const sunriseSunsetDate = useSelector(state => state.ui.sunriseSunsetDate);
    const activeContract = useSelector(state => state.contracts.activeContract);

    const today = (sunriseSunsetDate && !onlyToday) ? new Date(sunriseSunsetDate) : new Date();

    const todaySunriseAndSunset = (activeContract?.latitude && activeContract?.longitude) && calc_too.calcular_too2(today, activeContract.latitude, activeContract.longitude, 0);

    const tomorrow = (sunriseSunsetDate && !onlyToday) ? new Date(sunriseSunsetDate) : new Date();

    tomorrow.setDate(tomorrow.getDate() + 1);

    const tomorrowSunriseAndSunset = (activeContract?.latitude && activeContract?.longitude) && calc_too.calcular_too2(tomorrow, activeContract.latitude, activeContract.longitude, 0);

    const sunrise = (tomorrowSunriseAndSunset) && moment(`${tomorrowSunriseAndSunset.dia}-${tomorrowSunriseAndSunset.mes} ${tomorrowSunriseAndSunset.ortoHora}:${tomorrowSunriseAndSunset.ortoMin}`, 'D-M HH:mm');

    const sunset = (todaySunriseAndSunset) && moment(`${todaySunriseAndSunset.dia}-${todaySunriseAndSunset.mes} ${todaySunriseAndSunset.ocasoHora}:${todaySunriseAndSunset.ocasoMin}`, 'D-M HH:mm');

    return {
        sunrise,
        sunset,
    }

}


import React from 'react';
import { Container, Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { ContractsList } from './ContractsList/ContractsList';
import { CustomTitle } from '../../common/CustomTitle/CustomTitle';

export const ContractsScreen = () => {
    const navigate = useNavigate();

    const handleNewContract = () => {
        navigate('/new-contract');
    };

    return (
        <Container sx={{ paddingTop: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
                    <CustomTitle title="Contratos" />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddBoxIcon />}
                        onClick={handleNewContract}
                    >
                        Crear contrato
                    </Button>
                </Grid>

                <Grid container spacing={3} sx={{ paddingTop: 4 }}>
                    <Grid item xs={12}>
                        <ContractsList />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};






import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    loading: false,
    msgError: null,
    sunriseSunsetDate: null,
};


const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        uiSetError: (state, action) => {
            state.msgError = action.payload;
        },
        uiRemoveError: (state) => {
            state.msgError = null;
        },
        uiStartLoading: (state) => {
            state.loading = true;
        },
        uiFinishLoading: (state) => {
            state.loading = false;
        },
    },
});

// Exportamos las acciones
export const { uiSetError, uiRemoveError, uiStartLoading, uiFinishLoading } = uiSlice.actions;




// Exportamos el reducer
export default uiSlice.reducer;



export const isWriteAvailable = (permissions) => {
    switch(permissions) {
        case 'supervisor': 
            return false;
        default:
            return true;
    }
}

export const isUsersManagementAvailable = (permissions) => {
    switch(permissions) {
        case 'supervisor': 
            return false;
        case 'manager': 
            return false;
        default:
            return true;
    }
}

export const areAdvanceOptionsAvailable = (permissions) => {
    switch(permissions) {
        case 'super_administrator': 
            return true;
        default:
            return false;
    }
}

export const permissionsToInteger = (permissions) => {
    switch(permissions){
        case 'super_administrator':
            return 0;
        case 'administrator' :
            return 1;
        case 'manager': 
            return 2;
        default:
            return 3;
    }
}
export const integerToPermissions = (permissions) => {
    switch(permissions){
        case 0:
            return 'super_administrator';
        case 1 :
            return 'administrator';
        case 2: 
            return 'manager';
        default:
            return 'supervisor';
    }
}
export const permissionsMarks = [
    {
      value: 0,
      label: 'super_administrator',
    },
    {
      value: 1,
      label: 'administrator',
    },
    {
      value: 2,
      label: 'manager',
    },
    {
      value: 3,
      label: 'supervisor',
    },
  ];


import { CssBaseline } from '@mui/material'
import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { createTheme } from '@mui/material'
import { red } from '@mui/material/colors'

import mainNode from '../assets/png/map/green-dot.png';
import mainVirtualNode from '../assets/png/map/virtual-green-dot.png';
import alarmNode from '../assets/png/map/yellow-dot.png';
import newNode from '../assets/png/map/blue-dot.png';
import failNode from '../assets/png/map/indigo-dot.png';
import criticalNode from '../assets/png/map/red-dot.png';
import nodeAuxPink from '../assets/png/map/pink-dot.png';
import nodeAuxLemon from '../assets/png/map/lemon-dot.png';
import nodeAuxPurple from '../assets/png/map/purple-dot.png';
import nodeAuxOrange from '../assets/png/map/orange-dot.png';
import nodeAuxSalmon from '../assets/png/map/salmon-dot.png';
import mainActiveNode from '../assets/png/map/big-green-dot.png';
import mainActiveVirtualNode from '../assets/png/map/big-virtual-green-dot.png';
import alarmActiveNode from '../assets/png/map/big-yellow-dot.png';
import newActiveNode from '../assets/png/map/big-blue-dot.png';
import failActiveNode from '../assets/png/map/big-indigo-dot.png';
import criticalActiveNode from '../assets/png/map/big-red-dot.png';
import activeNodeAuxPink from '../assets/png/map/big-pink-dot.png';
import activeNodeAuxLemon from '../assets/png/map/big-lemon-dot.png';
import activeNodeAuxPurple from '../assets/png/map/big-purple-dot.png';
import activeNodeAuxOrange from '../assets/png/map/big-orange-dot.png';
import activeNodeAuxSalmon from '../assets/png/map/big-salmon-dot.png';
import panel from '../assets/png/map/panel.png';
import activePanel from '../assets/png/map/active-panel.png';
import failPanel from '../assets/png/map/fail-panel.png';
import gateway from '../assets/png/map/gateway.png';
import activeGateway from '../assets/png/map/active-gateway.png';
import failGateway from '../assets/png/map/fail-gateway.png';
import avatarNode from '../assets/png/map/avatar-green-dot.png';
import avatarVirtualNode from '../assets/png/map/avatar-virtual-green-dot.png';
import avatarPanel from '../assets/png/map/avatar-panel.png';
import avatarGateway from '../assets/png/map/avatar-gateway.png';
import clustererM1 from '../assets/png/map/clusterer/m1.png';
import mainAlert from '../assets/png/map/alert.png';
import incidence from '../assets/png/map/alert.png';

import icon from '../assets/png/tellink171.png';
import backgroundImageLogin from '../assets/jpg/background.jpg';
import logo1920 from '../assets/png/tellink1920.png';
import logo171 from '../assets/png/logo171.png';
const icon_secondary = undefined;
const primaryColor = '#ffc90e';
const secondaryColor = '#0a3c5f';
const tertiary = '#9c9b99';


const theme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: [
      'Arial'
    ].join(','),
  },
  palette: {
    primary: {
      main: primaryColor,
      attenuated: primaryColor+'60',
    },
    secondary: {
      main: secondaryColor,
      attenuated: secondaryColor+'60',
    },
    tertiary: {
      main: tertiary,
      attenuated: tertiary+'60',
    },
    quaternary: {
      main: "#008000",
      attenuated: '#00800060',
    },
    fifth: {
      main: "#03BB85",
      attenuated: '#03BB8560',
    },
    sixth: {
      main: "#015D52",
      attenuated: '#015D5260',
    },
    seventh: {
      main: "#EEB2EE",
      attenuated: '#EEB2EE60',
    },
    inherit: {
      main: secondaryColor,
      attenuated: secondaryColor+'60',
    }
  },
  icon,
  icon_secondary,
  logo171,
  logo1920,
  backgroundImageLogin,
  devicesIcons: {
    alert: {
      main: mainAlert
    },
    node: {
      main: mainNode,
      fail: failNode,
      alarm: alarmNode,
      critical: criticalNode,
      new: newNode,
      auxPink: nodeAuxPink,
      auxLemon: nodeAuxLemon,
      auxPurple: nodeAuxPurple,
      auxOrange: nodeAuxOrange,
      auxSalmon: nodeAuxSalmon,
      active: {
        main: mainActiveNode,
        fail: failActiveNode,
        alarm: alarmActiveNode,
        critical: criticalActiveNode,
        new: newActiveNode,
        auxPink: activeNodeAuxPink,
        auxLemon: activeNodeAuxLemon,
        auxPurple: activeNodeAuxPurple,
        auxOrange: activeNodeAuxOrange,
        auxSalmon: activeNodeAuxSalmon,
      },

      picture: avatarNode,
      avatar: avatarNode,
    },
    virtualNode:{
      main: mainVirtualNode,
      avatar: avatarVirtualNode,
      active: {
        main: mainActiveVirtualNode,
      },
    },
    panel: {
      main: panel,
      fail: failPanel,
      active: {
        main: activePanel,
      },
      avatar: avatarPanel,
      picture: avatarPanel,
    },
    gateway: {
      main: gateway,
      fail: failGateway,
      active: {
        main: activeGateway,
      },
      avatar: avatarGateway,
    },
    incidence: {
      main: incidence,
    },
    clusterer: [
      {
        textColor: 'white',
        url: clustererM1,
        height: 63,
        width: 63
      },
      {
        textColor: 'white',
        url: clustererM1,
        height: 63,
        width: 63
      },
      {
        textColor: 'white',
        url: clustererM1,
        height: 63,
        width: 63
      },
      {
        textColor: 'white',
        url: clustererM1,
        height: 63,
        width: 63
      },
      {
        textColor: 'white',
        url: clustererM1,
        height: 63,
        width: 63
      },
      {
        textColor: 'white',
        url: clustererM1,
        height: 63,
        width: 63
      }
    ]
  }
});

export default theme;

export const purpleTheme = createTheme({
  palette: {
    primary: {
      main: '#ffc90e'
    },
    secondary: {
      main: '#0a3c5f'
    },
    error: {
      main: red.A400
    },
    backgroundAuth: {
      default: '#FEFFE6'
    }
  }
})

export const AppTheme = ({ children }) => {


  return (
    <ThemeProvider theme={purpleTheme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}








/*import { createTheme } from '@material-ui/core/styles';


// SERVIDORES DE TSmart-Tellink
import icon from '../assets/png/tellink171.png';
import backgroundImageLogin from '../assets/jpg/background.jpg';
import logo1920 from '../assets/png/tellink1920.png';
import logo171 from '../assets/png/logo171.png';
const icon_secondary = undefined;
const primaryColor = '#ffc90e';
const secondaryColor = '#0a3c5f';
const tertiary = '#9c9b99';


const theme = createTheme({
    typography: {
      fontSize: 14,
      fontFamily: [
        'Arial'
      ].join(','),
    },
    palette: {
      primary: {
        main: primaryColor,
        attenuated: primaryColor+'60',
      },
      secondary: {
        main: secondaryColor,
        attenuated: secondaryColor+'60',
      },
      tertiary: {
        main: tertiary,
        attenuated: tertiary+'60',
      },
      quaternary: {
        main: "#008000",
        attenuated: '#00800060',
      },
      fifth: {
        main: "#03BB85",
        attenuated: '#03BB8560',
      },
      sixth: {
        main: "#015D52",
        attenuated: '#015D5260',
      },
      seventh: {
        main: "#EEB2EE",
        attenuated: '#EEB2EE60',
      },
      inherit: {
        main: secondaryColor,
        attenuated: secondaryColor+'60',
      }
    },
    /*
    icon,
    icon_secondary,
    logo171,
    logo1920,
    backgroundImageLogin,
    devicesIcons: {
      alert: {
        main: mainAlert
      },
    }
      
});

export default theme;

*/
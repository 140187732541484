

import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';

export const GoBack = ({ to }) => {
  const navigate = useNavigate();

  const handleRouter = () => {
    if (to) {
      navigate(to);
    } else {
      navigate(-1); // Ir atrás en el historial
    }
  };

  return (
    <IconButton
      edge="end"
      aria-label="go-back"
      onClick={handleRouter}
      sx={{ width: 56, height: 56, marginRight: 1 }} 
    >
      <ArrowBackIosIcon />
    </IconButton>
  );
};

GoBack.propTypes = {
  to: PropTypes.string,
};

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NodesMarker from './NodesMarker/NodesMarker'
import MarkerClusterGroup from 'next-leaflet-cluster'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import validator from 'validator'
import {
  mapFinishLoadingNodesLayer,
  mapStartLoadingNodesLayer
} from '../../../../reducers/map/mapReducer'
import { thereIsAFail } from '../../../../helpers/common'

const NodesLayer = React.memo(() => {
  const nodes = useSelector(state => state.nodes.nodes)
  const failure_window_in_hours = useSelector(
    state => state.contracts.activeContract.failure_window_in_hours
  )
  const active = useSelector(state => state.nodes.active)

  const [renderedMarkersCount, setRenderedMarkersCount] = useState(0)

  const dispatch = useDispatch()

  const filteredNodes = useMemo(
    () =>
      nodes
        .map(e => ({
          ...e,
          possition: [parseFloat(e.latitude), parseFloat(e.longitude)]
        }))
        .filter(e => {
          return validator.isLatLong(`(${e.possition[0]}, ${e.possition[1]})`)
        }),
    [nodes]
  )

  useEffect(() => {
    if (renderedMarkersCount >= filteredNodes.length) {
      dispatch(mapFinishLoadingNodesLayer())
    } else {
      dispatch(mapStartLoadingNodesLayer())
    }
  }, [renderedMarkersCount, filteredNodes.length, dispatch])

  const handleMarkerRendered = useCallback(() => {
    setRenderedMarkersCount(prevCount => prevCount + 1)
  }, [setRenderedMarkersCount])

  return (
    <MarkerClusterGroup
      disableClusteringAtZoom={17}
      chunkedLoading
      maxClusterRadius={50}
      spiderfyOnMaxZoom={true}
      showCoverageOnHover={true}
    >
      {filteredNodes &&
        filteredNodes.map(
          (node, index) =>
            node && (
              <NodesMarker
                key={index}
                possition={node.possition}
                data={node}
                active={
                  active.find(e => parseInt(e.id) === parseInt(node.id))
                    ? true
                    : false
                }
                fail={thereIsAFail(
                  node.last_gateway_connection,
                  failure_window_in_hours
                )}
                alarm={node.alarm !== 0}
                newDevice={node.new !== 0}
                gatewayNumber={node.current_gateway_number}
                firmwareVersion={node.firmware_version}
                onRender={handleMarkerRendered}
              />
            )
        )}
    </MarkerClusterGroup>
  )
})

export default NodesLayer




import React from 'react';
import PropTypes from 'prop-types';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import SupervisedUserCircleTwoToneIcon from '@mui/icons-material/SupervisedUserCircleTwoTone';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'; 
import ReplayIcon from '@mui/icons-material/Replay';
import { useDispatch, useSelector } from 'react-redux';
import { startContractsActive } from '../../../reducers/contracts/contractsActions';
import { useNavigate } from 'react-router-dom'; 

export const UsersMenu = React.memo(({ anchorEl, menuId, isMenuOpen, handleMenuClose }) => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Separo las llamadas de redux para evitar el acceso al state general y no crear warnings
  const activeContract = useSelector(state => state.contracts.activeContract);
  const user= useSelector((state) => state.auth.user);

  const handleRoute = (route) => {
    navigate(route);
    handleMenuClose();
  };

  const handleReloadContract = () => {
    dispatch(startContractsActive(activeContract?.id));
  };

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => { handleRoute('/contracts') }}>
        <ListItemIcon>
          <FontAwesomeIcon icon={faExchangeAlt} />
        </ListItemIcon>
        <ListItemText primary="Contratos" />
      </MenuItem>


      <MenuItem onClick={() => { handleRoute(`/users-management/user-profile/${user.id}`) }}>
        <ListItemIcon>
          <AccountCircleTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary="Perfil" />
      </MenuItem>

      <MenuItem onClick={() => { handleRoute('/users-management') }}>
        <ListItemIcon>
          <SupervisedUserCircleTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary="Gestión de usuarios" />
      </MenuItem>


      <MenuItem onClick={handleReloadContract}>
        <ListItemIcon>
          <ReplayIcon />
        </ListItemIcon>
        <ListItemText primary="Recargar datos básicos" />
      </MenuItem>
    </Menu>
  );
});

UsersMenu.propTypes = {
  anchorEl: PropTypes.object,
  menuId: PropTypes.string.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
};





import React from 'react';
import { loadStatus } from '../../../helpers/socket';
import { useSelector } from 'react-redux';
import { Grid, LinearProgress, Typography } from '@mui/material';

const DisplayResponseTelecs = React.memo(({ result }) => {
  const panels = useSelector(state => state.panels.panels);

  return (
    <>
      {result &&
        result.result &&
        result.result.msg &&
        Array.isArray(result.result.msg) && 
        result.topic.indexOf('telecs') !== -1 && (
        <>
          {result.result.msg.map(e => {
            const device = panels.find(item => item.ip === e.ip);
            if (!device) {
              return null;
            }
            let progress = null;
            if (e.num_file && e.total_file) {
              progress = parseInt(((e.num_file + 1) / e.total_file) * 100, 10);
            }
            return (
              <Grid container key={device?.id}>
                <Grid item xs={4}>
                  <Typography>
                    {device?.name}, {device?.ip}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {progress !== null && (
                    <>
                      <LinearProgress variant="determinate" value={progress} />
                      {progress}%
                    </>
                  )}
                  <Typography variant="caption">
                    {loadStatus(e?.estado)}
                  </Typography>
                  <br />
                  <Typography variant="caption">{e.result}</Typography>
                </Grid>
              </Grid>
            );
          })}
        </>
      )}
    </>
  );
});

export default DisplayResponseTelecs;




import { AppTheme } from './material-ui/theme';
import { AppRouter } from './router/AppRouter';
//import { ThemeProvider } from '@mui/material';
//import { AppTheme } from './theme/AppTheme';

export const App = () => {
  return (
    <>
      <AppTheme> 
        <AppRouter />
      </AppTheme>
    </>
  )
}










import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  panels: [],
  active: [],
  activeItem: [], // Aunque no se use más, se mantiene por compatibilidad estructural.
};

const panelsSlice = createSlice({
  name: 'panels',
  initialState,
  reducers: {
    panelsLoad: (state, action) => {
      const newPanels = action.payload.filter(
        newPanel => !state.panels.some(existingPanel => existingPanel.id === newPanel.id)
      );
      state.panels = [...state.panels, ...newPanels];
    },

    panelsActive: (state, action) => {
      state.active = [
        ...action.payload
          .filter(element => !state.active.some(e => e.id === Number(element.id)))
          .map(element => ({ id: Number(element.id) })),
        ...state.active.filter(element => !action.payload.some(e => Number(e.id) === element.id))
      ];
    },

    panelsUpdate: (state, action) => {
      const updatedPanels = action.payload;
      state.panels = state.panels.map(p =>
        updatedPanels.find(up => up.id === p.id) || p
      );
      state.active = state.active.map(p =>
        updatedPanels.find(up => up.id === p.id) || p
      );
    },

    panelsRemoveActive: (state) => {
      state.active = [];
    },

    panelsRemoveActiveById: (state, action) => {
      const itemId = Number(action.payload);
      state.active = state.active.filter(item => item.id !== itemId);
    },

    panelsActiveItem: (state, action) => {
      const newItem = { id: Number(action.payload.id) };
      const exists = state.active.some(item => item.id === newItem.id);

      if (!exists) {
        state.active = [...state.active, newItem];
      }
    },

    panelsRemoveActiveItemById: (state, action) => {
      const itemId = Number(action.payload);
      state.active = state.active.filter(item => item.id !== itemId);
    },

    panelsRemoveActiveItem: (state) => {
      state.active = [];
    },

    panelsLogoutCleaning: () => initialState,
  },
});

export const {
  panelsLoad,
  panelsActive,
  panelsUpdate,
  panelsRemoveActive,
  panelsActiveItem,
  panelsRemoveActiveItemById,
  panelsRemoveActiveItem,
  panelsLogoutCleaning,
} = panelsSlice.actions;

export default panelsSlice.reducer;

/*
// panelsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  panels: [],
  active: [],
  activeItem: [],
};

const panelsSlice = createSlice({
  name: 'panels',
  initialState,
  reducers: {
    panelsLoad: (state, action) => {
      const newPanels = action.payload.filter(
        newPanel => !state.panels.some(existingPanel => existingPanel.id === newPanel.id)
      );
      state.panels = [...state.panels, ...newPanels];
    },
    panelsActive: (state, action) => {
      state.active = [
        ...action.payload.filter(element => !state.active.find(e => e.id === element.id)),
        ...state.active.filter(element => !action.payload.find(e => e.id === element.id))
      ];
    },
    panelsUpdate: (state, action) => {
      const updatedPanels = action.payload;
      state.panels = state.panels.map(p =>
        updatedPanels.find(up => up.id === p.id) || p
      );
      state.active = state.active.map(p =>
        updatedPanels.find(up => up.id === p.id) || p
      );
    },
    panelsRemoveActive: (state) => {
      state.active = [];
    },
    panelsActiveItem: (state, action) => {
      const newItem = action.payload;
      const exists = state.activeItem.some(item => item.id === newItem.id);
      
      // Si no existe, agregarlo al array
      if (!exists) {
        state.activeItem = [...state.activeItem, newItem];
      }
    },
    panelsRemoveActiveItemById: (state, action) => {
      const itemId = action.payload;
      state.activeItem = state.activeItem.filter(item => item.id !== itemId);
    },
    panelsRemoveActiveItem: (state) => {
      state.activeItem = [];
    },
    panelsLogoutCleaning: () => initialState,
  },
});

export const {
  panelsLoad,
  panelsActive,
  panelsUpdate,
  panelsRemoveActive,
  panelsActiveItem,
  panelsRemoveActiveItemById,
  panelsRemoveActiveItem,
  panelsLogoutCleaning,
} = panelsSlice.actions;

export default panelsSlice.reducer;
*/
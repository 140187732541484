


import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, Avatar } from '@mui/material'; // Actualización a MUI
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';

import { CustomTitle } from '../../common/CustomTitle/CustomTitle';
import { GoBack } from '../../common/GoBack/GoBack';
//import { DeviceManagementGatewaysForm } from './DeviceManagementGatewaysForm/DeviceManagementGatewaysForm';
import { startGatewaysActiveItem } from '../../../reducers/gateways/gatewaysActions';
import { gatewaysRemoveActiveItem } from '../../../reducers/gateways/gatewaysReducers';
//import { GatewaysTable } from '../../../common/Tables/GatewaysTable/GatewaysTable';
import { styled } from '@mui/system';
import { GatewaysList } from '../../common/Tables/SelectedLists/GatewaysList/GatewaysList';


const RootContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const FlexGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
}));

const CenteredContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
}));

export const DeviceManagementGatewaysScreen = () => {
  const { gateways } = useSelector(state => state.gateways);
  const dispatch = useDispatch();
  const { id } = useParams();

  const gatewayColumns = [
    { id: 'select', label: '' },
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'Nombre' },
    { id: 'deleteAndSummary', label: '' }
  ];

  useEffect(() => {
    const found = gateways.find(e => e.id === parseInt(id));
    if (id && found) {
      dispatch(startGatewaysActiveItem(found?.gateway_number));
    }
  }, [id, dispatch, gateways]);

  useEffect(() => {
    return () => {
      dispatch(gatewaysRemoveActiveItem());
    };
  }, [dispatch]);

  return (
    <RootContainer maxWidth={false}>
      <Grid container spacing={1}>
        <FlexGrid item xs={12}>
          <GoBack />
          <CustomTitle title={`Gestión de dispositivos ~ Gateways`} icon={<Avatar> <FontAwesomeIcon icon={faTasks} /> </Avatar>} />
        </FlexGrid>

        <CenteredContainer container spacing={3}>
          {!id ? (
            <Grid item xs={12} sm={6}>
              {/*<GatewaysTable fields={['id', 'serial_number', 'name']} />*/}
              <GatewaysList data={gateways} columns={gatewayColumns} />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6}>
              {/*<DeviceManagementGatewaysForm />*/}
            </Grid>
          )}
        </CenteredContainer>
      </Grid>
    </RootContainer>
  );
};



import React from 'react';
import PublishIcon from '@mui/icons-material/Publish';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import ReactFileReader from 'react-file-reader';
import Papa from 'papaparse';

import { CustomTitle } from '../../common/CustomTitle/CustomTitle';
import { GoBack } from '../../common/GoBack/GoBack';
import DownloadExcel from '../../common/Download/DownloadExcel/DownloadExcel';
import { useDispatch, useSelector } from 'react-redux';
import { startNodesMultipleUpdate } from '../../../reducers/nodes/nodesActions';
import { styled } from '@mui/system';

// Uso de styled en lugar de makeStyles
const Root = styled(Container)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const FixedGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
}));

const CenteredContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'center',
}));

export const FastUploadScreen = () => {
  const dispatch = useDispatch();

  const { nodes } = useSelector(state => state.nodes);

  const data = [
    {
      eui: '',
      ip: '',
      icc: '',
      serial_number: ''
    }
  ];

  const handleFiles = files => {
    Papa.parse(files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        try {
          const matchs = results.data
            .map(e => e?.eui ? ({
              ...e,
              eui: e.eui.toLowerCase().split("_").join("")
            }) : e)
            .filter(e =>
              nodes.find(
                item =>
                  ((e.ip && item.ip === e.ip) || (e.eui && item.eui === e.eui)) &&
                  (item.icc !== e.icc || item.serial_number !== e.serial_number)
              )
            )
            .map(e => {
              const node = nodes.find(item => ((e.ip && item.ip === e.ip) || (e.eui && item.eui === e.eui)));
              for (let property in e) {
                if (!e[property]) {
                  delete e[property];
                }
              }
              return {
                ...e,
                id: node.id
              };
            });
          dispatch(startNodesMultipleUpdate(matchs, 0));
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  return (
    <Root maxWidth={false}>
      <Grid container spacing={3}>
        <FixedGrid item xs={12}>
          <GoBack />
          <CustomTitle
            title={`Carga rápida ICC y Nº de Serie ~ Luminarias`}
            icon={
              <Avatar>
                <PublishIcon />
              </Avatar>
            }
          />
        </FixedGrid>

        <CenteredContainer container>
          <Grid item className='animate__animated animate__fadeIn'>
            <Card>
              <CardHeader
                title="Carga rápida ICC y Nº de Serie"
                subheader="Luminarias"
                avatar={
                  <Avatar>
                    <PublishIcon />
                  </Avatar>
                }
              />
              <Divider />
              <CardContent>
                <Typography>1 - Descargue la plantilla.</Typography>
                <Typography>
                  2 - Rellene atentamente la plantilla con los datos de los que disponga (importante la coincidencia de IP).
                </Typography>
                <Typography>
                  3 - Cargue en la plataforma el archivo modificado.
                </Typography>
              </CardContent>
              <Divider />
              <CardActions>
                <ReactFileReader handleFiles={handleFiles} fileTypes={'.csv'}>
                  <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    fullWidth
                  >
                    Cargar
                  </Button>
                </ReactFileReader>
              </CardActions>
              <Divider />
              <CardActions>
                <Typography>Descargar plantilla:</Typography>
                <DownloadExcel data={data} fileName='template' />
              </CardActions>
            </Card>
          </Grid>
        </CenteredContainer>
      </Grid>
    </Root>
  );
};

//export default FastUploadScreen;



import { useSelector } from 'react-redux';

export const useDownloadSelectedDevicesJson = () => {
  const activeContract = useSelector((state) => state.contracts.activeContract);
  const activeNodes = useSelector((state) => state.nodes.active);
  const activePanels = useSelector((state) => state.panels.active);
  const activeGateways = useSelector((state) => state.gateways.active);

  const downloadJson = () => {
    const jsonData = {
      id_contract: activeContract?.id,
      panels: activePanels,
      nodes: activeNodes,
      gateways: activeGateways,
    };

    const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(jsonData, null, 2)
    )}`;
    
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = 'selectedDevices.json';
    link.click();
  };

  return downloadJson;
};

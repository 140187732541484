

import moment from 'moment';


export const loadFromTo = (type) => {
    const date = moment();
    const to = date.format('YYYY-MM-DDTHH:mm:ss');
    switch (type) {
        case 'Last week (minutes)': {
            const from = date.add(-7, 'days').format('YYYY-MM-DDTHH:mm:ss');
            return [from, to, 'minute', 'dd D MMM Y'];
        }
        case 'Last week': {
            const from = date.add(-7, 'days').format('YYYY-MM-DDTHH:mm:ss');
            return [from, to, 'days', 'dd D MMM Y'];
        }
        case 'Last month': {
            const from = date.add(-30, 'days').format('YYYY-MM-DDTHH:mm:ss');
            return [from, to, 'days', 'dd D MMM Y'];
        }
        case 'Last month (compare)': {
            const from = date.add(-30, 'days').format('YYYY-MM-DDTHH:mm:ss');
            return [from, to, 'days', 'dd D MMM Y'];
        }
        case 'Last three months': {
            const from = date.add(-3, 'months').format('YYYY-MM-DDTHH:mm:ss');
            return [from, to, 'month', 'MMM Y'];
        }
        case 'Last six month': {
            const from = date.add(-6, 'months').format('YYYY-MM-DDTHH:mm:ss');
            return [from, to, 'month', 'MMM Y'];
        }
        case 'The month before last (compare)': {
            const to = date.add(-30, 'days').format('YYYY-MM-DDTHH:mm:ss');
            const from = date.add(-30, 'days').format('YYYY-MM-DDTHH:mm:ss');
            return [from, to, 'days', 'dd D MMM Y'];
        }
        case 'The month before last': {
            const to = date.add(-12, 'months').format('YYYY-MM-DDTHH:mm:ss');
            const from = date.add(-30, 'days').format('YYYY-MM-DDTHH:mm:ss');
            return [from, to, 'days', 'dd D MMM Y'];
        }
        case 'The three months before last': {
            const to = date.add(-12, 'months').format('YYYY-MM-DDTHH:mm:ss');
            const from = date.add(-3, 'months').format('YYYY-MM-DDTHH:mm:ss');
            return [from, to, 'month', 'MMM Y'];
        }
        case 'The six months before last': {
            const to = date.add(-12, 'months').format('YYYY-MM-DDTHH:mm:ss');
            const from = date.add(-6, 'months').format('YYYY-MM-DDTHH:mm:ss');
            return [from, to, 'month', 'MMM Y'];
        }
        case 'The year before last': {
            const to = date.add(-12, 'months').format('YYYY-MM-DDTHH:mm:ss');
            const from = date.add(-12, 'months').format('YYYY-MM-DDTHH:mm:ss');
            return [from, to, 'month', 'MMM Y'];
        }
        case 'The week before last': {
            const to = date.add(-7, 'days').format('YYYY-MM-DDTHH:mm:ss');
            const from = date.add(-7, 'days').format('YYYY-MM-DDTHH:mm:ss');
            return [from, to, 'days', 'dd D MMM Y'];
        }
        case 'The week before last (minutes)': {
            const to = date.add(-7, 'days').format('YYYY-MM-DDTHH:mm:ss');
            const from = date.add(-7, 'days').format('YYYY-MM-DDTHH:mm:ss');
            return [from, to, 'minute', 'dd D MMM Y'];
        }

        default: {
            const from = date.add(-12, 'months').format('YYYY-MM-DDTHH:mm:ss');
            return [from, to, 'month', 'MMM Y'];
        }
    }
}

export const loadBeforeType = (type) => {
    switch (type) {
        case 'Last week (minutes)':
            return 'The week before last (minutes)';
        case 'Last week':
            return 'The week before last';
        case 'Last month (compare)':
            return 'The month before last (compare)';
        case 'Last month':
            return 'The month before last';
        case 'Last three months':
            return 'The three months before last';
        case 'Last six month':
            return 'The six months before last';
        default:
            return 'The year before last';
    }
}

//
import { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import moment from 'moment';
import { api } from '../api/api';
import { Buffer } from 'buffer';

export const useSocketIo = () => {
  const socket = useRef(null);
  const request = useRef({});
  const [result, setResult] = useState({});
  const [history, setHistory] = useState([]);
  const [status, setStatus] = useState('disconnected');


  const activeContract = useSelector((state) => state.contracts.activeContract);

  const handleChangeResult = useCallback((data) => {
    setResult(data);
  }, []);

  // Establecer la conexión del socket
  useEffect(() => {
    if (activeContract && socket.current === null) {
      const token = localStorage.getItem('token') || '';
      socket.current = io(`${api().data.replace('/api', '')}`, {
        path: '/api/mqtt',
        transports: ['websocket'],
        auth: {
          username: token,
          password: '',
          contract_id: activeContract.id,
        },
        reconnection: true,
        reconnectionAttempts: 50,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
      });

      socket.current.on('connect', () => {
        console.log("CONNECTED");
        setStatus('connected');
      });
      socket.current.on('disconnect', () => {console.log("DISCONNECTED");setStatus('disconnected')});
      socket.current.on('error', (error) => {
        console.error(error);
        setStatus('error');
      });
      socket.current.on('reconnect', (attemptNumber) => {
        setStatus(`reconnect ${attemptNumber}`);
      });
      socket.current.on('reconnect_failed', () => {
        setStatus('reconnect_failed');
      });
    }

    // Desconectar al desmontar
    return () => {
      if (socket.current) {
        socket.current.disconnect();
        socket.current = null;
      }
    };
  }, [activeContract]);

  // Enviar eventos al servidor
  const send = useCallback((topic, data) => {
    if (socket.current) {
      request.current = { topic, data };
      socket.current.emit(topic, data);
    }
  }, []);

  // Suscribirse a eventos del servidor
  const subscribe = useCallback(
    (topics = [], callback) => {
      topics.forEach((topic) => {
        socket.current?.on(topic, (lastJsonMessage) => {
          let result
          if (Buffer.isBuffer(lastJsonMessage)) {
            result = lastJsonMessage.toString('utf8')
          } else if (lastJsonMessage instanceof ArrayBuffer) {
            const buffer = Buffer.from(lastJsonMessage)
            result = buffer.toString('utf8')
          } else {
            result = JSON.parse(JSON.stringify(lastJsonMessage))
          }
          const data = {
            command: request.current.data.command,
            topic,
            typeof: typeof result,
            date: moment().format('YYYY-MM-DD HH:mm:ss'),
            result: result,
          };
          setHistory((prevHistory) => [...prevHistory, { ...data, request: request.current }]);
          setResult(data);
        });
      });
      callback();
    },
    []
  );

  // Desuscribirse de eventos
  const unSubscribe = useCallback((topics = [], callback) => {
    topics.forEach((topic) => {
      socket.current?.off(topic, callback);
    });
  }, []);

  return {
    result,
    status,
    history,
    send,
    subscribe,
    unSubscribe,
    handleChangeResult,
  };
};





import { Backdrop, Box, CircularProgress, Container, CssBaseline, Link, Paper, Typography } from '@mui/material';
import React from 'react'
import { styled } from '@mui/system';
import logo1 from '../../../assets/png/tellink1920.png';
import { Form } from './Form/Form';
import { useNavigate } from 'react-router-dom';


const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  backgroundColor: theme.palette.backgroundAuth.default,
  padding: theme.spacing(2),
}));


const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: 450,
}));

const StyledImg = styled('img')(({ theme }) => ({
width:'45%'
}));
const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}));
const StyledLink = styled(Link)(({ theme }) => ({
cursor: 'pointer'
}));

export const ForgotPasswordScreen = () => {
  const navigate = useNavigate();



  return (
    <StyledBox>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <StyledPaper>
                    <StyledImg 
                        alt="platform logo"
                        src={logo1}
                        title="Logo"
                    />

                    <Typography component="h1" variant="h5">
                        {"Recuperación de contraseña"}
                    </Typography>

                    <Form/>

                    <StyledLink
                        variant='body2'
                        onClick={() => {navigate("/login")
                        }}
                    >
                        {"Regresar"}
                    </StyledLink>
                </StyledPaper>
            </Container>
            <StyledBackdrop >
                <CircularProgress color="primary" />
            </StyledBackdrop>
    </StyledBox>
    )
}
   



import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gateways: [],
  active: [],
  activeItem: [], // Aunque no se use más, lo dejamos para mantener compatibilidad estructural.
};

const gatewaysSlice = createSlice({
  name: 'gateways',
  initialState,
  reducers: {
    gatewaysLoad: (state, action) => {
      const newGateways = action.payload.filter(
        newGateway => !state.gateways.some(existingGateway => existingGateway.id === newGateway.id)
      );
      state.gateways = [...state.gateways, ...newGateways];
    },

    gatewaysActive: (state, action) => {
      state.active = [
        ...action.payload
          .filter(element => !state.active.some(e => e.id === Number(element.id)))
          .map(element => ({ id: Number(element.id) })),
        ...state.active.filter(element => !action.payload.some(e => Number(e.id) === element.id))
      ];
    },

    gatewaysUpdate: (state, action) => {
      const updatedGateways = action.payload;
      state.gateways = state.gateways.map(g =>
        updatedGateways.find(up => up.id === g.id) || g
      );
      state.active = state.active.map(g =>
        updatedGateways.find(up => up.id === g.id) || g
      );
    },

    gatewaysRemoveActive: (state) => {
      state.active = [];
    },

    gatewaysRemoveActiveById: (state, action) => {
      const itemId = Number(action.payload);
      state.active = state.active.filter(item => item.id !== itemId);
    },

    gatewaysActiveItem: (state, action) => {
      const newItem = { id: Number(action.payload.id) };
      const exists = state.active.some(item => item.id === newItem.id);

      if (!exists) {
        state.active = [...state.active, newItem];
      }
    },

    gatewaysRemoveActiveItemById: (state, action) => {
      const itemId = Number(action.payload);
      state.active = state.active.filter(item => item.id !== itemId);
    },

    gatewaysRemoveActiveItem: (state) => {
      state.active = [];
    },

    gatewaysLogoutCleaning: () => initialState,
  },
});

export const {
  gatewaysLoad,
  gatewaysActive,
  gatewaysUpdate,
  gatewaysRemoveActive,
  gatewaysActiveItem,
  gatewaysRemoveActiveById,
  gatewaysRemoveActiveItemById,
  gatewaysRemoveActiveItem,
  gatewaysLogoutCleaning,
} = gatewaysSlice.actions;

export default gatewaysSlice.reducer;




/*
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gateways: [],
  active: [],
  activeItem: [], // Cambiado de null a array para mantener múltiples selecciones
};

const gatewaysSlice = createSlice({
  name: 'gateways',
  initialState,
  reducers: {
    gatewaysLoad: (state, action) => {
      const newGateways = action.payload.filter(
        newGateway => !state.gateways.some(existingGateway => existingGateway.id === newGateway.id)
      );
      state.gateways = [...state.gateways, ...newGateways];
    },
    gatewaysActive: (state, action) => {
      state.active = [
        ...action.payload.filter(element => !state.active.find(e => e.id === element.id)),
        ...state.active.filter(element => !action.payload.find(e => e.id === element.id))
      ];
    },
    gatewaysUpdate: (state, action) => {
      const updatedGateways = action.payload;
      state.gateways = state.gateways.map(g =>
        updatedGateways.find(up => up.id === g.id) || g
      );
      state.active = state.active.map(g =>
        updatedGateways.find(up => up.id === g.id) || g
      );
    },
    gatewaysRemoveActive: (state) => {
      state.active = [];
    },
    gatewaysRemoveActiveById: (state, action) => {
      const itemId = action.payload;
      state.active = state.active.filter(item => item.id !== itemId);
    },
    gatewaysActiveItem: (state, action) => {
      const newItem = action.payload;
      const exists = state.active.some(item => item.id === newItem.id);
      
      if (!exists) {
        state.activeItem = [...state.activeItem, newItem];
      }
    },
    gatewaysRemoveActiveItemById: (state, action) => {
      const itemId = action.payload;
      state.activeItem = state.activeItem.filter(item => item.id !== itemId);
    },
    gatewaysRemoveActiveItem: (state) => {
      state.activeItem = [];
    },
    gatewaysLogoutCleaning: () => initialState,
  },
});

export const {
  gatewaysLoad,
  gatewaysActive,
  gatewaysUpdate,
  gatewaysRemoveActive,
  gatewaysActiveItem,
  gatewaysRemoveActiveById,
  gatewaysRemoveActiveItemById,
  gatewaysRemoveActiveItem,
  gatewaysLogoutCleaning,
} = gatewaysSlice.actions;

export default gatewaysSlice.reducer;
*/

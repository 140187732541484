
import Swal from "sweetalert2";
import { purpleTheme } from "../../material-ui/theme";


export const loadStatusContractText = (flag, contract) => {
    const status_flag_text = [
        "",
        ("{{name}} is being evaluated and sent to", { name: contract.name }),
        ("{{field}} incorrect", { field: contract.name }),
        ("¡{{name}} pending elimination!", { name: contract.name }),
    ]
    return status_flag_text[flag];
}

const testFlag = (flag) => {
    switch(flag) {
        case 1: 
            return false;
        case 2: 
            return true;
        case 3: 
            return false;
        default: 
            return true;
    }
}


export const canIUpdateContract = (flag, contract) => {
    if(testFlag(flag)){
        return true;
    } else {
        Swal.fire({
            titleText: 'Error',
            text: loadStatusContractText(flag, contract),
            icon: 'warning',
            confirmButtonColor: purpleTheme.palette.primary.main,
        });
        return false;
    }
}




import { Warning } from '../common/Alerts/Warning/Warning';
import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CustomTableSelectedDevicesTabs } from './CustomTableSelectedDevicesTabs/CustomTableSelectedDevicesTabs';
import { useDownloadSelectedDevicesJson } from '../../hooks/useDownloadSelectedDevicesJson';

export const SelectedDeviceTools = () => {

    const activeNodes = useSelector((state) => state.nodes.active);
    const activePanels = useSelector((state) => state.panels.active);
    const activeGateways = useSelector((state) => state.gateways.active);

    const [state, setState] = useState(false);

    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpen = () => {
        setState(true)
    }

    const handleClose = () => {
        setState(false)
    }

    const devicesActived = (
        activeNodes.length +
        activePanels.length +
        activeGateways.length
    )

    const downloadJson = useDownloadSelectedDevicesJson();

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={state}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                fullWidth
                maxWidth="xl"
            >

                <DialogTitle id="responsive-dialog-title">{"Equipos seleccionados"}</DialogTitle>

                <DialogContent>
                    {(devicesActived > 0) ? <CustomTableSelectedDevicesTabs /> : <Warning warning={"No hay equipos seleccionados"} />}
                </DialogContent>

                <DialogActions>
                    <Tooltip title={'Descargar equipos seleccionados'}>
                        <FileDownloadIcon onClick={downloadJson} />
                    </Tooltip>
                    <Button onClick={handleClose} color="primary" variant="contained" autoFocus>
                        {"Cerrar"}
                    </Button>
                </DialogActions>

            </Dialog>

            <Fab
                onClick={handleOpen}
                role="presentation"
                style={{
                    position: 'fixed',
                    bottom: 24,
                    right: 64,
                    zIndex: 100,
                }}
                color="primary"
                size="small"
                aria-label="drawer"
            >
                <Tooltip title={"Equipos seleccionados"} aria-label="add">
                    <Badge badgeContent={devicesActived} color="secondary">
                        <PlaylistAddCheckIcon />
                    </Badge>
                </Tooltip>
            </Fab>
        </>
    )
}




import React, { useEffect, useRef } from 'react';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  Typography
} from '@mui/material';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
//import Swal from 'sweetalert2';

import { GoBack } from '../../common/GoBack/GoBack';
import { CustomTitle } from '../../common/CustomTitle/CustomTitle';
import { CustomSlider } from '../../common/CustomSlider/CustomSlider';
import { Warning } from '../../common/Alerts/Warning/Warning';
import { startUsersActive, startUsersUpdate, } from '../../../reducers/users/usersActions';
import { removeActiveUser } from '../../../reducers/users/usersReducer';
//import { subcontractsLogoutCleaning } from '../../../reducers/subcontracts/subcontractsReducer';
//import { startSubcontractsLoad } from '../../../reducers/subcontracts/subcontractsActions';
import { integerToType, typeMarks, typeToInteger } from '../../../helpers/type';
import {
  integerToPermissions,
  permissionsMarks,
  permissionsToInteger
} from '../../../helpers/permissions';
import { useForm } from '../../../hooks/useForm';

export const UserUpdateScreen = () => {
  const [values, handleInputChange, reset] = useForm({
    permissions: 3,
    type: 2,
    boss: false,
    operator: false,
    person_in_charge: false,
    subcontract_id: [],
    allSubcontracts: true
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const user = useSelector((state) => state.users.active);
  const activeContract = useSelector((state) => state.contracts.activeContract);
  const currentPermissions = useSelector((state) => state.contracts.permissions);
  const currentType = useSelector((state) => state.contracts.type);
  const maintenance = useSelector((state) => state.contracts.maintenance);
  const subcontracts = useSelector((state) => state.subcontracts.subcontracts);

  const handleSave = () => {
    dispatch(
      startUsersUpdate(
        {
          ...user,
          contract: {
            permissions: integerToPermissions(values.permissions),
            type: integerToType(values.type),
            boss: values.boss ? 1 : 0,
            operator: values.operator ? 1 : 0,
            person_in_charge: values.person_in_charge ? 1 : 0,
            subcontract_id: values.allSubcontracts ? '-1' : `,${values.subcontract_id.toString()},`
          }
        },
        () => {
          navigate(-1);
        }
      )
    );
  };

  const refId = useRef(null);
  useEffect(() => {
    if (refId.current !== id) {
      dispatch(startUsersActive(id));
      refId.current = id;
    }
  }, [id, dispatch]);

  const refUser = useRef(null);
  useEffect(() => {
    if (user !== refUser.current) {
      reset({
        permissions: permissionsToInteger(user?.contract.permissions),
        type: typeToInteger(user?.contract.type),
        boss: user?.contract.boss ? true : false,
        operator: user?.contract.operator ? true : false,
        person_in_charge: user?.contract.person_in_charge ? true : false,
        subcontract_id: user?.contract.subcontract_id === null ? [] : user?.contract.subcontract_id.split(",").filter(e => e && e !== '-1'),
        allSubcontracts: user?.contract.subcontract_id === null ? true : user?.contract.subcontract_id.split(",").includes('-1')
      });
      refUser.current = user;
    }
  }, [user, reset]);

  useEffect(() => {
    //dispatch(startSubcontractsLoad());
    return () => {
      //dispatch(subcontractsLogoutCleaning());
      dispatch(removeActiveUser());
    };
  }, [dispatch]);

  return (
    <Container maxWidth={false} sx={{ padding: 2 }}>
      <Grid container spacing={1} className="animate__animated animate__fadeIn animate__fast">
        <Grid item xs={12} sx={{ display: 'flex' }}>
          <GoBack />
          <CustomTitle
            title={user ? user.user_name : 'Sin información'}
            icon={
              <Avatar>
                <AccountCircleTwoToneIcon />
              </Avatar>
            }
          />
        </Grid>
        <Grid item xs={12}>
          {!user ? (
            <Warning warning="Sin información" />
          ) : (
            <Card>
              <CardHeader
                title={`Permisos de ${user?.name} en el contrato ${activeContract?.name}`}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                  <Grid item sx={{ minWidth: 360 }}>
                    <Typography id="discrete-slider-permissions" gutterBottom>
                      Permisos: {integerToPermissions(values.permissions)}
                    </Typography>
                    <CustomSlider
                      name="permissions"
                      onChange={handleInputChange}
                      value={values.permissions}
                      aria-labelledby="discrete-slider-permissions"
                      step={1}
                      min={permissionsToInteger(currentPermissions)}
                      max={3}
                      valueLabelDisplay="auto"
                      marks={permissionsMarks}
                      track="inverted"
                    />
                    <InputLabel shrink htmlFor="select-multiple-native">
                      Sub-Contratos
                    </InputLabel>
                    <Select
                      multiple
                      native
                      value={values.subcontract_id}
                      onChange={handleInputChange}
                      inputProps={{
                        id: 'select-multiple-native'
                      }}
                      name="subcontract_id"
                      disabled={values.allSubcontracts}
                    >
                      {subcontracts.map((e) => (
                        <option key={e.id} value={e.id}>
                          {e.name}
                        </option>
                      ))}
                    </Select>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.allSubcontracts}
                          onChange={handleInputChange}
                          name="allSubcontracts"
                          color="primary"
                        />
                      }
                      label="Todos"
                    />
                    {typeToInteger(currentType) > 1 && (
                      <>
                        <Typography id="discrete-slider-type" gutterBottom>
                          Tipo: {integerToType(values.type)}
                        </Typography>
                        <CustomSlider
                          name="type"
                          onChange={handleInputChange}
                          value={values.type}
                          aria-labelledby="discrete-slider-type"
                          step={1}
                          min={0}
                          max={typeToInteger(currentType)}
                          valueLabelDisplay="auto"
                          marks={typeMarks}
                        />
                      </>
                    )}
                    {maintenance && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.boss}
                              onChange={handleInputChange}
                              name="boss"
                              color="primary"
                            />
                          }
                          label="Jefe"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.operator}
                              onChange={handleInputChange}
                              name="operator"
                              color="primary"
                            />
                          }
                          label="Operador"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.person_in_charge}
                              onChange={handleInputChange}
                              name="person_in_charge"
                              color="primary"
                            />
                          }
                          label="Encargado"
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  Guardar
                </Button>
              </CardActions>
            </Card>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

import React from 'react'
import {  Typography } from '@mui/material'
import { Fragment } from 'react'
import theme from '../../../../../material-ui/theme'

export const ChatHistoryList = React.memo(({ flow }) => {
  const getColor = (type) => {
    switch(type){
      case "request":
        return theme.palette.secondary.main
      case "response":
        return theme.palette.quaternary.main
      default:
        return "black"
    }
  }
  return (
    <>
      <Typography >
      {flow &&
        flow.length > 0 &&
        flow.map((e, i) => (<Fragment key={i}>
          {e.type === 'request' && <br/>}
            <span style={{ fontFamily: '"Lucida Console", "Courier New", monospace', whiteSpace: "pre-line", color: getColor(), borderRadius: 0}}>{e.msg}</span>
          </Fragment>
        ))}
        </Typography>
    </>
  )
})



import { green, yellow, red } from '@mui/material/colors';

export const color = (status = 'disconnected') => {
  switch (status) {
    case 'connected':
      return green[500];
    case 'error':
      return yellow[500];
    case 'closed':
      return red[500];
    case 'disconnected':
      return red[500];
    case 'reconnect_failed':
      return red[500];
    default:
      return green[500];
  }
};

export const loadStatus = (status = 0) => {
  switch (status) {
    case 0:
      return "Inactiva";
    case 1:
      return "Iniciando";
    case 2:
      return "Abriendo socket";
    case 3:
      return "Socket ready";
    case 4:
      return 'Esperando "LOGIN?"';
    case 5:
      return "Enviando LOGIN";
    case 6:
      return 'Esperando "PASSWORD?"';
    case 7:
      return "Enviando password";
    case 8:
      return "Esperando el OK";
    case 90:
      return "Enviando 'AT+TEST' (Pidiendo VERSION)";
    case 91:
      return "Esperando Versión";
    case 9:
      return 'Enviando "AT+TELEC"';
    case 10:
      return "Esperando el OK para empezar";
    case 11:
      return "Iniciando envio de telecarga";
    case 12:
      return "Enviando telecarga";
    case 13:
      return "Fin de envio de telecarga";
    case 14:
      return "Iniciando la fase 2";

    case 201:
      return "Iniciando Socket";
    case 202:
      return "Abriendo socket";
    case 203:
      return "Socket ready";
    case 204:
      return "Esperando 'LOGIN?'";
    case 205:
      return "Enviando LOGIN";
    case 206:
      return "Esperando 'PASSWORD?'";
    case 207:
      return "Enviando password";
    case 208:
      return "Esperando el OK";
    case 209:
      return "Enviando 'AT+TEST' (Pidiendo VERSION)";
    case 291:
      return "Esperando Versión";

    case 30:
      return "Esperando reinicio del modem para Pedir nueva versión y configurar parámetros (2ª Fase)";
    case 31:
      return "Enviando el fichero de configuración";
    case 32:
      return "Esperando respuesta al fichero de configuración";
    case 33:
      return "Finalizando conexión";

    case 20:
      return "Cerrando el socket";
    case 21:
      return "Esperando el evento Closed";
    case 50:
      return "Timer de espera agotado";
    case 51:
      return "Socket cortado";
    case 100:
      return "Fin de secuencia";
    default:
      return "...";
  }
};

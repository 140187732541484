
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthRoutes } from './AuthRoutes/AuthRoutes.js'
import  LoginScreen   from '../components/auth/LoginScreen/LoginScreen'
import { ForgotPasswordScreen } from '../components/auth/ForgotPasswordScreen/ForgotPasswordScreen.js'

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<LoginScreen />} />
        <Route path='/forgot-password' element={<ForgotPasswordScreen/>}/>
        <Route path='/*' element={<AuthRoutes />} />
      </Routes>
    </BrowserRouter>
  )
}





import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { CircularProgress, Grid, Typography, Box } from '@mui/material';

import { CustomTable } from '../../../common/Tables/CustomTable/CustomTable'; 
import { useFetchAuth } from '../../../../hooks/useFetchAuth'; 
import { Error } from '../../../common/Alerts/Error/Error'; 
import { LoadFromToTimePickers } from '../../../common/LoadFromToTimePickers/LoadFromToTimePickers'; 
import { Warning } from '../../../common/Alerts/Warning/Warning'; 

const getBody = (text) => {
  const body = text.replace(/(\r\n|\n|\r)/gm, "");
  try {
    return body && Object.keys(JSON.parse(body)).length > 0 ? (
      <pre>{JSON.stringify(JSON.parse(body), null, 3)}</pre>
    ) : null;
  } catch (err) {
    console.error(err, body);
    return null;
  }
};

export const UserHistory = ({ id }) => {
  const [state, setState] = useState({ from: null, to: null, dateFormat: 'YYYY-MM-DD' });

  const handleState = useCallback(
    (value) => {
      setState(value);
    },
    [setState]
  );

  const { from, to, dateFormat } = state;
  const activeContract = useSelector((state) => state.contracts.activeContract);
  const contracts = useSelector((state) => state.contracts.contracts);
  
  const url =
    from && to && activeContract?.id
      ? `users/${id ? `${id}/` : ''}operations?from=${from}&to=${to}`
      : null;

  const { data, loading, error } = useFetchAuth(url);
  //console.log(data)
  //console.log(JSON.stringify(data, null, 2));

  const columns = [
    { id: 'date', label: 'Fecha' },
    { id: 'contract', label: 'Contrato' }, // Nueva columna
    { id: 'url', label: 'URL' },
    { id: 'method', label: 'Método' },
    { id: 'body', label: 'Contenido' },
    { id: 'text', label: 'Operación' }
  ];

  const prettyData = data?.map((e) => {
    const contract = contracts.find((contract) => contract.id === e.contract_id); 
    return {
      ...e,
      contract: contract ? contract.name : 'Sin contrato', 
      url: e.url?.split('?')[0],
    };
  });

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="overline" display="block">
            {from && to ? `${moment(from).format(dateFormat)} ~ ${moment(to).format(dateFormat)}` : 'Selecciona un rango de fechas'}
          </Typography>
          <Box sx={{ float: 'right' }}>
            <LoadFromToTimePickers handleState={handleState} />
          </Box>
        </Grid>

        {loading && <CircularProgress color="primary" />}
        {error && <Error />}
        {prettyData && prettyData.length > 0 && (
          <Grid item xs={12}>
            <CustomTable
              columns={columns}
              rows={prettyData
                .sort((a, b) => b.id - a.id)
                .map((e) => ({
                  ...e,
                  date: moment(e.date).format('dd D MMM Y HH:mm'),
                  method: e.method || 'Desconocido',
                  body: getBody(e.body) || 'Vacío',
                }))}
            />
          </Grid>
        )}
        {prettyData && prettyData.length === 0 && <Warning warning="Sin información" />}
      </Grid>
    </Box>
  );
};

UserHistory.propTypes = {
  id: PropTypes.number,
};

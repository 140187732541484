

import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchConToken, handleError } from '../../helpers/fetch';
import Swal from 'sweetalert2';
import { setActiveUser, removeActiveUser } from './usersReducer';
import { uiFinishLoading, uiSetError, uiStartLoading } from '../ui/uiReducer';

// Acción para eliminar un usuario
export const startUsersDelete = createAsyncThunk(
  'users/delete',
  async (_, { getState, rejectWithValue, dispatch }) => {
    const { contracts: { activeContract }, users: { active: { id } } } = getState();
    if (activeContract && activeContract.id && id) {
      try {
        Swal.fire({
          title: 'Cargando...',
          text: 'Por favor espera',
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });

        const result = await fetchConToken(`contract/${activeContract.id}/users/${id}`, null, 'DELETE', 'auth');
        if (!result.ok) {
          throw result.status;
        }

        Swal.fire({
          titleText: 'Guardado',
          text: 'Usuario eliminado',
          icon: 'success',
          confirmButtonColor: '#3085d6',
        });
      } catch (err) {
        handleError(err, dispatch);
        return rejectWithValue(err);
      }
    }
  }
);

// Acción para actualizar un usuario
export const startUsersUpdate = createAsyncThunk(
  'users/update',
  async (user, { getState, rejectWithValue, dispatch }) => {
    const { contracts: { activeContract } } = getState();
    if (activeContract && activeContract.id && user?.id && user?.contract) {
      try {
        Swal.fire({
          title: 'Cargando...',
          text: 'Por favor espera',
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });

        const result = await fetchConToken(`contract/${activeContract?.id}/users/${user.id}`, user.contract, 'PATCH', 'auth');
        if (!result.ok) {
          throw result.status;
        }

        Swal.fire({
          titleText: 'Guardado',
          text: 'Usuario actualizado',
          icon: 'success',
          confirmButtonColor: '#3085d6',
        });

        return result.json();
      } catch (err) {
        handleError(err, dispatch);
        return rejectWithValue(err);
      }
    }
  }
);

// Acción para crear un usuario
export const startUsersCreate = createAsyncThunk(
  'users/create',
  async ({ user, contracts }, { rejectWithValue, dispatch }) => {
    
    try {
      Swal.fire({
        title: 'Cargando...',
        text: 'Por favor espera',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      
      const bodyUser = {
        ...user,
      };

      const result = await fetchConToken(`protected/users`, bodyUser, 'POST', 'auth');
      if (!result.ok) {
        throw result.status;
      }

      //console.log("result", result)

      const userCreated = await result.json();
      //console.log("usercreate", userCreated)

      // Asociar permisos para cada contrato
      if (userCreated?.id && Array.isArray(contracts) && contracts.length > 0) {
        for (const contract of contracts) {
          const bodyPermissions = {
            ...contract,
            user_id: userCreated.id, // Asignar el ID del usuario creado
          };

          //console.log("Peticion 1", contract.id, userCreated.id)

          const permissionsResult = await fetchConToken(
            `contracts/${contract.id}/users/${userCreated.id}`,
            bodyPermissions,
            'POST',
            'auth'
          );

          if (!permissionsResult.ok) {
            throw new Error(`Error asignando permisos para el contrato ${contract.id}`);
          }
        }
      }

      Swal.fire({
        titleText: 'Guardado',
        text: 'Usuario creado y permisos asignados',
        icon: 'success',
        confirmButtonColor: '#3085d6',
      });

      return userCreated;
    } catch (err) {
      handleError(err, dispatch);
      return rejectWithValue(err);
    }
  }
);



// Acción para realizar una operación de usuario
export const startUsersOperation = createAsyncThunk(
  'users/operation',
  async (body, { getState, rejectWithValue, dispatch }) => {
    const { contracts: { activeContract } } = getState();
    if (activeContract && activeContract?.id) {
      try {
        const result = await fetchConToken(`contract/${activeContract?.id}/users/operations`, body, 'POST', 'auth');
        if (!result.ok) {
          throw result.status;
        }

        return result;
      } catch (err) {
        handleError(err, dispatch);
        return rejectWithValue(err);
      }
    }
  }
);

// Acción para activar un usuario por ID
export const startUsersActive = createAsyncThunk(
  'users/active',
  async (id, { getState, rejectWithValue, dispatch }) => {
    const state= getState();
    //console.log("ESTADO ==> : ", state)
    const { contracts: { activeContract } } = getState();
    //console.log("CONTRACT !!!!!!:", activeContract)
    if (activeContract && activeContract?.id) {
      //console.log("Lanzando actua contrct")

      try {
        Swal.fire({
          title: 'Cargando...',
          text: 'Por favor espera',
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });

        const requests = [
          `contract/${activeContract?.id}`,
          `contract/${activeContract?.id}/users/${id}`
        ].map(e => fetchConToken(e, null, 'GET', 'auth'));

        const responses = await Promise.all(requests);

        responses.forEach(res => {
          if (!res.ok) {
            throw res.status;
          }
        });

        const [usersContracts, users] = await Promise.all(responses.map(r => r.json()));

        const thisUserExist = (users && users.length > 0);
        const userContract = (thisUserExist && usersContracts && usersContracts.length > 0) &&
          usersContracts.find(e => e.user_id === users[0].id);

        if (thisUserExist && userContract) {
          dispatch(setActiveUser({
            ...users[0],
            contract: {
              ...userContract,
            }
          }));
        }

        Swal.close();
      } catch (err) {
        handleError(err, dispatch);
        return rejectWithValue(err);
      }
    }
  }
);

// Acción para activar un usuario por email
export const startUsersActiveByEmail = createAsyncThunk(
  'users/activeByEmail',
  async (email, { getState, rejectWithValue, dispatch }) => {
    const { contracts: { activeContract } } = getState();
    if (activeContract && activeContract?.id && email) {
      try {
        Swal.fire({
          title: 'Cargando...',
          text: 'Por favor espera',
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });

        const result = await fetchConToken(`contract/${activeContract?.id}/users?email=${email}`, null, 'GET', 'auth');
        if (!result.ok) {
          throw result.status;
        }

        const data = await result.json();

        if (data?.registered && data?.access === false) {
          dispatch(setActiveUser({
            id: data?.registered,
            email
          }));
        } else if (data?.registered && data?.access === true) {
          dispatch(startUsersActive(data?.registered));
        } else {
          dispatch(removeActiveUser());
        }

        Swal.close();
      } catch (err) {
        handleError(err, dispatch);
        return rejectWithValue(err);
      }
    }
  }
);


export const startResetPassword = (email) => {
  return async (dispatch) => {
      dispatch(uiStartLoading());

      try {
          const result = await fetchConToken("users/reset-password", { email }, "PATCH", 'auth');
          
          if (!result.ok) {
              throw new Error(result.status);
          }

          const data = await result.json();

          if (data?.msg === false) {
              await dispatch(uiSetError("Ya se ha iniciado el proceso de recuperación de contraseña, por favor, revise su correo electrónico"));
          } else if (data?.msg === true) {
              await dispatch(uiSetError("Por favor, revise su correo electrónico"));
          } else {
              throw new Error("Bad response");
          }
      } catch (err) {
          console.error(err);
          await dispatch(uiSetError("Se produjo un error durante el proceso de recuperación de contraseña, por favor contacte con su administrador"));
      } finally {
          dispatch(uiFinishLoading());
      }
  };
};



import React, { useState } from 'react'
// Componente principal del formulario
const DynamicForm = React.memo(({ fields, handle }) => {
  // Inicializamos el estado con los valores predeterminados del JSON
  const [formData, setFormData] = useState(
    Object.fromEntries(fields.map(field => [field.name, field.value || '']))
  )

  // Función para manejar el cambio de valores en los inputs
  const handleChange = (e, fieldType) => {
    const { name, value, checked } = e.target
    const newValue = fieldType === 'checkbox' ? checked : value

    // Actualizamos el valor del campo
    setFormData({
      ...formData,
      [name]: newValue
    })
  }

  // Función para manejar el envío del formulario
  const handleSubmit = e => {
    e.preventDefault()
    if(handle){
      handle(e)
    }
    //console.log('Formulario guardado con los siguientes datos:', formData)
    // Aquí puedes enviar los datos a un servidor, API, etc.
  }

  // Función para resetear el formulario
  const handleReset = () => {
    setFormData(
      Object.fromEntries(fields.map(field => [field.name, field.value || '']))
    )
  }

  // Renderizado del formulario dinámico
  return (
    <form onSubmit={handleSubmit}>
      {fields.map((field, index) => (
        <div key={index} style={{ marginBottom: '15px' }}>
          <label htmlFor={field.name} style={{ marginRight: '10px' }}>
            {field.label || field.name}:
          </label>

          {/* Generar los inputs según el tipo de campo */}
          {field.type === 'text' && (
            <input
              type='text'
              name={field.name}
              value={formData[field.name]}
              onChange={e => handleChange(e, field.type)}
              disabled={field.readOnly || false} // Aplica el atributo readonly si está definido en el campo
            />
          )}

          {field.type === 'number' && (
            <input
              type='number'
              name={field.name}
              value={formData[field.name]}
              onChange={e => handleChange(e, field.type)}
              disabled={field.readOnly || false}
            />
          )}

          {field.type === 'datetime' && (
            <input
              type='datetime'
              name={field.name}
              value={formData[field.name]}
              onChange={e => handleChange(e, field.type)}
              disabled={field.readOnly || false}
            />
          )}

          {field.type === 'checkbox' && (
            <input
              type='checkbox'
              name={field.name}
              checked={formData[field.name]}
              onChange={e => handleChange(e, field.type)}
              disabled={field.readOnly || false}
            />
          )}
        </div>
      ))}

      <button type='submit' style={{ marginRight: '10px' }}>
        Guardar
      </button>
      <button type='button' onClick={handleReset}>
        Resetear
      </button>
    </form>
  )
});
export default DynamicForm

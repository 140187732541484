import {
  Card,
  CardContent,
  Divider,
  FormControl,
  MenuItem,
  Select
} from '@mui/material'
import { useEffect, useState } from 'react'

export const SelectApi = () => {
  const [state, setState] = useState(-1)

  const handleChange = e => {
    const value = e.target.value
    setState(value)
    localStorage.setItem('api', value)
  }

  const apis = process.env.REACT_APP_API_NAMES?.split(',')
  useEffect(() => {
    const api = localStorage.getItem('api')
    setState(api)
  }, [])

  return (
    <>
      {apis && (
        <Card elevation={0}>
          <Divider>PLATAFORMA</Divider>
          <CardContent>
            <FormControl fullWidth>
              <Select value={state} onChange={handleChange} defaultValue={-1}>
                <MenuItem value={-1}>
                  <em>None</em>
                </MenuItem>
                {apis.map((e, i) => (
                  <MenuItem value={i} key={`${i}${e}`}>{e}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
        </Card>
      )}
    </>
  )
}




import React from 'react'
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { CustomMenu } from '../../CustomMenu/CustomMenu';
import { PingButton } from '../../PingButton/PingButton';
import { TerminalButton } from '../../TerminalButton/TerminalButton';

export const PanelsOptionsMenu = ({ id, onlyOptions = false, advanced = false }) => {

    const panels = useSelector(state => state.panels.panels);

    const panel = panels.find(e => e.id === id);

    if (!panel) {
        return null;
    } else {
        return (
            <>
                {!onlyOptions &&
                    <CustomMenu>
                        <PingButton type='panels' device={panel} variant='menuItem' />
                        <TerminalButton device={panel} variant='menuItem' />
                    </CustomMenu>
                }
            </>
        )
    }
}


PanelsOptionsMenu.propTypes = {
    onlyOptions: PropTypes.bool,
    id: PropTypes.number.isRequired,
    advanced: PropTypes.bool,
}


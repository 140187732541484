

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contracts: [],
  activeContract: null,
  permissions: null,
  type: null,
  onlyIp: null,
  maintenance: false,
  u6me2: false,
  coded_mains: false,
  superAdminContracts: [],
};

const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    contractsLoad: (state, action) => {
      state.contracts = action.payload;
      state.superAdminContracts = action.payload.filter(contract => contract.permissions === 'super_administrator');
    },
    contractsActive: (state, action) => {
      state.activeContract = {...action.payload};
    },
    contractsUpdate: (state, action) => {
      state.activeContract = {...state.payload, ...action.payload};
    },
    contractsOnlyIp: (state, action) => {
      state.onlyIp = action.payload;
    },
    contractsMaintenance: (state, action) => {
      state.maintenance = action.payload;
    },
    contractsU6me2: (state, action) => {
      state.u6me2 = action.payload;
    },
    contractsCodedMains: (state, action) => {
      state.coded_mains = action.payload;
    },
    contractsPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    contractsType: (state, action) => {
      state.type = action.payload;
    },
    contractsLogoutCleaning: () => initialState,
  },
});

export const {
  contractsLoad,
  contractsActive,
  contractsUpdate,
  contractsOnlyIp,
  contractsMaintenance,
  contractsU6me2,
  contractsCodedMains,
  contractsPermissions,
  contractsType,
  contractsLogoutCleaning,
} = contractsSlice.actions;

export default contractsSlice.reducer;

//export default contractsSlice.reducer;




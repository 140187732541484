

import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { useForm } from '../../../hooks/useForm';
import { loadFromTo } from '../../../helpers/loadFromTo';

const numberOfDays = 30;

export const LoadFromToTimePickers = ({ handleState }) => {
  const [error, setError] = useState(false);

  const [from, to] = loadFromTo('Last week');

  const [values, handleInputChange, reset] = useForm({
    from: moment(from).format('yyyy-MM-DDTHH:mm'),
    to: moment(to).add(10, 'minutes').format('yyyy-MM-DDTHH:mm'), // Add error margin
  });

  const handleSubmit = useCallback(() => {
    const start = moment(values.from);
    const end = moment(values.to);
    const number_of_columns = end.diff(start, 'days');

    if (number_of_columns <= numberOfDays) {
      handleState({
        from: values.from,
        to: values.to,
        frecuency: 'minute',
        dateFormat: 'MM-DD HH:mm',
      });
      setError(false);
    } else {
      const diff = number_of_columns - numberOfDays;
      const newTo = moment(values.to).add(-diff, 'days').format('yyyy-MM-DDTHH:mm');
      reset({
        from: values.from,
        to: newTo,
      });
      handleState({
        from: values.from,
        to: newTo,
        frecuency: 'minute',
        dateFormat: 'MM-DD HH:mm',
      });
      setError(true);
    }
  }, [values, handleState, reset]);

  const isLoaded = useRef(false);
  useEffect(() => {
    if (!isLoaded.current) {
      handleSubmit();
      isLoaded.current = true;
    }
  }, [handleSubmit]);

  return (
    <>
      {values?.from && values?.to && (
        <form noValidate>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <TextField
                error={error}
                id="datetime-local-from"
                name="from"
                type="datetime-local"
                value={values.from}
                onChange={handleInputChange}
                sx={{ width: 200 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            ~
            <Grid item>
              <TextField
                error={error}
                id="datetime-local-to"
                name="to"
                type="datetime-local"
                value={values.to}
                onChange={handleInputChange}
                sx={{ width: 200 }}
                helperText={error ? `Máximo ${numberOfDays} días` : ''}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item>
              <IconButton onClick={handleSubmit}>
                <SearchIcon />
              </IconButton>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

LoadFromToTimePickers.propTypes = {
  handleState: PropTypes.func.isRequired,
};

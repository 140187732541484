

import React from 'react';
import { useSelector } from 'react-redux';
import { CustomMenu } from '../../CustomMenu/CustomMenu';

export const NodesOptionsMenu = React.memo(({ id }) => {
  const nodes = useSelector(state => state.nodes.nodes);

  const node = nodes.find(e => e.id === id);

  if (!node) {
    return null;
  } else {
    return (
      <CustomMenu>
        {/*Por ahora no hay opciones dentro del menu de nodos*/}
      </CustomMenu>
    );
  }
});

/*
NodesOptionsMenu.propTypes = {
  id: PropTypes.number.isRequired,
};
*/




import React from 'react';
import { useSelector } from 'react-redux';

import { CircularProgress, Typography, Box } from '@mui/material';
import { UsersList } from '../../common/Tables/SelectedLists/UsersList/UsersList';
import { useFetchAuth } from '../../../hooks/useFetchAuth';


export const UsersManagementForm = () => {
  const activeContract = useSelector(state => state.contracts.activeContract);

  const { data: users, loading: loadingUsers, error: errorUsers } = useFetchAuth(`contracts/${activeContract?.id}/users`);
  const { data: users_contracts, loading: loadingContracts, error: errorContracts } = useFetchAuth(`contracts/${activeContract?.id}`);



  const columns = [
    { id: 'id', label: 'ID de Usuario' },
    { id: 'name', label: 'Nombre' },
    { id: 'email', label: 'Email' },
    { id: 'type', label: 'Tipo' },
    { id: 'permissions', label: 'Permisos' },
    { id: 'company', label: 'Compañía' },
  ];

  
  // Combinamos `users` y `users_contracts` para incluir type y permissions

  const combinedData = (users && users_contracts) ? users.map(user => {
    const userContract = users_contracts.find(contract => contract.user_id === user.id) || {};
    return {
      ...user,
      type: userContract.type || 'N/A',
      permissions: userContract.permissions || 'N/A',
    };
  }) : [];


  return (
    <Box>

      {(loadingUsers || loadingContracts) ? (
        <CircularProgress />
      ) : (errorUsers || errorContracts) ? (
        <Typography color="error">
          Error al cargar datos: {errorUsers?.message || errorContracts?.message}
        </Typography>
      ) : (
        <UsersList
          data={combinedData}
          columns={columns}
          usersContracts={users_contracts}
          //onSelectedUsersChange={onSelectedUsersChange}
          showSearch={false}
          showConfirmButton={false}
          allowClick={true}
        />
      )}
    </Box>
  );
};


import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Map } from './Map/Map'
import NodesLayer from './Map/NodesLayer/NodesLayer'
import GeoSearch from './Map/GeoSearch/GeoSearch'
import { LayerGroup, LayersControl } from 'react-leaflet'
import PanelsLayer from './Map/PanelsLayer/PanelsLayer'
import GatewaysLayer from './Map/GatewaysLayer/GatewaysLayer'
import { useDispatch, useSelector } from 'react-redux'
import { Filter } from '../common/Filter/Filter'
import DrawControl from './Map/DrawControl/DrawControl'
import GeoPosMap from './Map/GeoPosMap/GeoPosMap'
import SetMinZoomButton from './Map/SetMinZoomButton/SetMinZoomButton'
import { Backdrop, CircularProgress } from '@mui/material'
import {
  mapStartLoadingGatewaysLayer,
  mapStartLoadingNodesLayer,
  mapStartLoadingPanelsLayer
} from '../../reducers/map/mapReducer'

export const AdvancedMonitoringMapScreen = React.memo(() => {
  const type = useSelector(state => state.contracts.type)
  const onlyIp = useSelector(state => state.contracts.onlyIp)
  const isLoaded = useRef(false)
  const { loadingNodesLayer, loadingPanelsLayer, loadingGatewaysLayer } =
    useSelector(state => state.map)
  const [mapLoaded, setMapLoaded] = useState(false)
  const dispatch = useDispatch()


  const handleWhenReady = useCallback(() => {
    setTimeout(() => {
      setMapLoaded(true)
    }, 500)
  }, [setMapLoaded])

  useEffect(() => {
    if (!isLoaded.current) {
      isLoaded.current = true
      dispatch(mapStartLoadingNodesLayer())
      dispatch(mapStartLoadingPanelsLayer())
      dispatch(mapStartLoadingGatewaysLayer())
    }
  }, [isLoaded, dispatch])

  return (
    <>
      <Backdrop
        sx={() => ({ color: '#fff', zIndex: 100000 })}
        open={loadingNodesLayer || loadingPanelsLayer || loadingGatewaysLayer}
      >
        <CircularProgress color='primary' />
      </Backdrop>
      <Filter />
      <Map handleWhenReady={handleWhenReady}>
        <SetMinZoomButton />
        <GeoPosMap />
        <DrawControl />
        <GeoSearch />
        {mapLoaded && type && (
          <>
            <LayersControl.Overlay
              name='Nodes'
              checked={type.indexOf('P2P') !== -1}
            >
              <LayerGroup>
                <NodesLayer />
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay
              name='Panels'
              checked={type.indexOf('CM') !== -1}
            >
              <LayerGroup>
                <PanelsLayer />
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay
              name='Gateways'
              checked={type.indexOf('P2P') !== -1 && !onlyIp}
            >
              <LayerGroup>
                <GatewaysLayer />
              </LayerGroup>
            </LayersControl.Overlay>
          </>
        )}
      </Map>
    </>
  )
})

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GatewaysMarker from './GatewaysMarker/GatewaysMarker'
import MarkerClusterGroup from 'next-leaflet-cluster'
import { mapFinishLoadingGatewaysLayer, mapStartLoadingGatewaysLayer } from '../../../../reducers/map/mapReducer'
import validator from 'validator'
import { thereIsAFail } from '../../../../helpers/common'

const GatewaysLayer = React.memo(() => {
  const gateways = useSelector(state => state.gateways.gateways)
  const active = useSelector(state => state.gateways.active)
  const [renderedMarkersCount, setRenderedMarkersCount] = useState(0);
  const failure_window_in_hours_panels = useSelector(
    state => state.contracts.activeContract.failure_window_in_hours_panels
  )
  const dispatch = useDispatch()

  const filteredGateways = useMemo(
    () =>
      gateways
        .map(e => ({
          ...e,
          possition: [parseFloat(e.latitude), parseFloat(e.longitude)]
        }))
        .filter(e => {
          return validator.isLatLong(`(${e.possition[0]}, ${e.possition[1]})`)
        }),
    [gateways]
  )

  useEffect(() => {
    if (renderedMarkersCount >= filteredGateways.length) {
      dispatch(mapFinishLoadingGatewaysLayer());
    } else {
      dispatch(mapStartLoadingGatewaysLayer());
    }
  }, [renderedMarkersCount, filteredGateways.length, dispatch]);

  const handleMarkerRendered = useCallback(() => {
    setRenderedMarkersCount(prevCount => prevCount + 1);
  }, [setRenderedMarkersCount]);
  return (
    <MarkerClusterGroup
      disableClusteringAtZoom={17}
      chunkedLoading
      maxClusterRadius={50}
      spiderfyOnMaxZoom={true}
      showCoverageOnHover={true}
    >
      {filteredGateways &&
        filteredGateways.map(
          (gateway, index) =>
            gateway && (
              <GatewaysMarker
                key={index}
                possition={gateway.possition}
                data={gateway}
                active={
                  active.find(e => parseInt(e.id) === parseInt(gateway.id))
                    ? true
                    : false
                }
                fail={thereIsAFail(
                  gateway.status_date,
                  failure_window_in_hours_panels
                )}
                onRender={handleMarkerRendered}
              />
            )
        )}
    </MarkerClusterGroup>
  )
})

export default GatewaysLayer

import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-easybutton';

const SetMinZoomButton = () => {
    const map = useMap();

    useEffect(() => {
        
        const easyButton = L.easyButton({
            states: [{
                stateName: 'set-zoom', 
                icon: '<i class="fa-solid fa-earth-europe"></i>', 
                title: 'Zoom Mínimo', 
                onClick: function(btn, map) {
                    map.setZoom(7); 
                }
            }]
        });

        easyButton.addTo(map);

        
        return () => {
            easyButton.remove(); 
        };
    }, [map]);

    return null;
};

export default SetMinZoomButton;
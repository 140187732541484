import React, { useState, useEffect } from 'react'
import { IconButton, Paper, Tooltip } from '@mui/material'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
const FullScreenPaper = ({ children, ...rest }) => {
  const [isFullScreen, setIsFullScreen] = useState(false)

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen)
  }

  // Manejar la tecla ESC
  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape' && isFullScreen) {
        setIsFullScreen(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isFullScreen])

  return (
    <>
      <Paper
        variant='contained'
        square
        style={{
          marginLeft: 10,
          zIndex: isFullScreen ? 100 : 5,
          position: isFullScreen ? 'fixed' : 'relative',
          top: isFullScreen ? 0 : 'auto',
          left: isFullScreen ? 0 : 'auto',
          width: isFullScreen ? '100vw' : '100%',
          height: isFullScreen ? '100vh' : '300px',
          padding: isFullScreen ? '80px' : '1px',
          backgroundColor: '#F2F0EB',
          boxSizing: 'border-box',
          overflow: 'auto'
        }}
      >
        <Tooltip title={isFullScreen ? 'Salir de pantalla completa' : 'Pantalla completa'}>
          <IconButton
            style={{ float: 'right' }}
            variant='contained'
            color='secondary'
            onClick={toggleFullScreen}
          >
            {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </Tooltip>
        {children}
      </Paper>
    </>
  )
}

export default FullScreenPaper

import React, { useEffect, useMemo, useRef } from 'react'
import L from 'leaflet'
import theme from '../../../../../material-ui/theme'
import { Marker } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import { gatewaysActive } from '../../../../../reducers/gateways/gatewaysReducers'

const GatewaysMarker = React.memo(({ possition, data, active, fail, onRender }) => {
  const markerRef = useRef(null)
  const dispatch = useDispatch()
  const onlyActive = useSelector(state => state.map.filters.active)
  const onlyFails = useSelector(state => state.map.filters.fail)
  const currentIcon = useMemo(() => {
    if (!fail) {
      return active
        ? theme.devicesIcons.gateway.active.main
        : theme.devicesIcons.gateway.main
    } else {
      return active
      ? theme.devicesIcons.gateway.active.fail
      : theme.devicesIcons.gateway.fail
    }
  }, [active, fail])
  const icon = L.icon({
    iconUrl: currentIcon, // Puedes personalizar el icono si quieres
    iconSize: [active ? 28 : 28, active ? 32 : 32] // Tamaño del icono (ajústalo al tamaño real de tu icono)
  })
  const handleLeftClick = e => {
    dispatch(gatewaysActive([{ id: data.id }]))
    if (markerRef.current) {
      markerRef.current.closePopup()
    }
  }
  const handleRightClick = e => {
    if (markerRef.current) {
      markerRef.current.openPopup()
    }
  }
  useEffect(() => {
    if (onRender) {
      onRender() // Llama a la función cuando el marcador se ha renderizado
    }
  }, [onRender])

  if(onlyActive && !active){
    return null;
  }
  if (onlyFails && !fail) {
    return null
  }
  return (
    <>
        <Marker
          id={data.id}
          type="gateways"
          position={possition}
          icon={icon} // Icono opcional
          ref={markerRef}
          eventHandlers={{
            contextmenu: handleRightClick, // Detecta el clic derecho
            click: handleLeftClick
          }}
        />
    </>
  )
})

export default GatewaysMarker

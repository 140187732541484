

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, TextField, List, ListItem, ListItemIcon, ListItemText, CircularProgress, IconButton, ListItemSecondaryAction, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCity } from '@fortawesome/free-solid-svg-icons';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import { useForm } from '../../../../hooks/useForm';
import { startContractsActive } from '../../../../reducers/contracts/contractsActions';

export const ContractsList = () => {
  const [values, handleInputChange] = useForm({ search: '' });
  const { search } = values;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { contracts, activeContract } = useSelector((state) => state.contracts);

  //console.log(contracts, activeContract);

  const handleClick = (id) => {
    dispatch(startContractsActive(id));
  };

  const handleRouter = () => {
    navigate(`/contracts/settings`);
  };

  // Crear una copia de contracts para poder ordenar sin mutar el estado original
  const sortedContracts = contracts ? [...contracts]
    .sort((a, b) => (a.id === activeContract?.id ? -1 : b.id === activeContract?.id ? 1 : 0)) : [];

  return (
    <Grid container item xs={12} spacing={2} sx={{
      backgroundColor: 'background.paper',
      padding: 3,
      borderRadius: 1,
      boxShadow: 1,
      maxHeight: 500,
      overflow: 'auto'
    }}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h6">Contratos disponibles</Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          id="filled-search"
          label="Buscar"
          type="search"
          name="search"
          autoComplete="off"
          value={search}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <Box className="animate__animated animate__fadeIn">
          <List>
            {sortedContracts.length > 0 ? (
              sortedContracts
                .filter(({ name }) =>
                  search.length === 0
                    ? true
                    : name.toLowerCase().includes(search.toLowerCase())
                )
                .map(({ id, name }) => (
                  <ListItem
                    key={id}
                    button
                    selected={id === activeContract?.id}
                    onClick={() => handleClick(id)}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faCity} />
                    </ListItemIcon>
                    <ListItemText primary={name} />
                    {id === activeContract?.id && (
                      <ListItemSecondaryAction>
                        <IconButton edge="end" onClick={handleRouter}>
                          <SettingsIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))
            ) : (
              <CircularProgress color="primary" />
            )}
          </List>
        </Box>
      </Grid>
    </Grid>
  );
};


import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRef } from 'react'
import moment from 'moment/moment'
import {
  CardHeader,
  Card,
  CardContent,
  Button,
  Typography,
  Avatar,
  IconButton,
  Tooltip,
  InputLabel,
  Select,
  FormControl,
  Paper,
  TextField,
  InputAdornment,
  CardActions,
  ButtonGroup,
  CircularProgress,
  Divider,
  MenuItem,
  Box
} from '@mui/material'
import TerminalIcon from '@mui/icons-material/Terminal'
import CodeIcon from '@mui/icons-material/Code'
import GetAppIcon from '@mui/icons-material/GetApp'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import { useForm } from '../../../../hooks/useForm'
import {
  uiFinishLoading,
  uiStartLoading
} from '../../../../reducers/ui/uiReducer'
import { useSocketIo } from '../../../../hooks/useSocketIo'
import downloadJsonFile from '../../Download/DownloadJson/DownloadJson'
import { color } from '../../../../helpers/socket'
import { ChatHistoryList } from './ChatHistoryList/ChatHistoryList'

export const PanelsTerminalCard = ({ device, onClose }) => {
  const topicsReady = useRef(false)
  const paperRef = useRef(null)
  const [chat, setChat] = useState({
    flow: [],
    history: [],
    status: 'disconnected'
  })

  const activeContract = useSelector(state => state.contracts.activeContract)
  const loading = useSelector(state => state.ui.loading)

  const dispatch = useDispatch()

  const [values, handleInputChange] = useForm({
    port: 40001,
    data: ''
  })

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const handleCancel = async () => {
    await dispatch(uiFinishLoading())
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSend(isConnected ? 'send' : 'open')
    }
  }

  const { result, status, send, subscribe, history } = useSocketIo()

  // Enviar eventos al servidor Socket.io
  const handleSend = async command => {
    const payload = JSON.stringify({
      comando: command,
      ip: device.ip,
      port: values.port,
      data: values.data
    })
    if (command === 'send') {
      setChat(e => ({
        ...e,
        flow: [...e.flow, { msg: values.data, type: 'request' }],
        history: [...e.history, { msg: values.data, type: 'request' }]
      }))
      handleInputChange({ target: { value: '', name: 'data' } })
    }
    await dispatch(uiStartLoading())
    if (!topicsReady.current) {
      topicsReady.current = true
      await subscribe(
        [
          `/contracts/${activeContract.id}/panels/${device.ip}/terminal/status`,
          `/contracts/${activeContract.id}/panels/${device.ip}/terminal/response`
        ],
        () => {}
      )
    }
    await send('/terminal', payload)
  }

  const handleDownload = () => {
    if (chat.history.length) {
      const text = chat.history
        .map(e => (e.type === 'request' ? '\r\n$>' + e.msg : e.msg))
        .join('')

      downloadJsonFile(
        text,
        `history_${device.name}_${moment().format('YYYY-MM-DD HH:mm')}`,
        'txt',
        'text/json'
      )
    }
  }

  const handleClear = () => {
    setChat(e => ({
      ...e,
      flow: []
    }))
  }

  useEffect(() => {
    if (Object.keys(result).length) {
      if (result.topic.indexOf('status') !== -1) {
        setChat(e => ({ ...e, status: result.result.msg }))
      } else {
        setChat(e => ({
          ...e,
          flow: [...e.flow, { msg: result.result.msg, type: 'response' }],
          history: [...e.history, { msg: result.result.msg, type: 'response' }]
        }))
      }
      dispatch(uiFinishLoading())
    }
  }, [result, dispatch])

  useEffect(() => {
    const scrollToBottom = () => {
      if (paperRef.current) {
        paperRef.current.scrollTop = paperRef.current.scrollHeight
      }
    }
    scrollToBottom()
  }, [chat.flow])

  const isConnected = chat.status === 'ready' || chat.status === 'connected'

  return (
    <Card>
      <CardHeader
        title={`Terminal - ${device?.name}`}
        action={
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        }
        avatar={
          <Avatar>
            {' '}
            <TerminalIcon />{' '}
          </Avatar>
        }
      />
      <CardContent>
        <Box container spacing={3}>
          <Box item xs={12}>
            <Card elevation={0} className='animate__animated animate__fadeIn'>
              <CardHeader
                title={`${device?.name} [${device?.ip}]`}
                subheader={
                  <Typography
                    variant='caption'
                    style={{ color: color(chat.status) }}
                  >
                    {chat.status}
                  </Typography>
                }
                avatar={
                  <Avatar style={{ backgroundColor: color(status) }}>
                    <CodeIcon />
                  </Avatar>
                }
                action={
                  <FormControl>
                    <InputLabel id='demo-simple-select-label'>
                      Puerto
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      name='port'
                      value={values.port}
                      onChange={handleInputChange}
                    >
                      <MenuItem value={40001}>40001</MenuItem>
                    </Select>
                  </FormControl>
                }
              />
              <Divider />
              <CardContent>
                <Paper ref={paperRef} variant='outlined' elevation={0}>
                  <Box container>
                    <Box item xs={12} style={{ textAlign: 'right' }}>
                      <IconButton
                        onClick={handleDownload}
                        disabled={!(history && history.length)}
                      >
                        <Tooltip title='Descargar'>
                          <GetAppIcon />
                        </Tooltip>
                      </IconButton>
                    </Box>
                    <Box item xs={12}>
                      <ChatHistoryList flow={chat.flow} />
                    </Box>
                    <Box item xs={12}>
                      <TextField
                        fullWidth
                        name='data'
                        onChange={handleInputChange}
                        value={values.data}
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position='start'>
                                {'>'}
                              </InputAdornment>
                            )
                          }
                        }}
                        onKeyUp={handleKeyPress}
                      />

                      <Tooltip title='Limpiar'>
                        <IconButton onClick={handleClear} size='small'>
                          <ClearAllIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Paper>
              </CardContent>

              <Divider />

              <CardActions>
                <ButtonGroup
                  disableElevation
                  size='large'
                  variant='contained'
                  fullWidth
                >
                  <Button
                    color='default'
                    /*disabled={!isConnected}*/
                    onClick={() => handleSend('close')}
                  >
                    Desconectar
                  </Button>
                  {!loading ? (
                    <Button
                      color='primary'
                      onClick={() => handleSend(isConnected ? 'send' : 'open')}
                    >
                      {isConnected ? 'Enviar' : 'Conectar'}
                    </Button>
                  ) : (
                    <Button color='primary' onClick={handleCancel}>
                      <CircularProgress color='secondary' size={20} /> Cancelar
                    </Button>
                  )}
                </ButtonGroup>
              </CardActions>
            </Card>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

PanelsTerminalCard.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  onClose: PropTypes.func
}

import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Divider,
  TextareaAutosize,
  TextField
} from '@mui/material'
import React from 'react'
import ReactFileReader from 'react-file-reader'

export const NodesTelechargeNBIoTCommand = React.memo(
  ({ handleInputChange, result, values }) => {
    const uploadFileForm = (command = 'READ') => {
      handleInputChange({
        target: {
          name: 'command',
          type: 'text',
          value: command,
          checked: false
        }
      })
    }

    const handleCleanPayload = () => {
      handleInputChange({
        target: { name: 'payload', type: 'text', value: '', checked: false }
      })
    }

    const handleCopiResult = () => {
      if (typeof result.result === 'object') {
        handleInputChange({
          target: {
            name: 'payload',
            type: 'text',
            value: JSON.stringify(result.result, null, 3),
            checked: false
          }
        })
      } else {
        handleInputChange({
          target: {
            name: 'payload',
            type: 'text',
            value: result.result.toString(),
            checked: false
          }
        })
      }
    }

    const handleFiles = files => {
      try {
        const fileReader = new FileReader()
        fileReader.readAsText(files[0], 'UTF-8')
        fileReader.onload = async event => {
          const content = event.target.result
          if (typeof content === 'object') {
            handleInputChange({
              target: {
                name: 'payload',
                type: 'text',
                value: JSON.stringify(content, null, 3),
                checked: false
              }
            })
          } else {
            handleInputChange({
              target: {
                name: 'payload',
                type: 'text',
                value: content,
                checked: false
              }
            })
          }
        }
      } catch (err) {
        console.error(err)
      }
    }

    return (
      <>
        <Divider>COMMAND</Divider>
        <ButtonGroup
          fullWidth
          variant='contained'
          color='secondary'
          size='small'
        >
          <Button onClick={() => uploadFileForm('WRITE')}>WRITE File</Button>
          <Button onClick={() => uploadFileForm('DELETE')}>DELETE File</Button>
        </ButtonGroup>
        <TextField
          fullWidth
          disabled={true}
          id='command'
          label='command...'
          value={values.command}
          name='command'
          onChange={handleInputChange}
          style={{ marginTop: 10 }}
        />
        <Divider>PATH</Divider>
        <TextField
          fullWidth
          id='path'
          label='path...'
          value={values.path}
          name='path'
          onChange={handleInputChange}
          style={{ marginTop: 10 }}
        />
        <Divider>TOPIC MQTT</Divider>
        <TextField
          fullWidth
          required
          id='topic'
          label='topic required...'
          value={values.topic}
          name='topic'
          onChange={handleInputChange}
          style={{ marginTop: 10 }}
        />
        <Divider>PAYLOAD</Divider>
        <Card elevation={0}>
          <CardContent style={{ maxHeight: 400, overflow: 'auto' }}>
            <TextareaAutosize
              maxRows={1000}
              style={{
                width: '100%',
                backgroundColor: '#F2F0EB',
                padding: 1
              }}
              name='payload'
              value={values.payload}
              onChange={handleInputChange}
            />
          </CardContent>
          <CardActions>
            <ButtonGroup variant='contained' size='small'>
              <Button onClick={handleCleanPayload}>Limpiar</Button>
              <Button onClick={handleCopiResult}>Copiar Resultado</Button>
              <ReactFileReader
                handleFiles={handleFiles}
                fileTypes={['.json', '.txt', '.js']}
              >
                <Button>Subir datos</Button>
              </ReactFileReader>
            </ButtonGroup>
          </CardActions>
        </Card>
      </>
    )
  }
)





import { useEffect, useState, useRef, useMemo } from 'react';
import {
    useSelector,
    useDispatch
} from 'react-redux';
import { startLogout } from '../reducers/auth/authActions';
import { fetchConToken } from '../helpers/fetch';

export const useFetch = (path, contractId = undefined, join = '/') => {

    const activeContract = useSelector(state => state.contracts.activeContract);

    const dispatch = useDispatch();

    const isMounted = useRef(false);


    const [state, setState] = useState({
        data: null,
        loading: true,
        error: null
    })

    const url = useMemo(() => {
        let urlBuild;
        if (contractId === undefined || contractId === null) {
            urlBuild =  (path && `${path}`)
        } else {
            urlBuild= ((contractId || activeContract) && path) && `${contractId || activeContract?.id}${join}${path}`;
        }
        return urlBuild;

        
    }, [activeContract, path, contractId, join])

    useEffect(() => {
        if (url) {
            if (isMounted.current) {
                setState({
                    data: null,
                    loading: true,
                    error: null
                })
            }

            if (url === "NO PERMISSIONS") {
                setState({
                    data: [],
                    loading: false,
                    error: null
                })
            } else {
                //const single = contractId !== undefined && contractId !== null;
                fetchConToken(url)
                    .then(resp => {

                        if (resp.ok) {
                            return resp.json();
                        } else {
                            if (resp.status === 403) {
                                dispatch(startLogout());
                            }
                            throw new Error(resp.status);
                        }
                    })  

                    .then(data => {
                        if (isMounted.current) {
                            setState({
                                loading: false,
                                error: null,
                                data
                            });
                        }
                    })
                    .catch((error) => {
                        if (isMounted.current) {
                            setState({
                                data: null,
                                loading: false,
                                error: error.message
                            })
                        }
                    })
            }
        }
    }, [url, dispatch])


    useEffect(() => {
        if (isMounted.current === false){
            isMounted.current = true
        }
        return () => {
            isMounted.current = false;
        }
    }, [])

    return state;
}


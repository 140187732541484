

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CustomMenu } from '../../CustomMenu/CustomMenu';
import { PingButton } from '../../PingButton/PingButton';

export const GatewaysOptionsMenu = ({ id }) => {
  const gateways = useSelector(state => state.gateways.gateways);

  const gateway = gateways.find(e => e.id === id);

  //console.log("Gateways in Redux:", gateways);
  //console.log("ID passed to GatewaysOptionsMenu:", id);

  if (!gateway) {
    console.error(`No se encontró el gateway con id ${id}`);
    return <p>Error: Gateway no encontrado</p>;
  } else {
    return (
      <>
        <CustomMenu>
          <PingButton type='gateways' device={gateway} variant='menuItem' />
        </CustomMenu>
      </>
    );
  }
};

GatewaysOptionsMenu.propTypes = {
  id: PropTypes.number.isRequired,
};



export const typeToInteger = (permissions) => {
    switch(permissions){
        case 'P2P':
            return 0;
        case 'CM' :
            return 1;
        default:
            return 2;
    }
}
export const integerToType = (permissions) => {
    switch(permissions){
        case 0:
            return 'P2P';
        case 1 :
            return 'CM';
        default:
            return 'P2P/CM';
    }
}

export const typeMarks = [
    {
      value: 0,
      label: 'P2P',
    },
    {
      value: 1,
      label: 'CM',
    },
    {
      value: 2,
      label: 'P2P/CM',
    },
];
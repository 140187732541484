
import React from 'react'
import ReactDOM from 'react-dom/client'
import { App }  from './App.js'
import { store } from './store/store';
import { Provider } from 'react-redux';

//StrictMode crea una rerenderizacion habitual. En produccion no ocurriria.

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
)
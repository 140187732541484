

export const loadState = (key) => {
    try {

    const serializedData = localStorage.getItem(key)

    if (serializedData === null) {

        return undefined;

    }

    return JSON.parse(serializedData); 

    } catch (error) {

    return undefined;
    }
}




import React from 'react';
import { useSelector } from 'react-redux';
import { useFetchAuth } from '../../../../../hooks/useFetchAuth';
import { CircularProgress, Typography, Box, Divider } from '@mui/material';
import { UsersList } from '../../../../common/Tables/SelectedLists/UsersList/UsersList';

export const NewUsersContract = ({ onSelectedUsersChange }) => {
  const activeContract = useSelector(state => state.contracts.activeContract);

  const { data: users, loading: loadingUsers, error: errorUsers } = useFetchAuth(`contracts/${activeContract?.id}/users`);
  const { data: users_contracts, loading: loadingContracts, error: errorContracts } = useFetchAuth(`contracts/${activeContract?.id}`);

  const columns = [
    { id: 'select', label: '' },
    { id: 'id', label: 'ID de Usuario' },
    { id: 'name', label: 'Nombre' },
    { id: 'email', label: 'Email' },
    { id: 'company', label: 'Compañía' },
    { id: 'permissions', label: 'Permisos'}
  ];
  //console.log(users?.map(e => ({...e, permissions: users_contracts.find(item => item.user_id === e.id)?.permissions})), users, users_contracts)
  return (
    <Box>
      <Divider />
      <Typography variant="h5" component="h1" gutterBottom>
        Asignar Usuarios al Contrato
      </Typography>
      {(loadingUsers || loadingContracts) ? (
        <CircularProgress />
      ) : (errorUsers || errorContracts) ? (
        <Typography color="error">
          Error al cargar datos: {errorUsers?.message || errorContracts?.message}
        </Typography>
      ) : (
        <UsersList
          data={users.map(e => ({...e, permissions: users_contracts.find(item => item.user_id === e.id)?.permissions}))}
          columns={columns}
          usersContracts={users_contracts}
          onSelectedUsersChange={onSelectedUsersChange}
          showSearch={true}
          showConfirmButton={true}
        />
      )}
    </Box>
  );
};






import React from 'react';
import { Avatar, Card, CardContent, CardHeader, Container, Divider, IconButton, Tooltip } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Grid2 from '@mui/material/Grid2';
import { CustomTitle } from '../common/CustomTitle/CustomTitle';
import { UsersManagementForm } from './UsersManagementForm/UsersManagementForm';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';



export const UsersManagement = () => {

  const name = useSelector((state) => state.auth.user.name);
  const permissions = useSelector((state) => state.contracts.permissions);

  const navigate = useNavigate();

  const redirect = (route) => {
    navigate(route);
  };

  return (
    <Container maxWidth={false} sx={{ padding: 2 }}>
      <Grid2 container spacing={3} direction="column">
        {/* Título */}
        <Grid2 xs={12}>
          <CustomTitle advanced={false} title="Gestion de usuarios" />
        </Grid2>

        {/* Divider para añadir espacio entre el título y el formulario */}
        <Grid2 xs={12}>
          <Divider /> {/* my: margenes en el eje vertical (top/bottom) */}
        </Grid2>

        {/* Formulario */}
        <Grid2 xs={12}>
          <Card sx={{ width: '100%', marginBottom: 4 }}>
            <CardHeader
              avatar={
                <Avatar aria-label="user">
                  <PersonIcon />
                </Avatar>
              }
              title={name}
              subheader={permissions}
              action={
                <Tooltip title={'Nuevo usuario'}>
                  <IconButton onClick={() => redirect('/users-management/create')}>
                    <AddCircleIcon />
                  </IconButton>
                </Tooltip>
              }
            />
            <CardContent>
              <UsersManagementForm />
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </Container>
  );
};

import { useState, useCallback } from 'react';

export const useFormAuth = (initialState = {}) => {
    const [formState, setFormState] = useState(initialState);

    const handleInputChange = useCallback(({ target }) => {
        const { name, type, checked } = target;
        setFormState(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : getValue(target, prevState[name])
        }));
    }, []);

    const reset = useCallback((newState = initialState) => {
        setFormState(newState);
    }, [initialState]);

    return {
        formState,
        handleInputChange,
        reset,
    };
};

export const useForm = (initialState = {}) => {

    const [values, setValues] = useState(initialState);

    const reset = useCallback((newState = initialState) => {
        setValues(newState);
    }, [initialState]);

    const handleInputChange = useCallback(({ target }) => {
        setValues({
            ...values,
            [target.name]: getValue(target, values[target.name]),
        });
    }, [values]);

    return [values, handleInputChange, reset];
};

const getValue = (target, oldValue = null) => {
    switch (target.type) {
        case 'number':
            const valueToNumber = parseFloat(target.value);
            return isNaN(valueToNumber) ? target.value : valueToNumber;
        case 'checkbox':
            return target.checked;
        case 'select-multiple':
            if (oldValue) {
                const foundIndex = oldValue.findIndex(e => e === target.value);
                return foundIndex >= 0
                    ? oldValue.filter(e => e !== target.value) // Retorna un nuevo array filtrado
                    : [...oldValue, target.value]; // Retorna un nuevo array concatenado
            } else {
                return [target.value]; // Si `oldValue` no existe, crea un nuevo array
            }
        case 'radio':
            const valueToFloat = parseFloat(target.value);
            return isNaN(valueToFloat) ? target.value : valueToFloat;
        default:
            return target.value;
    }
};




import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Box } from '@mui/material';

export const Error = ({ error }) => {
  return (
    <Box sx={{ width: '100%', '& > * + *': { marginTop: 2 } }}>
      <Alert variant="outlined" severity="error">
        {error || 'Error desconocido'}
      </Alert>
    </Box>
  );
};

Error.propTypes = {
  error: PropTypes.string,
};



import { useEffect, useState, useRef } from 'react';
import {
    useSelector,
    useDispatch
} from 'react-redux';
import { startLogout } from '../reducers/auth/authActions';
import { fetchConToken } from '../helpers/fetch';

export const useFetchAuth = (path) => {

    const activeContract = useSelector(state => state.contracts.activeContract);

    const dispatch = useDispatch();

    const isMounted = useRef(false);




    const [state, setState] = useState({
        data: null,
        loading: true,
        error: null
    })


    const url = (activeContract && path) && path;

    useEffect(() => {
        if (url) {
            if (isMounted.current) {
                setState({
                    data: null,
                    loading: true,
                    error: null
                })
            }
            fetchConToken(url, null, 'GET', 'auth')
                .then(resp => {
                    if (resp.ok) {
                        return resp;
                    } else {
                        if (resp.status === 403) {
                            dispatch(startLogout());
                        }
                        throw new Error(resp.status);
                    }
                })
                .then(resp => resp.json())
                .then(data => {
                    if (isMounted.current) {
                        setState({
                            loading: false,
                            error: null,
                            data
                        });
                    }
                })
                .catch((error) => {
                    if (isMounted.current) {
                        setState({
                            data: null,
                            loading: false,
                            error: error.message
                        })
                    }
                })

        }
    }, [url, dispatch])

    useEffect(() => {
        if (isMounted.current === false) {
            isMounted.current = true
        }
        return () => {
            isMounted.current = false;
        }
    }, [])


    /*
    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])
    */

    return state;
}






import { createSlice } from '@reduxjs/toolkit';

const socketSlice = createSlice({
    name: 'socket',
    initialState: {
        result: {},
        history: [],
        status: "disconnected",
        topics: []
    },
    reducers: {
        socketConnect: (state) => {
            console.log("CONNECTADO")
            state.status = "connected";
        },
        socketDisconnect: (state) => {
            state.status = "disconnected";
            state.topics = []
            state.history = []
            state.result = {}
        },
        socketSetResult: (state, action) => {
            state.result = action.payload;
        },
        socketSetHistory: (state, action) => {
            state.history.push(action.payload);
        },
        socketSetStatus: (state, action) => {
            state.status = action.payload;
        },
        socketSetTopic: (state, action) => {
            state.topics = [
                ...action.payload
                  .filter(element => state.topics.indexOf(element) === -1),
                ...state.topics.filter(element => action.payload.indexOf(element) === -1)
              ];
        }
    }
});

export const { socketConnect,
    socketDisconnect,
    socketSetResult,
    socketSetHistory,
    socketSetStatus,
    socketSend,
    socketSetTopic } = socketSlice.actions;


export default socketSlice.reducer;




import React from 'react';
import PropTypes from 'prop-types';
import { Slider } from '@mui/material'; // Actualización de importación para MUI v5+

export const CustomSlider = ({ onChange, ...rest }) => {

    const handleOnChange = (e, value) => {
        onChange({
            target: {
                name: rest?.name,
                type: rest?.type,
                value
            }
        });
    };

    // Asegúrate de que el valor sea un número flotante antes de pasarlo
    const sliderValue = parseFloat(rest.value);

    return (
        <Slider
            {...rest}
            type="number"
            value={sliderValue}
            valueLabelDisplay="auto"
            onChange={handleOnChange}
        />
    );
};

CustomSlider.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default CustomSlider;

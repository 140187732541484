import { useEffect } from 'react';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css'; // Importa los estilos de geosearch
import { useMap } from 'react-leaflet';
import L from 'leaflet'; // Importa Leaflet para personalizar el icono

const GeoSearch = () => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    // Proveedor de OpenStreetMap
    const provider = new OpenStreetMapProvider();

    // Definir un icono predeterminado para los marcadores de búsqueda
    const invisibleIcon = L.icon({
      iconUrl: 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=', // Imagen transparente en base64
      iconSize: [1, 1], // El tamaño mínimo posible
      iconAnchor: [0, 0], // Sin anclaje
      popupAnchor: [0, 0] // Ubicación del popup
    });

    // Configuración del control de búsqueda
    const searchControl = new GeoSearchControl({
      provider,
      style: 'bar',
      autoClose: true,
      retainZoomLevel: false,
      animateZoom: true,
      keepResult: true,
      marker: {
        icon: invisibleIcon, // Asigna el icono personalizado al marcador
      }
    });

    // Añadir control de búsqueda al mapa
    map.addControl(searchControl);

    // Limpiar el control cuando el componente se desmonte
    return () => {
      if (map && searchControl) {
        map.removeControl(searchControl);
      }
    };
  }, [map]);

  return null;
};

export default GeoSearch;
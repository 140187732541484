

import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Box } from '@mui/material'; // Usando Box en lugar de div para estilos más fáciles
import { styled } from '@mui/system'; // Importa styled de @mui/system

// Definimos el componente estilizado usando styled
const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  '& > * + *': {
    marginTop: theme.spacing(2),
  },
}));

export const Warning = ({ warning }) => {

  return (
    <Root className="animate__animated animate__fadeIn animate__fast">
      <Alert variant="outlined" severity="warning">
        {warning ? warning : "Error desconocido"}
      </Alert>
    </Root>
  );
};

Warning.propTypes = {
  warning: PropTypes.string,
};




import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Avatar, Typography, Box } from '@mui/material';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import { selectActiveContract } from '../../../reducers/contracts/contractsSelectors';

export const CustomTitle = ({ title, icon, advanced }) => {
    // Obtengo el contrato activo del selector de redux
    const activeContracts = useSelector(selectActiveContract);
  
    // Obtenemos el primer contrato activo si existe
    const activeContract = activeContracts ? activeContracts : null;

  
    const modifyIcon = () => {
      const props = {
        sx: {
          width: 48,
          height: 48,
          marginRight: 2,
        },
      };
      return React.cloneElement(icon, props);
    };

    return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            animation: 'animate__animated animate__fadeIn animate__fast',
          }}
        >
          {icon ? (
            modifyIcon()
          ) : (
            <Avatar
              sx={{
                width: 48,
                height: 48,
                marginRight: 2,
              }}
            >
              <LocationCityOutlinedIcon />
            </Avatar>
          )}
    
          <Box>
            <Typography variant="h5">{title}</Typography>
    
            {/* Si hay un contrato activo y no es avanzado, mostramos su nombre */}
            {activeContract && !advanced && (
              <Typography variant="subtitle1">{activeContract.name}</Typography>
            )}
          </Box>
        </Box>
      );
    };
    
/*
CustomTitle.defaultProps = {
  title: '',
  advanced: false,
  icon: null,
};
*/
    

CustomTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  advanced: PropTypes.bool,
};
    

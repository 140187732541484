

import React from 'react';
import { Container, Divider } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import { CustomTitle } from '../../common/CustomTitle/CustomTitle';
import { NewContractForm } from './NewContractForm/NewContractForm';
 

export const NewContractScreen = () => {
  return (
    <Container maxWidth={false} sx={{ padding: 2 }}>
      <Grid2 container spacing={3} direction="column">
        {/* Título */}
        <Grid2 xs={12}>
          <CustomTitle advanced={true} title="Crear contrato" />
        </Grid2>

        {/* Divider para añadir espacio entre el título y el formulario */}
        <Grid2 xs={12}>
          <Divider  /> {/* my: margenes en el eje vertical (top/bottom) */}
        </Grid2>

        {/* Formulario */}
        <Grid2 xs={12}>
          <NewContractForm />
        </Grid2>
      </Grid2>
    </Container>
  );
};

import React from 'react'
import {
  LayersControl,
  MapContainer,
  TileLayer,
  WMSTileLayer
} from 'react-leaflet'
import validator from 'validator'
import { useSelector } from 'react-redux'
import 'leaflet/dist/leaflet.css'

export const Map = React.memo(({ children, handleWhenReady }) => {
  const activeContract = useSelector(state => state.contracts.activeContract)
  const possitionIsValid = activeContract
    ? validator.isLatLong(
        `(${parseFloat(activeContract.latitude)}, ${parseFloat(
          activeContract.longitude
        )})`
      )
    : false

  const position = possitionIsValid
    ? [
        parseFloat(activeContract.latitude),
        parseFloat(activeContract.longitude)
      ]
    : [process.env.REACT_APP_LATITUDE, process.env.REACT_APP_LONGITUDE]

  const MapPlaceholder = () => {
    return (
      <p>
        Map of London.{' '}
        <noscript>You need to enable JavaScript to see this map.</noscript>
      </p>
    )
  }
  const handler = () => {
    if (handleWhenReady) {
      handleWhenReady()
    }
  }

  return (
    <>
      {possitionIsValid && (
        <MapContainer
          center={position}
          zoom={12}
          minZoom={7}
          scrollWheelZoom={true}
          style={{ height: '100%', width: '100%', zIndex: 9 }}
          placeholder={<MapPlaceholder />}
          whenReady={handler}
        >
          <LayersControl position='topright'>
            <LayersControl.BaseLayer
              name='Planimetría (OSM)'
              checked={true}
              disabled
            >
              <TileLayer
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
                minZoom={0}
                maxZoom={18}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name='Ortofotos (PNOA)'>
              <TileLayer
                url='https://tms-pnoa-ma.idee.es/1.0.0/pnoa-ma/{z}/{x}/{-y}.jpeg'
                maxZoom={19}
                attribution='CC BY 4.0 scne.es'
              />
            </LayersControl.BaseLayer>
            {/*
            <LayersControl.BaseLayer name='ArcGIS'>
              <TileLayer
                url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                minZoom={0}
                maxZoom={20}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name='CartoDB'>
              <TileLayer
                url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
                minZoom={0}
                maxZoom={20}
              />
            </LayersControl.BaseLayer>*/}
            <LayersControl.BaseLayer name='Topografía (OTM)'>
              <TileLayer
                url='https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png'
                minZoom={0}
                maxZoom={20}
              />
            </LayersControl.BaseLayer>
            <LayersControl.Overlay name='Catastro'>
              <WMSTileLayer
                url='http://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx?'
                layers='Catastro'
                format='image/jpeg'
                transparent={true}
                version='1.1.1'
                opacity={0.7} // Ajusta la opacidad
                attribution='<a src="https://www.catastro.hacienda.gob.es/">Dirección general del catastro</a>'
              />
            </LayersControl.Overlay>
            {children}
          </LayersControl>
        </MapContainer>
      )}
    </>
  )
})

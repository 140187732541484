
import React, { useEffect } from 'react';
import { Form } from './Form/Form.js';
import { Box, Link, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import logo1 from '../../../assets/png/tellink1920.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectToken } from '../../../reducers/auth/authSelectors.js';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  height: 'auto',
  backgroundColor: theme.palette.backgroundAuth.default,
  padding: theme.spacing(2),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: 450,
  height: 'auto',
}));

const StyledImg = styled('img')(({ theme }) => ({
  width: '90%',
  maxWidth: '450px',
  height: 'auto',
  marginBottom: theme.spacing(2),
}));

const StyledLink = styled(Link)(({ theme }) => ({
  variant: 'body2',
  cursor: 'pointer',
  textAlign: 'center',
  display: 'block',
}));

//export const LoginScreen = () => {
const LoginScreen = () => {

  const navigate = useNavigate();
  const location = useLocation();
  //const dispatch = useDispatch();
  //const token = useSelector(state => state.auth.token);
  const token = useSelector(selectToken);


  /*
  useEffect(() => {
    if (token) {
      //Redirigimos al usuario después de la autenticación
      navigate('/', { replace: true });
    }
  }, [ token, navigate]);
  */
  useEffect(() => {
    if (token && location.pathname !== '/dashboard') {
      navigate('/dashboard', { replace: true });
    }
  }, [token, navigate, location ]);



  return (
    <StyledBox>
      <StyledImg
        alt='platform logo'
        src={logo1}
        title='Logo' />
      <Typography
        variant="h4"
        color="secondary"
        sx={{
          marginBottom: 5,
          fontSize: '3rem'
        }}>
        Management
      </Typography>
      <StyledPaper elevation={10}>
        <Typography component='h1' variant='h5'>
          Login
        </Typography>
        <Form />
        <StyledLink
          //className={classes.link}
          onClick={() => {
            navigate("/forgot-password")
          }}
        >
          {"¿Forgot your password?"}
        </StyledLink>
      </StyledPaper>
    </StyledBox>
  );
};


export default React.memo(LoginScreen);












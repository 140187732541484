import React, { useState } from 'react'
import { Drawer, Fab, Tooltip } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import FilterList from './FilterList/FilterList';

export const Filter = React.memo(() => {
  const [state, setstate] = useState(false)

  const handleOpen = () => {
    setstate(true)
  }

  const handleClose = () => {
    setstate(false)
  }

  return (
    <>
      <Drawer
        anchor='right'
        open={state}
        onClose={handleClose}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        <FilterList onSubmit={handleClose}/>
      </Drawer>

      <Tooltip title={'Filtros'} aria-label='add'>
        <Fab
          color='primary'
          size='small'
          aria-label='drawer'
          onClick={handleOpen}
          role='presentation'
          style={{
            position: 'fixed',
            bottom: 24,
            right: 194,
            zIndex: 10
          }}
        >
          <FilterListIcon />
        </Fab>
      </Tooltip>
    </>
  )
})
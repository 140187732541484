

import { createAsyncThunk } from '@reduxjs/toolkit';
import Swal from 'sweetalert2';
import { fetchConToken, handleError } from '../../helpers/fetch';
import { purpleTheme } from '../../material-ui/theme';
import theme from '../../material-ui/theme';
//import i18n from '../../i18n/i18n';

import {
  panelsLoad,
  panelsActive,
  panelsActiveItem,
  panelsRemoveActiveItem,
} from './panelsReducer';
import { loadStatusPanelText, testFlagPanel } from '../../helpers/panels/statusPanels';

const fields = [
  'id', 'name', 'ip', 'contract_id', 'firmware_version', 'serial_number_ucc',
  'last_download', 'address', 'active_alarms', 'number_of_circuits',
  'status_flag', 'on_off_schedule_send', 'alarm_send', 'identification',
  'u6me2_profile1_id', 'u6me2_profile2_id', 'u6me2_profile3_id',
  'u6me2_profile4_id', 'coded_mains_profile_id', 'latitude', 'longitude', 'last_download'
];

export const startPanelsLoad = createAsyncThunk(
  'panels/startPanelsLoad',
  async (contractId, { dispatch }) => {
    if (contractId) {
      try {
        const response = await fetchConToken(`contract/${contractId}/panels?fields=${fields.join(',')}`);
        if (!response.ok) 
          throw response.status;
        const panels = await response.json();
        dispatch(panelsLoad(panels));
      } catch (error) {
        handleError(error, dispatch);
      }
    }
  }
);

/*
export const startPanelsLoad = createAsyncThunk(
  'panels/startPanelsLoad',
  async (_, { dispatch, getState }) => {
    const { contracts: { active } } = getState();

    if (active && active.id) {
      try {
        const response = await fetchConToken(`/${active.id}/panels?fields=${fields.join(',')}`);
        if (!response.ok) throw response.status;
        const panels = await response.json();
        dispatch(panelsLoad(panels));
      } catch (error) {
        handleError(error, dispatch);
      }
    }
  }
);
*/

export const startPanelsCreate = createAsyncThunk(
  'panels/startPanelsCreate',
  async (data, { dispatch, getState }) => {
    const { contracts: { activeContract } } = getState();

    if (activeContract && activeContract.id && typeof data === 'object') {
      Swal.fire({
        title: `${'Loading'}...`,
        text: 'Please wait',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => Swal.showLoading(),
      });

      try {
        const response = await fetchConToken(`contract/${activeContract.id}/panels`, data, 'POST');
        if (!response.ok && response.status !== 405) throw response.status;
        //const result = await response.json();
        if (response.status === 405) {
          Swal.fire({
            titleText: 'Error',
            text: loadStatusPanelText(1, data),
            icon: 'warning',
            confirmButtonColor: purpleTheme.palette.primary.main,
          });
        } else {
          dispatch(startPanelsLoad());
          Swal.fire({
            titleText: 'Saved',
            text: ("¡{{name}} update!", { name: data?.name || '' }),
            icon: 'success',
            confirmButtonColor: purpleTheme.palette.primary.main,
          });
        }
      } catch (error) {
        handleError(error, dispatch);
      } finally {
        Swal.close();
      }
    }
  }
);

export const startPanelsUpdate = createAsyncThunk(
  'panels/startPanelsUpdate',
  async (data, { dispatch, getState }) => {
    const { contracts: { activeContract }, panels: { panels } } = getState();

    if (activeContract && activeContract.id && typeof data === 'object' && data.id) {
      Swal.fire({
        title: `${'Loading'}...`,
        text: 'Please wait',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => Swal.showLoading(),
      });

      try {
        const response = await fetchConToken(`contract/${activeContract.id}/panels/${data.id}`, data, 'PATCH');
        if (!response.ok && response.status !== 405) throw response.status;
        //const result = await response.json();
        const panel = panels.find(e => e.id === data.id);
        if (response.status === 405) {
          Swal.fire({
            titleText: 'Error',
            text: loadStatusPanelText(1, panel),
            icon: 'warning',
            confirmButtonColor: purpleTheme.palette.primary.main,
          });
        } else {
          dispatch(startPanelsLoad());
          Swal.fire({
            titleText: 'Saved',
            text: ("¡{{name}} update!", { name: panels.find(e => e.id === data.id)?.name || '' }),
            icon: 'success',
            confirmButtonColor: purpleTheme.palette.primary.main,
          });
        }
      } catch (error) {
        handleError(error, dispatch);
      } finally {
        Swal.close();
      }
    }
  }
);




export const startPanelsActiveItem = createAsyncThunk(
  'panels/startPanelsActiveItem',
  async (id, { dispatch, getState }) => {
    const { contracts: { activeContract } } = getState();

    if (activeContract && activeContract.id && id) {
      try {
        const response = await fetchConToken(`contract/${activeContract.id}/panels/${id}`);
        if (!response.ok) throw response.status;
        const panel = await response.json();
        dispatch(panelsActiveItem(panel.length ? panel[0] : null));
      } catch (error) {
        handleError(error, dispatch);
      }
    }
  }
);

export const startPanelsDelete = createAsyncThunk(
  'panels/startPanelsDelete',
  async (id, { dispatch, getState }) => {
    const { contracts: { activeContract }, panels: { panels } } = getState();

    if (activeContract && activeContract.id && id) {
      Swal.fire({
        title: `${'Loading'}...`,
        text: 'Please wait',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => Swal.showLoading(),
      });

      try {
        const response = await fetchConToken(`contract/${activeContract.id}/panels/${id}`, null, 'DELETE');
        if (!response.ok) throw response.status;
        await response.json();

        Swal.fire({
          titleText: 'Saved',
          text: ("¡{{name}} update!", { name: panels.find(e => parseInt(e.id) === parseInt(id))?.name }),
          icon: 'success',
          confirmButtonColor: purpleTheme.palette.primary.main,
        });

        dispatch(panelsRemoveActiveItem());
        dispatch(panelsActive([{ id: parseInt(id) }]));
        dispatch(panelsLoad(panels.filter(e => parseInt(e.id) !== parseInt(id))));
      } catch (error) {
        handleError(error, dispatch);
      } finally {
        Swal.close();
      }
    }
  }
);

export const startPanelsPing = createAsyncThunk(
  'panels/startPanelsPing',
  async (id, { dispatch, getState }) => {
    const { contracts: { activeContract }, panels: { panels } } = getState();
    if (activeContract && activeContract.id && id) {
      const name = panels.find(e => e.id === id)?.name || "";

      Swal.fire({
        title: `${'Loading'}...`,
        text: 'Please wait',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => Swal.showLoading(),
      });

      try {
        const response = await fetchConToken(`contract/${activeContract.id}/panels/${id}/ping`, {}, 'POST');
        if (!response.ok) throw response.status;
        const action = await response.json();
        Swal.fire({
          titleText: name,
          text: (action?.msg),
          icon: action?.msg === 'is alive' ? 'success' : 'error',
          confirmButtonColor: purpleTheme.palette.primary.main,
        });
      } catch (error) {
        handleError(error, dispatch);
      } finally {
        Swal.close();
      }
    }
  }
);


//LO DEJO COMENTADO YA QUE ACTUALMENTE EN LA API EN PRODUCCION TAMBIEN ESTA COMENTADO

export const startPanelsMultipleUpdate = createAsyncThunk(
  'panels/startPanelsMultipleUpdate',
  async ({ devices, status_flag = 1 }, { dispatch, getState }) => {
    const { contracts: { activeContract } } = getState();

    if (activeContract && activeContract.id && Array.isArray(devices) && devices.length > 0) {
      Swal.fire({
        title: `${'Loading'}...`,
        text: 'Please wait',
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => Swal.showLoading(),
      });

      try {
        const requests = [
          fetchConToken(`contract/${activeContract.id}/panels?fields=${fields.join(',')}`),
          fetchConToken(`contract/${activeContract.id}/panels?status_flag=${status_flag}`, devices, 'PATCH'),
        ];
        const [panelsResponse, updateResponse] = await Promise.all(requests);

        if (!panelsResponse.ok && panelsResponse.status !== 405) throw panelsResponse.status;
        if (!updateResponse.ok && updateResponse.status !== 405) throw updateResponse.status;

        const [panels, results] = await Promise.all([
          panelsResponse.json(),
          updateResponse.json()
        ]);

        const panelsNoOk = panels.filter(e => !testFlagPanel(e.status_flag));
        const devicesNoOk = panelsNoOk.filter(e => devices.find(item => item.id === e.id));

        dispatch(startPanelsLoad());

        const text = `Han sido updates ${results?.changedRows} de ${devices.length} equipos seleccionados.
          ${devicesNoOk.length > 0 ? 
              `Los siguientes Centros de mando no pueden ser updates por el momento, inténtelo más tarde: ${devicesNoOk.map(e => loadStatusPanelText(e.status_flag, e)).join(', ')}` 
              : ''}`;


        Swal.fire({
          titleText: devicesNoOk.length > 0 ? 'Atención' : 'Saved',
          text,
          icon: devicesNoOk.length > 0 ? 'warning' : 'success',
          confirmButtonColor: theme.palette.primary.main,
        });
      } catch (error) {
        handleError(error, dispatch);
      } finally {
        Swal.close();
      }
    }
  }
);




import React, { useState, useMemo } from 'react';
import { Grid, IconButton, TextField, Tooltip, Checkbox, Button } from '@mui/material';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { CustomTable } from '../../../../common/Tables/CustomTable/CustomTable';
import Swal from 'sweetalert2';

export const UsersList = ({ data, columns, usersContracts, onSelectedUsersChange, showSearch = true, showConfirmButton = true, allowClick = false }) => {
  const [search, setSearch] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const navigate = useNavigate();

  const handleSelect = (user, isChecked) => {
    const userContractInfo = usersContracts.find(contract => contract.user_id === user.id);
    setSelectedUsers((prevSelected) =>
      isChecked
        ? [...prevSelected, userContractInfo]
        : prevSelected.filter(selected => selected.user_id !== user.id)
    );
  };

  const handleUserClick = (userId) => {
    if (allowClick) {
      navigate(`/users-management/user-profile/${userId}`);
    }
  };

  const handleUnSelectAll = () => {
    setSelectedUsers([]);
  };

  const handleConfirmSelection = () => {
    console.log(selectedUsers)
    onSelectedUsersChange && onSelectedUsersChange(selectedUsers);
    Swal.fire({
      icon: 'success',
      title: 'Usuarios Asignados',
      text: 'Se ha agregado los usuarios seleccionados al contrato. Haz click en "ENVIAR" para terminar el proceso.',
    });
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredData = useMemo(() => {
    return data.filter((row) =>
      search.length === 0
        ? true
        : columns.some((column) => {
            const value = row[column.id];
            return value && String(value).toLowerCase().includes(search.toLowerCase());
          })
    );
  }, [data, search, columns]);

  const allSelected = useMemo(() => {
    return (
      filteredData.length > 0 &&
      filteredData.every((user) => selectedUsers.some(selected => selected.user_id === user.id))
    );
  }, [filteredData, selectedUsers]);

  return (
    <Grid container className="animate__animated animate__fadeIn animate__fast">
      {showSearch && (
        <>
          <Grid item sm={5}>
            <TextField
              id="search"
              label="Buscar"
              type="search"
              name="search"
              autoComplete="off"
              value={search}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item sm={2}>
            <Tooltip title="Eliminar todo">
              <IconButton edge="end" aria-label="delete" onClick={handleUnSelectAll}>
                <DeleteSweepIcon style={{ fontSize: 30 }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </>
      )}

      <Grid item style={{ width: '100%', marginTop: '16px' }}>
        <CustomTable
          rows={filteredData.map((user) => ({
            ...user,
            name: (
              <span
                style={{ cursor: allowClick ? 'pointer' : 'default', color: allowClick ? 'blue' : 'inherit' }}
                onClick={() => handleUserClick(user.id)}
              >
                {user.name}
              </span>
            ),
            select: (
              <Checkbox
                checked={selectedUsers.some(selected => selected.user_id === user.id)}
                onChange={(event) => handleSelect(user, event.target.checked)}
              />
            ),
          }))}
          columns={columns}
        />
      </Grid>

      {showConfirmButton && (
        <Grid item style={{ marginTop: '16px' }}>
          <Button variant="contained" color="primary" onClick={handleConfirmSelection}>
            Confirmar selección de usuarios
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

UsersList.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  usersContracts: PropTypes.array.isRequired,
  onSelectedUsersChange: PropTypes.func,
  showSearch: PropTypes.bool,
  showConfirmButton: PropTypes.bool,
  allowClick: PropTypes.bool,
};




import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filters: {
    active: false,
    alarm: false,
    new: false,
    fail: false,
    current_gateway_number: -1,
    firmwareIcons: [],
    gatewayIcons: []
  },
  loadingNodesLayer: false,
  loadingPanelsLayer: false,
  loadingGatewaysLayer: false
}

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    mapSetFilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload
      }
    },
    mapClearFilters: state => {
      state.filters = { ...initialState.filters }
    },
    mapStartLoadingNodesLayer: state => {
      state.loadingNodesLayer = true
    },
    mapStartLoadingPanelsLayer: state => {
      state.loadingPanelsLayer = true
    },
    mapStartLoadingGatewaysLayer: state => {
      state.loadingGatewaysLayer = true
    },
    mapFinishLoadingNodesLayer: state => {
      state.loadingNodesLayer = false
    },
    mapFinishLoadingPanelsLayer: state => {
      state.loadingPanelsLayer = false
    },
    mapFinishLoadingGatewaysLayer: state => {
      state.loadingGatewaysLayer = false
    }
  }
})

// Exportamos las acciones
export const {
  mapSetFilters,
  mapClearFilters,
  mapStartLoadingNodesLayer,
  mapStartLoadingPanelsLayer,
  mapStartLoadingGatewaysLayer,
  mapFinishLoadingNodesLayer,
  mapFinishLoadingPanelsLayer,
  mapFinishLoadingGatewaysLayer
} = mapSlice.actions

// Exportamos el reducer
export default mapSlice.reducer



import { createSlice } from '@reduxjs/toolkit';

// Reducer con createSlice
const usersSlice = createSlice({
  name: 'users',
  initialState: {
    active: null,
  },
  reducers: {
    setActiveUser: (state, action) => {
      state.active = action.payload;
    },
    removeActiveUser: (state) => {
      state.active = null;
    },
    updateUser: (state, action) => {
      state.active = action.payload;
    }
  },
  //Cuando startUsersDelete termine, borrara el usuario activo de redux de manera automatica
  extraReducers: (builder) => {
    builder
      .addCase('users/delete/fulfilled', (state) => {
        state.active = null;
      });
  }
});


export const { setActiveUser, removeActiveUser, updateUser } = usersSlice.actions;


export default usersSlice.reducer;

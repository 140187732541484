
import React from 'react';
import { Grid, Container } from '@mui/material';

import { CustomTitle } from '../common/CustomTitle/CustomTitle';
import { DeviceTable } from './DeviceTable/DeviceTable';

export const Dashboard = () => {
  return (
    <Container maxWidth={false} sx={{ padding: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CustomTitle advanced={true} title="Panel de control avanzado" />
        </Grid>

        <Grid item xs={12} sm={12} lg={12}>
          <DeviceTable/>
        </Grid>
      </Grid>
    </Container>
  );
};














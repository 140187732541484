


import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Grid,
  Container,
  Avatar,
  Card,
  CardActions,
  useTheme,
  CardContent,
  Divider,
  Button,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import SendIcon from '@mui/icons-material/Send';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
//import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { startPanelsLoad } from '../../reducers/panels/panelsActions';
import { startPanelsMultipleUpdate } from '../../reducers/panels/panelsActions';
import { GoBack } from '../common/GoBack/GoBack';
import { CustomTitle } from '../common/CustomTitle/CustomTitle';
import { PanelsList } from '../common/Tables/SelectedLists/PanelsList/PanelsList';
import { Warning } from '../common/Alerts/Warning/Warning';
//import { DevicesTablesDrawer } from '../common/Tables/DevicesTablesDrawer/DevicesTablesDrawer';
import DisplayResponseVers from './DisplayResponseVers/DisplayResponseVers';
import DisplayResponseTelecs from './DisplayResponseTelecs/DisplayResponseTelecs';
import DisplayResponseStatus from './DisplayResponseStatus/DisplayResponseStatus';
import CustomSelectFileOrFiles from './CustomSelectFileOrFiles/CustomSelectFileOrFiles';
import { useForm } from '../../hooks/useForm';
import { useSocketIo } from '../../hooks/useSocketIo';
import { uiStartLoading, uiFinishLoading } from '../../reducers/ui/uiReducer';
import { startUsersOperation } from '../../reducers/users/usersActions';
import { color } from '../../helpers/socket';
import UploadIcon from '@mui/icons-material/Upload';

export const PanelsTelechargeScreen = () => {
  const topicsReady = useRef(false);
  const theme = useTheme();
  const [values, handleInputChange] = useForm({
    port: 40001,
    telec_file: [],
    config_file: []
  });

  const dispatch = useDispatch();
  const activeContract = useSelector(state => state.contracts.activeContract);
  const panels = useSelector(state => state.panels.panels);  
  const activeItem  = useSelector(state => state.panels.active);  
  const loading = useSelector(state => state.ui.loading);
  const { result, status, send, subscribe } = useSocketIo();

  const activePanelsData = useMemo(() => {
    return panels.filter(panel =>
      activeItem?.some(active => active && active.id === panel.id)
    );
  }, [panels, activeItem]);

  const handleFileTelechargeInputChange = useCallback(
    data => handleInputChange({ target: { value: data, name: 'telec_file' } }),
    [handleInputChange]
  );

  const handleFileConfigInputChange = useCallback(
    data => handleInputChange({ target: { value: data, name: 'config_file' } }),
    [handleInputChange]
  );

  const handleClear = async () => {
    await send('/vers', JSON.stringify({ comando: 'clear' }));
  };

  const handleSave = () => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Los cambios realizados tardarán un tiempo variable en llevarse a cabo',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then(async res => {
      if (res.isConfirmed) {
        const devices = activePanelsData;  

        const payload = {
          destino: devices.map(e => e.ip),
          port: devices.map(() => values.port)
        };
        if (values.telec_file.length) {
          try {
            const jsonData = JSON.parse(values.telec_file);
            payload.telec_file = JSON.stringify(jsonData.telec_file);
            payload.version = jsonData.version;
            payload.comando = 'send_telec';
          } catch (err) {
            Swal.fire('Error', 'El archivo de telecarga no es válido', 'error');
            return;
          }
        } else {
          payload.comando = 'send_cfg';
        }
        if (values.config_file.length) {
          payload.config_file = values.config_file;
        }

        await dispatch(uiStartLoading());
        await dispatch(
          startUsersOperation({ text: `Send Telecharge: ${payload.destino}` })
        );
        await send('/telecs', JSON.stringify(payload));
      }
    });
  };

  const handleScheduleSend = () => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Los cambios realizados tardarán un tiempo variable en llevarse a cabo',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then(async res => {
      await dispatch(
        startPanelsMultipleUpdate(
          activePanelsData.map(e => {
            const panel = panels.find(item => item.id === e.id);
            if (panel) {
              const coded_mains_profile_send = (activeContract.coded_mains === 1 && panel.coded_mains_profile_id > 0) ? 1 : 0;
              const u6me2_profile1_status = (activeContract.u6me2 === 1 && panel.u6me2_profile1_id > 0) ? 1 : 0;
              const u6me2_profile2_status = (activeContract.u6me2 === 1 && panel.u6me2_profile2_id > 0) ? 1 : 0;
              const u6me2_profile3_status = (activeContract.u6me2 === 1 && panel.u6me2_profile3_id > 0) ? 1 : 0;
              const u6me2_profile4_status = (activeContract.u6me2 === 1 && panel.u6me2_profile4_id > 0) ? 1 : 0;
              return {
                id: e.id,
                on_off_schedule_send: 1,
                coded_mains_profile_send,
                u6me2_profile1_status,
                u6me2_profile2_status,
                u6me2_profile3_status,
                u6me2_profile4_status,
                alarm_send: 1
              };
            } else {
              return null;
            }
          }).filter(Boolean)
        )
      );
    });
  };

  const handleUpdateVersions = () => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Los cambios realizados tardarán un tiempo variable en llevarse a cabo',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then(async res => {
      if (res.isConfirmed) {
        const devices = activePanelsData;

        const payload = {
          comando: 'get_version',
          destino: devices.map(e => e.ip),
          port: devices.map(() => values.port),
          telec_file: []
        };
        await dispatch(uiStartLoading());
        await dispatch(
          startUsersOperation({ text: `Send "Get Version": ${payload.destino}` })
        );

        await send('/vers', JSON.stringify(payload));
      }
    });
  };

  useEffect(() => {
    if (!topicsReady.current && status === "connected") {
      topicsReady.current = true;
      subscribe(
        [
          `/contracts/${activeContract.id}/panels/telecs`,
          `/contracts/${activeContract.id}/panels/status`
        ],
        () => {
          dispatch(uiStartLoading());
          setTimeout(async () => {
            await send('/telecs', 'only_listen', async () => {});
            await send('/vers', JSON.stringify({ comando: 'status' }));
            dispatch(uiFinishLoading());
          }, 2000);
        }
      );
    }
  }, [activeContract, subscribe, status, send, dispatch]);

  useEffect(() => {
    if (Object.keys(result).length) {
      if (result.topic.indexOf('telecs') !== -1) {
        dispatch(uiStartLoading());
      } else {
        dispatch(startPanelsLoad());
        dispatch(uiFinishLoading());
      }
    }
  }, [result, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(uiFinishLoading());
    };
  }, [dispatch]);

  const panelColumns = [
    { id: 'select', label: '' },
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'Nombre' },
    { id: 'firmware_version', label: 'Versión de Firmware' },
    { id: 'ip', label: 'Dirección IP' },
    { id: 'deleteAndSummary', label: '' }
  ];

  return (
    <>
      {!panels ? (
        <CircularProgress color="primary" />
      ) : (
    <Container maxWidth={false} sx={{ padding: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ display: 'flex' }}>
          <GoBack />
          <CustomTitle
            title={`Telecarga ~ Centros de mando`}
            icon={
              <Avatar>
                <UploadIcon />
              </Avatar>
            }
          />
        </Grid>

        <Grid container spacing={3} justifyContent="center">
          <Grid item>
            <Card>
              <CardHeader
                avatar={
                  <Avatar style={{ backgroundColor: color(status) }}>CM</Avatar>
                }
                title="Telecarga"
                subheader="Actualiza el firmware de los equipos"
                action={
                  <FormControl variant="outlined" style={{ width: '160px', height: '25px' }}>
                    <InputLabel>Puerto</InputLabel>
                    <Select
                      name="port"
                      value={values.port}
                      onChange={handleInputChange}
                    >
                      <MenuItem value={40001}>40001</MenuItem>
                    </Select>
                  </FormControl>
                }
              />
              <Divider />
              {activeItem.length === 0 ? (  
                <CardContent>
                  <Warning warning="No hay equipos seleccionados" />
                </CardContent>
              ) : (
                <>
                  <CardContent>
                    <PanelsList data={activePanelsData} columns={panelColumns} />  
                  </CardContent>
                  <CardActions>
                    <CustomSelectFileOrFiles
                      multiple={false}
                      accept=".json"
                      title="Selecciona archivos de Telecarga"
                      onChange={handleFileTelechargeInputChange}
                      disabled={values.telec_file.length > 0}
                      id="inputTelec"
                    />
                  </CardActions>
                  <CardActions>
                    <CustomSelectFileOrFiles
                      multiple={false}
                      accept=".json"
                      title="Selecciona un archivo de Configuración"
                      onChange={handleFileConfigInputChange}
                      disabled={values.config_file.length > 0}
                      id="inputConfig"
                    />
                  </CardActions>
                </>
              )}
              <Divider />
              <CardContent>
                <DisplayResponseStatus result={result} />
                <DisplayResponseTelecs result={result} />
                <DisplayResponseVers result={result} />
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={
                    loading ||
                    (values.telec_file.length === 0 &&
                      values.config_file.length === 0)
                  }
                  fullWidth
                  startIcon={<SendIcon />}
                >
                  Enviar{' '}
                  {loading && <CircularProgress color="secondary" size={20} />}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleScheduleSend}
                  disabled={!activeItem.length || loading}  
                  fullWidth
                  startIcon={<SendIcon />}
                >
                  Enviar perfiles de actuación{' '}
                  {loading && <CircularProgress color="secondary" size={20} />}
                </Button>
              </CardActions>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateVersions}
                  disabled={!activeItem.length || loading}  
                  fullWidth
                  startIcon={<SystemUpdateAltIcon />}
                >
                  Refrescar versiones{' '}
                  {loading && <CircularProgress color="secondary" size={20} />}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClear}
                  disabled={!loading}
                  startIcon={<CancelScheduleSendIcon />}
                >
                  Cancelar
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )}
  </>)
};





import React, { useEffect, useMemo, useRef } from 'react'
import L from 'leaflet'
import theme from '../../../../../material-ui/theme'
import { Marker, Popup } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux'
import { panelsActive } from '../../../../../reducers/panels/panelsReducer'
import DynamicForm from '../../../../common/DynamicForm/DynamicForm'
import { Card, CardContent, CardHeader } from '@mui/material'
import { PanelsOptionsMenu } from '../../../../common/Options/PanelsOptionsMenu/PanelsOptionsMenu'

const PanelsMarker = React.memo(({ possition, data, active, fail, alarm, onRender }) => {
  const markerRef = useRef(null)
  const dispatch = useDispatch()
  const onlyActive = useSelector(state => state.map.filters.active)
  const onlyFails = useSelector(state => state.map.filters.fail)
  const onlyAlarms = useSelector(state => state.map.filters.alarm)

  const currentIcon = useMemo(() => {
    if (!fail) {
      return active
        ? theme.devicesIcons.panel.active.main
        : theme.devicesIcons.panel.main
    } else {
      return active
      ? theme.devicesIcons.panel.active.fail
      : theme.devicesIcons.panel.fail
    }
  }, [active, fail])
  const icon = L.icon({
    iconUrl: currentIcon, // Puedes personalizar el icono si quieres
    iconSize: [active ? 28 : 28, active ? 32 : 32] // Tamaño del icono (ajústalo al tamaño real de tu icono)
  })
  const handleLeftClick = e => {
    dispatch(panelsActive([{ id: data.id }]))
    if (markerRef.current) {
      markerRef.current.closePopup()
    }
  }
  const handleRightClick = e => {
    if (markerRef.current) {
      markerRef.current.openPopup()
    }
  }
  useEffect(() => {
    if (onRender) {
      onRender() // Llama a la función cuando el marcador se ha renderizado
    }
  }, [onRender])

  if(onlyActive && !active){
    return null;
  }
  if (onlyFails && !fail) {
    return null
  }
  if (onlyAlarms && !alarm) {
    return null
  }
  return (
    <>
        <Marker
          id={data.id}
          type="panels"
          position={possition}
          icon={icon} // Icono opcional
          ref={markerRef}
          eventHandlers={{
            contextmenu: handleRightClick, // Detecta el clic derecho
            click: handleLeftClick
          }}
        >
          <Popup maxWidth='none' autoClose={false} closeOnClick={false}>
            <Card elevation={0}>
              <CardHeader
                title={data.name}
                action={<PanelsOptionsMenu id={data.id} />}
              />
              <CardContent>
                <DynamicForm
                  fields={[
                    {
                      name: 'name',
                      label: 'Nombre del equipo',
                      type: 'text',
                      value: data.name
                    },
                    {
                      name: 'ip',
                      label: 'I.P.',
                      type: 'text',
                      value: data.ip
                    },
                    {
                      name: 'last_download',
                      label: 'Última conexión',
                      type: 'datetime',
                      value: data.last_download,
                      readOnly: true
                    },
                    {
                      name: 'status_flag',
                      label: 'Flag modificado',
                      type: 'checkbox',
                      value: data.status_flag,
                      readOnly: true
                    },
                    {
                      name: 'serial_number',
                      label: 'Número de serie',
                      type: 'number',
                      value: data.serial_number
                    },
                    {
                      name: 'identification',
                      label: 'Identificación',
                      type: 'number',
                      value: data.identification
                    },
                    {
                      name: 'firmware_version',
                      label: 'Versión de Firmware',
                      type: 'number',
                      value: data.firmware_version,
                      readOnly: true
                    },
                    {
                      name: 'active_alarms',
                      label: 'Alarma',
                      type: 'checkbox',
                      value: data.active_alarms,
                      readOnly: true
                    },
                    {
                      name: 'on_off_schedule_send',
                      label: 'Enviar perfil de regulación',
                      type: 'checkbox',
                      value: data.on_off_schedule_send
                    },
                    {
                      name: 'alarm_send',
                      label: 'Enviar perfil de alarmas',
                      type: 'checkbox',
                      value: data.alarm_send
                    }
                  ]}
                />
              </CardContent>
            </Card>
          </Popup>
        </Marker>
    </>
  )
})

export default PanelsMarker

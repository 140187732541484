
import React, { useEffect } from 'react';
import { Button, FormHelperText, TextField } from '@mui/material';
import { useFormAuth } from '../../../../hooks/useForm';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { uiRemoveError, uiSetError } from '../../../../reducers/ui/uiReducer';
import { startResetPassword } from '../../../../reducers/auth/authActions';
import { styled } from '@mui/system';

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%', 
  marginTop: theme.spacing(1)
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2)
}));

export const Form = () => {
  const dispatch = useDispatch();
  const msgError = useSelector(state => state.ui.msgError);

  const { formState, handleInputChange } = useFormAuth({
    email: ''
  });

  const { email } = formState;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validator.isEmail(email)) {
      dispatch(startResetPassword(email));
    } else {
      dispatch(uiSetError("{{field}} es incorrecto", { field: "email" }));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(uiRemoveError());
    };
  }, [dispatch]);

  return (
    <div className='animate__animated animate__fadeIn'>
      <StyledForm noValidate onSubmit={handleSubmit}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='email'
          label={'Correo electrónico'}
          name='email'
          value={email}
          autoComplete='email'
          autoFocus
          onChange={handleInputChange}
        />

        <FormHelperText
          variant='outlined'
          error={true}
          disabled={!msgError}
        >
          {msgError}
        </FormHelperText>

        <StyledButton
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
        >
          {'Enviar'}
        </StyledButton>
      </StyledForm>
    </div>
  );
};


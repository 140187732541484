


import { Button } from '@mui/material';
import React from 'react';
import TaskIcon from '@mui/icons-material/Task';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

const CustomSelectFileOrFiles = ({
  multiple,
  accept,
  title,
  disabled,
  onChange,
  id
}) => {
  
  const handleFileMultipleInputChange = async event => {
    async function readFile (file) {
      return await new Promise(resolve => {
        let fileReader = new FileReader();
        fileReader.onload = e => resolve(fileReader.result);
        fileReader.readAsText(file);
      });
    }

    const files = [];
    for (let i = 0; i < event.target.files.length; i++) {
      const content = await readFile(event.target.files[i]);
      try {
        const data = JSON.parse(content);
        const number = parseInt(data.filename.split('_')[1].split('.')[0], 10);
        files.push({
          ...data,
          number
        });
      } catch (err) {
        console.error(err);
      }
    }

    const newData = files
      .sort(({ number: a }, { number: b }) => a - b)
      .map(e => {
        return {
          valor: e.firm[0].valor,
          datos: e.firm.map(info => info.datos.substr(2)).join('')
        };
      });

    newData[newData.length - 1].valor =
      '6F' + newData[newData.length - 1].valor.substr(2);
    onChange(newData);
    event.target.value = null;
  };

  const handleFileInputChange = async event => {
    async function readFile (file) {
      return await new Promise(resolve => {
        let fileReader = new FileReader();
        fileReader.onload = e => resolve(fileReader.result);
        fileReader.readAsText(file);
      });
    }

    const content = await readFile(event.target.files[0]);
    onChange(content);
    event.target.value = null;
  };

  return (
    <>
      <Button variant="outlined" color="secondary" disabled={disabled}>
        <label htmlFor={id} id="labelarchivo" style={{ cursor: 'pointer' }}>
          {title}
        </label>
        <input
          id={id}
          type="file"
          accept={accept}
          onChange={
            multiple ? handleFileMultipleInputChange : handleFileInputChange
          }
          multiple={multiple}
          style={{ display: 'none' }}
        />
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => onChange([])}
        disabled={!disabled}
      >
        Borrar
      </Button>
      {disabled ? <TaskIcon /> : <NoteAddIcon />}
    </>
  );
};

export default CustomSelectFileOrFiles;




import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { Switch, Grid, IconButton, TextField, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import { 
  panelsActiveItem, 
  panelsRemoveActiveItem, 
  panelsRemoveActiveItemById, 
  panelsRemoveActive
} from '../../../../../reducers/panels/panelsReducer';
import { CustomTable } from '../../../../common/Tables/CustomTable/CustomTable';
import { PanelsOptionsMenu } from '../../../Options/PanelsOptionsMenu/PanelsOptionsMenu';

export const PanelsList = ({ data, columns }) => {
  const [search, setSearch] = useState('');
  const activeItem = useSelector(state => state.panels.active);
  const dispatch = useDispatch();

  const handleSelect = (panelId, isChecked) => {
    if (isChecked) {
      dispatch(panelsActiveItem({ id: panelId }));
    } else {
      dispatch(panelsRemoveActiveItemById(panelId));
    }
  };

  const handleUnSelectAll = () => {
    dispatch(panelsRemoveActive([]));  
    dispatch(panelsRemoveActiveItem([]));  
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      filteredData.forEach(panel => {
        if (!activeItem.some(item => item.id === panel.id)) {
          dispatch(panelsActiveItem({ id: panel.id }));
        }
      });
    } else {
      dispatch(panelsRemoveActiveItem());
    }
  };


  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };


  const filteredData = useMemo(() => {
    return data.filter((row) =>
      search.length === 0
        ? true
        : columns.some((column) => {
            const value = row[column.id];
            return value && String(value).toLowerCase().includes(search.toLowerCase());
          })
    );
  }, [data, search, columns]);

  const allSelected = useMemo(() => {
    return filteredData.length > 0 && filteredData.every(panel => activeItem.some(item => item.id === panel.id));
  }, [filteredData, activeItem]);

  return (
    <Grid container className='animate__animated animate__fadeIn animate__fast'>
      <Grid item sm={5}>
        <TextField
          id="search"
          label="Buscar"
          type="search"
          name="search"
          autoComplete="off"
          value={search}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>

      <Grid item sm={2}>
        <Tooltip title="Eliminar todo">
          <IconButton edge="end" aria-label="delete" onClick={handleUnSelectAll}>
            <DeleteSweepIcon style={{ fontSize: 30 }} />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item sm={5} style={{ display: 'flex', alignItems: 'center' }}>
        <FormControlLabel
          control={
            <Switch
              checked={allSelected}
              onChange={(event) => handleSelectAll(event.target.checked)}
              color="primary"
            />
          }
          label="Marcar/Desmarcar todos"
        />
      </Grid>

      <Grid item style={{ width: '100%', marginTop: '16px' }}>
        <CustomTable
          rows={filteredData.map((panel) => ({
            ...panel,
            select: (
              <Checkbox
                checked={activeItem.some(item => item.id === panel.id)}
                onChange={(event) => handleSelect(panel.id, event.target.checked)}
              />
            ),
            deleteAndSummary: <PanelsOptionsMenu id={panel.id} />,
          }))}
          columns={columns}
        />
      </Grid>
    </Grid>
  );
};

PanelsList.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};











import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const ResponsiveContainer = ({ children }) => {
 

  const Content = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    height: window.innerHeight,
    width: window.innerWidth,
    paddingTop: theme.spacing(8),
  }));

  return (<Content>{children}</Content>);
};




import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  Container,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Divider,
  Collapse,
  IconButton,
  Tooltip,
  useTheme,
  ListItemSecondaryAction,
  Button,
  FormHelperText,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCity } from '@fortawesome/free-solid-svg-icons';
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TodayIcon from '@mui/icons-material/Today';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import Swal from 'sweetalert2';
import PeopleIcon from '@mui/icons-material/People';
//import ApartmentIcon from '@mui/icons-material/Apartment';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

//import { UserHistory } from './UserHistory/UserHistory';
import { CustomTitle } from '../../common/CustomTitle/CustomTitle';
import { Warning } from '../../common/Alerts/Warning/Warning';
import { GoBack } from '../../common/GoBack/GoBack';
import { startUsersActive, startUsersDelete } from '../../../reducers/users/usersActions';
import { removeActiveUser } from '../../../reducers/users/usersReducer';
import { uiRemoveError } from '../../../reducers/ui/uiReducer';
import { startResetPassword } from '../../../reducers/users/usersActions';
import { UserHistory } from './UserHistory/UserHistory';
//import { startSubcontractsLoad } from '../../../reducers/subcontracts/subcontractsActions';
//import { subcontractsLogoutCleaning } from '../../../reducers/subcontracts/subcontractsReducer';

export const UserProfileScreen = () => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();
  const refId = useRef(null);

  const msgError = useSelector((state) => state.ui.msgError);
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.users.active);
  const activeContract = useSelector((state) => state.contracts.activeContract);
  const maintenance = useSelector((state) => state.contracts.maintenance);
  //const subcontracts = useSelector((state) => state.subcontracts.subcontracts);


  const currentUserId = auth?.user?.id;

  const thisUserExist = user && currentUserId;

  useEffect(() => {
    if (activeContract && activeContract.id && refId.current !== id) {

      dispatch(startUsersActive(id));
      refId.current = id;
    }
  }, [id,activeContract,  dispatch]);

  useEffect(() => {
    //dispatch(startSubcontractsLoad());
    return () => {
      //dispatch(subcontractsLogoutCleaning());
      dispatch(removeActiveUser());
      dispatch(uiRemoveError());
    };
  }, [dispatch]);

  const handleClick = () => setOpen(!open);

  const handleDeleteUser = () => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: theme.palette.primary.main,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((res) => {
      if (id && res.isConfirmed) {
        dispatch(startUsersDelete(id));
        navigate(-1); // Navega hacia atrás
      }
    });
  };

  const getRoles = () => {
    return ['boss', 'person_in_charge', 'operator']
      .map((e) => (user.contract[e] ? e : null))
      .filter((e) => e)
      .join(', ');
  };

  const handleResetPassword = () => {
    dispatch(startResetPassword(user.email));
  };

/*
  const getSubcontracts = (list_id) => {
    if (parseInt(list_id) === -1 || !list_id) {
      return 'Todo';
    }
    return list_id
      .split(',')
      .filter(Boolean)
      .map((e) => subcontracts.find((item) => item.id === parseInt(e))?.name)
      .join(', ');
  };
  */

  return (
    <Container maxWidth={false} sx={{ padding: 2 }}>
      <Grid container spacing={1} className='animate__animated animate__fadeIn animate__fast'>
        <Grid item xs={12} sx={{ display: 'flex' }}>
          <GoBack />
          <CustomTitle
            title='Perfil de usuario'
            icon={
              <Avatar>
                <AccountCircleTwoToneIcon />
              </Avatar>
            }
          />
        </Grid>

        {thisUserExist ? (
          <Grid item xs={12}>
            <Card sx={{ marginBottom: 4 }}>
              <CardHeader
                avatar={
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                }
                title={user.name}
                subheader={user.company}
                action={
                  <>
                    <Tooltip title='Editar perfil'>
                      <IconButton
                        onClick={() => navigate(`/users-management/user-profile/${id}/update`)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>

                    {currentUserId !== parseInt(id) && (
                      <Tooltip title='Eliminar'>
                        <IconButton onClick={handleDeleteUser}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                }
              />

              <CardContent>
                <List
                  component='nav'
                  aria-labelledby='nested-list-subheader'
                  subheader={<ListSubheader>Información general</ListSubheader>}
                  sx={{ width: '100%', bgcolor: 'background.paper' }}
                >
                  <ListItem>
                    <ListItemIcon>
                      <TodayIcon />
                    </ListItemIcon>
                    <ListItemText primary={moment(user.date).format('dd D MMM Y HH:mm')} />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <MailIcon />
                    </ListItemIcon>
                    <ListItemText primary={user.email} />
                  </ListItem>
                </List>
                <Divider />
                <List
                  component='nav'
                  subheader={
                    <ListSubheader>
                      Información de {user.name} en el contrato {activeContract?.name}
                    </ListSubheader>
                  }
                >
                  <ListItem>
                    <ListItemIcon>
                      <VpnKeyIcon />
                    </ListItemIcon>
                    <ListItemText primary={user.contract.permissions} />
                    <ListItemSecondaryAction>
                      <Button onClick={handleResetPassword}>
                        Forzar cambio de contraseña
                      </Button>
                      <FormHelperText variant='outlined' error={Boolean(msgError)}>
                        {msgError}
                      </FormHelperText>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faCity} />
                    </ListItemIcon>
                    <ListItemText primary={`Tipo: ${user.contract.type}`} />
                  </ListItem>
                  
                  {maintenance && (
                    <ListItem>
                      <ListItemIcon>
                        <PeopleIcon />
                      </ListItemIcon>
                      <ListItemText primary={`Roles: ${getRoles()}`} />
                    </ListItem>
                  )}
                  <ListItem button onClick={handleClick}>
                    <ListItemIcon>
                      <DataUsageIcon />
                    </ListItemIcon>
                    <ListItemText primary='Historial' />
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={open} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                      <ListItem sx={{ paddingLeft: 4 }}>
                        <UserHistory id={parseInt(id)} />
                      </ListItem>
                    </List>
                  </Collapse>
                </List>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <Warning warning='Este usuario no existe' />
        )}
      </Grid>
    </Container>
  );
};


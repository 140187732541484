import React from 'react';
import { Avatar, Container, Grid, Card, CardContent, CardHeader, ListItem, ListItemText, ListItemAvatar, /*useTheme,*/ Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import SettingsApplicationsTwoToneIcon from '@mui/icons-material/SettingsApplicationsTwoTone';
import { faTasks, faCogs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PublishIcon from '@mui/icons-material/Publish';
import { useNavigate } from 'react-router-dom';
import { List } from '@mui/material';
import { styled } from '@mui/system';
import theme from '../../material-ui/theme'

import { CustomTitle } from '../common/CustomTitle/CustomTitle';


const Root = styled(Container)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const NormalAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginLeft: theme.spacing(2),
    borderRadius: 0,
}));

export const DeviceManagementScreen = () => {

    const { type } = useSelector(state => state.contracts);
    const { gateways } = useSelector(state => state.gateways);

    const navigate = useNavigate(); 

    const redirect = (url) => {
        navigate(url);
    };

    const enableNodes = type && type.indexOf('P2P') !== -1;

    return (
        <Root maxWidth={false}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CustomTitle title="Gestión de dispositivos" icon={<Avatar> <FontAwesomeIcon icon={faTasks} /> </Avatar>} />
                </Grid>

                <Grid container justifyContent="center" spacing={3}>
                    <Grid item>
                        <Card>
                            <CardHeader
                                title="Gestión de dispositivos"
                                subheader="¿Qué dispositivos desea gestionar?"
                                avatar={<Avatar> <SettingsApplicationsTwoToneIcon /> </Avatar>}
                            />

                            <CardContent>
                                <List>
                                    {enableNodes && (
                                        <>
                                            {  (
                                                <ListItem Button onClick={() => redirect("/device-management/gateways")}>
                                                    <ListItemAvatar>
                                                        <NormalAvatar src={theme.devicesIcons.gateway.avatar} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary="Gateways" secondary={`${gateways.length}`} />
                                                </ListItem>
                                            )}
                                            <Divider />
                                            <ListItem Button onClick={() => redirect("/device-management/nodes/storage-settings")}>
                                                <ListItemAvatar>
                                                    <FontAwesomeIcon className="normal" icon={faCogs} />
                                                </ListItemAvatar>
                                                <ListItemText primary="Configuración de almacenamiento" secondary="Luminarias" />
                                            </ListItem>
                                            <Divider />
                                            <ListItem Button onClick={() => redirect("/device-management/nodes/fast-upload")}>
                                                <ListItemAvatar>
                                                    <PublishIcon className="normal" />
                                                </ListItemAvatar>
                                                <ListItemText primary="Carga rápida ICC y Nº de Serie" secondary="Luminarias" />
                                            </ListItem>
                                        </>
                                    )}
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Root>
    );
};



import React from 'react';
import { List, ListItemIcon, ListItemText, ListItemButton, Tooltip } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import DvrIcon from '@mui/icons-material/Dvr';
import SettingsApplicationsTwoToneIcon from '@mui/icons-material/SettingsApplicationsTwoTone';
import PlaylistAddTwoToneIcon from '@mui/icons-material/PlaylistAddTwoTone';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UploadIcon from '@mui/icons-material/Upload';
import HubIcon from '@mui/icons-material/Hub';

const ListItemNested = styled(ListItemButton)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  maxHeight: 48, //Para que el tamaño vertical de cada icono sea el mismo
  justifyContent: 'initial',
}));

export const AdvancedList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleRoute = (route) => {
    navigate(route);
  };

  return (
    <List component="nav">
      <List component="div" disablePadding >
      <Tooltip title="Dashboard" placement="right">
        <ListItemNested
          onClick={() => handleRoute('/dashboard')}
          selected={pathname.includes('/dashboard')}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemNested>
        </Tooltip>
        <Tooltip title="Monitorización avanzada" placement="right">
        <ListItemNested
          onClick={() => handleRoute('/monitoring')}
          selected={pathname.includes('/monitoring')}
        >
          <ListItemIcon>
            <DvrIcon />
          </ListItemIcon>
          <ListItemText primary="Monitorización avanzada" />
        </ListItemNested>
        </Tooltip>
        <Tooltip title="Gestión de dispositivos" placement="right">
        <ListItemNested
          onClick={() => handleRoute('/device-management')}
          selected={pathname.includes('/device-management')}
        >
          <ListItemIcon>
            <SettingsApplicationsTwoToneIcon />
          </ListItemIcon>
          <ListItemText primary="Gestión de dispositivos" />
        </ListItemNested>
</Tooltip>
        <Tooltip title="Dar de alta GW" placement="right">
          <ListItemNested
            onClick={() => handleRoute('/registrations')}
            selected={pathname.includes('/registrations')}
          >
            <ListItemIcon>
              <PlaylistAddTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="Dar de alta GW" />
          </ListItemNested>
        </Tooltip>
        <Tooltip title="Telecarga CM" placement="right">
          <ListItemNested
            onClick={() => handleRoute('/telecharge/panels')}
            selected={pathname.includes('/telecharge/panels')}
          >
            <ListItemIcon>
              <UploadIcon />
            </ListItemIcon>
            <ListItemText primary="Telecarga CM" />
          </ListItemNested>
        </Tooltip>
        <Tooltip title="Telecarga Nodos NBIoT" placement="right">
          <ListItemNested
            onClick={() => handleRoute('/telecharge/nodes/nbiot-settings')}
            selected={pathname.includes('/telecharge/nodes/nbiot-settings')}
          >
            <ListItemIcon>
              <UploadIcon />
            </ListItemIcon>
            <ListItemText primary="Telecarga Nodos NBIoT" />
          </ListItemNested>
        </Tooltip>
        <Tooltip title="Node-RED UI" placement="right">
          <ListItemNested
            onClick={() => handleRoute('/telecharge/nodes/node-red')}
            selected={pathname.includes('/telecharge/nodes/node-red')}
          >
            <ListItemIcon>
              <HubIcon />
            </ListItemIcon>
            <ListItemText primary="Node-RED UI" />
          </ListItemNested>
        </Tooltip>
      </List>
    </List>
  );
};



import { useEffect, useRef } from 'react'
import { useMap } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet-draw'
import 'leaflet/dist/leaflet.css'

import 'leaflet-draw/dist/leaflet.draw.css'
import { nodesActive } from '../../../../reducers/nodes/nodesReducer'
import { panelsActive } from '../../../../reducers/panels/panelsReducer'
import { gatewaysActive } from '../../../../reducers/gateways/gatewaysReducers'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'
import pointInPolygon from 'point-in-polygon'

const DrawControl = () => {
  const map = useMap()
  const nodes = useSelector(state => state.nodes.nodes)
  const panels = useSelector(state => state.panels.panels)
  const gateways = useSelector(state => state.gateways.gateways)
  const loading = useSelector(state => state.ui.loading)

  const drawnItems = useRef(new L.FeatureGroup()).current
  const loaded = useRef(false)
  const dispatch = useDispatch()
  useEffect(() => {
    if (loaded.current === false && loading === false) {
      map.addLayer(drawnItems)

      const drawControl = new L.Control.Draw({
        edit: {
          featureGroup: drawnItems
        },
        draw: {
          rectangle: false, // Habilitar solo la herramienta de selección rectangular
          polyline: false,
          polygon: true,
          circle: false,
          marker: false,
          circlemarker: false
        }
      })
      map.addControl(drawControl)

      // Evento al dibujar
      map.on(L.Draw.Event.CREATED, function (e) {
        const { layerType, layer } = e
        if (layerType === 'polygon') {
          const polygon = layer
          const latLngs = polygon.getLatLngs()[0].map(latLng => [latLng.lng, latLng.lat]) 

          const nodesInBounds = []
          const panelsInBounds = []
          const gatewaysInBounds = []
          nodes.forEach(node => {
            const lat = parseFloat(node.latitude)
            const lng = parseFloat(node.longitude)
            if (validator.isLatLong(`(${lat}, ${lng})`)) {
              const latLng = [lng, lat]
              if (pointInPolygon(latLng, latLngs)) {
                nodesInBounds.push({ id: node.id })
              }
            }
          })
          panels.forEach(panel => {
            const lat = parseFloat(panel.latitude)
            const lng = parseFloat(panel.longitude)
            if (validator.isLatLong(`(${lat}, ${lng})`)) {
              const latLng = [lng, lat] 
              if (pointInPolygon(latLng, latLngs)) {
                panelsInBounds.push({ id: panel.id })
              }
            }
          })
          gateways.forEach(gateway => {
            const lat = parseFloat(gateway.latitude)
            const lng = parseFloat(gateway.longitude)
            if (validator.isLatLong(`(${lat}, ${lng})`)) {
              const latLng = [lng, lat] 
              if (pointInPolygon(latLng, latLngs)) {
                gatewaysInBounds.push({ id: gateway.id })
              }
            }
          })

          // Almacenar markers seleccionados
          if (nodesInBounds.length) {
            //console.log(nodesInBounds)
            dispatch(nodesActive(nodesInBounds))
          }
          if (panelsInBounds.length) {
            dispatch(panelsActive(panelsInBounds))
          }
          if (gatewaysInBounds.length) {
            dispatch(gatewaysActive(gatewaysInBounds))
          }
        }

      })
      loaded.current = true
    }
  }, [map, drawnItems, loaded, dispatch, gateways, nodes, panels, loading])

  return null
}

export default DrawControl

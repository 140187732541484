


// Salva lo calculado en las tablas orto(mm, dd) y ocaso(mm, dd)
function calcular_too(dd, mm, aa, zona_horaria, GPSLat, GPSLong) // As Integer, mm As Integer, aa As Integer, zona_horaria As Integer, GPSLat As Double, GPSLong As Double)
{
    var valorOrtoOcaso = {
        dia: 0,
        mes: 0,
        ortoHora: 0,
        ortoMin: 0,
        ocasoHora: 0,
        ocasoMin: 0,
    
    }
    var dfGG // As Double  // SUNUP.BAS G
    var dfLL // As Double // SUNUP.BAS L
    var dfSS // As Double // SUNUP.BAS S
    var dfUU // As Double // SUNUP.BAS U
    var dfVV // As Double // SUNUP.BAS V
    var dfWW // As Double // SUNUP.BAS W
    var dfA5 // As Double
    var dfD5 // As Double
    var dfAA1, dfAA2  // As Double, dfAA2  As Double // SUNUP.BAS A(2)
    var dfDD1, dfDD2  // As Double, dfDD2 As Double  // SUNUP.BAS D(2)
    var dfZenith, dfSinLat, dfCosLat // As Double
    var dfA0, dfD0, dfDA, dfDD, dfK1  // As Double
    var dfHourRise, dfHourSet // As Double, dfHourSet  As Double
    var dfMinRise, dfMinSet //  As Double, dfMinSet  As Double
    var dfV0, dfV1, dfV2  // As Double
    var dfC0, dfP, dfD1, dfA2  // As Double
    var dfD2, dfL0, dfL2, dfH0, dfH2, dfH1  // As Double

    var dfJ //  As Double
    var t1 // As Long //u16 t1;
    var t2 // As Long // INT32 t2;
    var dfJ3  // As Double
    var /*iSign,*/ dfA  // As Double
    var iJulian // As Long  // INT32 iJulian;
    var dfT  // As Double
    var dfTT, dfT0  // As Double
    var dfTimeZone  //As Double
    var tempA // As Double // SUNUP.BAS A
    var tempB // As Double // SUNUP.BAS B
    var tempD // As Double // SUNUP.BAS D
    var tempE // As Double // SUNUP.BAS E

    //    double dfLat = 40.38;
    //    double dfLon = -3.29;

    //    double dfLat = 40.255285;
    //    double dfLon = -3.4321074;

    //double dfLat = atof((char *)conf.posic_gps.latitud);     // 40.2795052;
    //double dfLon = atof((char *)conf.posic_gps.longitud);   // -3.3692796;
    var dfLat // As Double
    var dfLon // As Double
    dfLat = GPSLat
    dfLon = GPSLong

    var too_ano // As Integer
    var too_dia // As Integer
    var too_mes  // As Integer

    too_ano = aa
    too_dia = dd
    too_mes = mm
    //    u8 zona_horaria = 0;

    var iCount // As Integer

    //var bSunriseToday // As Boolean  // flag for sunrise on this date
    //var bSunsetToday // As Boolean  // flag for sunset on this date
    //var bSunUpAllDay // As Boolean  // flag for sun up all day
    //var bSunDownAllDay // As Boolean  // flag for sun down all day
    var bSunrise // As Boolean  // sunrise during hour checked
    var bSunset // As Boolean  // sunset during hour checked

    //bSunriseToday = false // flag for sunrise on this date
    //bSunsetToday = false // flag for sunset on this date
    //bSunUpAllDay = false // flag for sun up all day
    //bSunDownAllDay = false // flag for sun down all day
    bSunrise = false // sunrise during hour checked
    bSunset = false // sunset during hour checked
    dfAA1 = 0
    dfAA2 = 0
    dfDD1 = 0
    dfDD2 = 0
    //dfJ = - Math.floor(7.0 * (Math.floor((iMonth + 9.0) / 12.0) + iYear) / 4.0) + Math.floor(iMonth * 275.0 / 9.0) + iDay + 1721027.0 + iYear * 367.0;
    dfJ = -Math.floor(7.0 * (Math.floor((too_mes + 9.0) / 12.0) + too_ano) / 4.0)
        + Math.floor(too_mes * 275.0 / 9.0) + too_dia + 1721027.0 + too_ano * 367.0;
    // dfJ = -Int(7# * (Int((too_mes + 9#) / 12#) + too_ano) / 4#) + Int(too_mes * 275# / 9#) + too_dia + 1721027# + too_ano * 367#
    if (too_mes < 9) {
        //iSign = -1
        dfA = 9 - too_mes
        t1 = too_ano - (dfA / 7)
    }
    else {
        //iSign = 1
        dfA = too_mes - 9
        t1 = too_ano + (dfA / 7)
    }
    t2 = (t1 / 100) + 1
    t2 = t2 * 3
    t2 = Math.floor(t2 / 4)
    dfJ3 = t2
    dfJ = dfJ - dfJ3 + 2
    // dfJ3 = -Math.floor((Math.floor(Math.floor(iYear + (double) iSign * Math.floor(dfA / 7.0)) / 100.0) + 1.0) * 0.75);
    // correct dfJ as in SUNUP.BAS 1240 and 1250 for G = 1
    // dfJ = dfJ + dfJ3 + 2.0;
    // iJulian = CCur(dfJ) - 1
    iJulian = Math.floor(dfJ) - 1;
    dfT = iJulian - 2451545 + 0.5
    dfTT = (dfT / 36525.0) + 1.0    // centuries since 1900

    dfTimeZone = (zona_horaria) / (-24.0)
    dfLon = dfLon / 360.0
    // Calculate local sidereal time at 0h in zone time
    // SUNUP.BAS 410 through 460
    // dfT0 = (dfT * 8640184.813 / 36525.0 + 24110.5 + dfTimeZone * 86636.6 + dfLon * 86400.0) / 86400.0;
    // dfT0 = dfT0 - Math.floor(dfT0); // NOTE: SUNUP.BAS uses INT()
    // dfT0 = dfT0 * 2.0 * Math.PI;
    dfT0 = (dfT * 8640184.813 / 36525.0 + 24110.5 + dfTimeZone * 86636.6 + dfLon * 86400.0) / 86400.0
    dfT0 = dfT0 - parseInt(dfT0) // NOTE: SUNUP.BAS uses INT()
    dfT0 = dfT0 * 2.0 * Math.PI
    dfT = dfT + dfTimeZone
    //****************************
    // for (iCount = 0; iCount <= 1; iCount++) // Loop thru only twice
    for (iCount = 0; iCount <= 1; iCount++) { // Loop thru only twice	
        dfLL = 0.779072 + 0.00273790931 * dfT
        dfLL = dfLL - Math.floor(dfLL)   // dfLL =  Math.floor(dfLL);
        dfLL = dfLL * 2.0 * Math.PI

        dfGG = 0.993126 + 0.0027377785 * dfT
        dfGG = dfGG - Math.floor(dfGG)        // Math.floor(dfGG);
        dfGG = dfGG * 2.0 * Math.PI

        dfVV = 0.39785 * Math.sin(dfLL) - 0.01 * Math.sin(dfLL - dfGG) + 0.00333 * Math.sin(dfLL + dfGG) - 0.00021 * Math.sin(dfLL) * dfTT
        dfUU = 1 - 0.03349 * Math.cos(dfGG) - 0.00014 * Math.cos(dfLL * 2.0) + 0.00008 * Math.cos(dfLL)

        dfWW = -0.0001 - (0.04129 * Math.sin(dfLL * 2.0))
        dfWW = dfWW + (0.03211 * Math.sin(dfGG))
        dfWW = dfWW - (0.00104 * Math.sin(2.0 * dfLL - dfGG))
        dfWW = dfWW - (0.00035 * Math.sin(2.0 * dfLL + dfGG))
        dfWW = dfWW - (0.00008 * Math.sin(dfGG) * dfTT)

        // Compute Sun's RA and Dec; SUNUP.BAS 1120 - 1140
        dfSS = dfWW / Math.sqrt(dfUU - dfVV * dfVV)
        dfA5 = dfLL + Math.atan(dfSS / Math.sqrt(1.0 - dfSS * dfSS))  // ATAN = ATN

        dfSS = dfVV / Math.sqrt(dfUU)
        dfD5 = Math.atan(dfSS / Math.sqrt(1 - dfSS * dfSS))
        // Set values and increment t
        if (iCount === 0) {  // SUNUP.BAS 125
            dfAA1 = dfA5
            dfDD1 = dfD5
        }
        else { // SUNUP.BAS 145
            dfAA2 = dfA5
            dfDD2 = dfD5
        }
        dfT = dfT + 1.0   // SUNUP.BAS 130
    }	// for
    if (dfAA2 < dfAA1)
        dfAA2 = dfAA2 + 2.0 * Math.PI

    dfZenith = Math.PI * 90.833 / 180.0   // SUNUP.BAS 160
    dfSinLat = Math.sin(dfLat * Math.PI / 180.0)  // SUNUP.BAS 170
    dfCosLat = Math.cos(dfLat * Math.PI / 180.0)   // SUNUP.BAS 170

    dfA0 = dfAA1 // SUNUP.BAS 190
    dfD0 = dfDD1 // SUNUP.BAS 190
    dfDA = dfAA2 - dfAA1 // SUNUP.BAS 200
    dfDD = dfDD2 - dfDD1 // SUNUP.BAS 200

    dfK1 = 15.0 * 1.0027379 * Math.PI / 180.0   // SUNUP.BAS 330

    // Initialize sunrise and sunset times, and other variables
    // hr and min are set to impossible times to make errors obvious
    dfHourRise = 99.0
    dfMinRise = 99.0
    dfHourSet = 99.0
    dfMinSet = 99.0
    dfV0 = 0.0  // initialization implied by absence in SUNUP.BAS
    dfV2 = 0.0  // initialization implied by absence in SUNUP.BAS

    // Test each hour to see if the Sun crosses the horizon
    // and which way it is heading.
    for (iCount = 0; iCount < 24; iCount++) // SUNUP.BAS 210
    {
        dfC0 = parseFloat(iCount)
        dfP = (dfC0 + 1.0) / 24.0    // SUNUP.BAS 220
        dfA2 = dfAA1 + dfP * dfDA  // SUNUP.BAS 230
        dfD2 = dfDD1 + dfP * dfDD  // SUNUP.BAS 230
        dfL0 = dfT0 + dfC0 * dfK1  // SUNUP.BAS 500
        dfL2 = dfL0 + dfK1 // SUNUP.BAS 500
        dfH0 = dfL0 - dfA0 // SUNUP.BAS 510
        dfH2 = dfL2 - dfA2 // SUNUP.BAS 510
        // hour angle at half hour
        dfH1 = (dfH2 + dfH0) / 2.0  // SUNUP.BAS 520
        // declination at half hour
        dfD1 = (dfD2 + dfD0) / 2.0  // SUNUP.BAS 530

        // Set value of dfV0 only if this is the first hour,
        // otherwise, it will get set to the last dfV2 (SUNUP.BAS 250)
        if (iCount === 0)  // SUNUP.BAS 550
            dfV0 = dfSinLat * Math.sin(dfD0) + dfCosLat * Math.cos(dfD0) * Math.cos(dfH0) - Math.cos(dfZenith)  // SUNUP.BAS
        else
            dfV0 = dfV2 // That is, dfV2 from the previous hour.


        dfV2 = dfSinLat * Math.sin(dfD2) + dfCosLat * Math.cos(dfD2) * Math.cos(dfH2) - Math.cos(dfZenith) // SUNUP.BAS

        // if dfV0 and dfV2 have the same sign, then proceed to next hr
        if ((dfV0 >= 0.0 && dfV2 >= 0.0) || (dfV0 < 0.0 && dfV2 < 0.0)) {    // both are positive or negative
            // Break iteration and proceed to test next hour
            dfA0 = dfA2 // SUNUP.BAS 250
            dfD0 = dfD2 // SUNUP.BAS 250
            continue; // SUNUP.BAS 610
        }

        dfV1 = dfSinLat * Math.sin(dfD1) + dfCosLat * Math.cos(dfD1) * Math.cos(dfH1) - Math.cos(dfZenith) // SUNUP.BAS
        tempA = 2.0 * dfV2 - 4.0 * dfV1 + 2.0 * dfV0
        // SUNUP.BAS 600
        tempB = 4.0 * dfV1 - 3.0 * dfV0 - dfV2   // SUNUP.BAS 600
        tempD = tempB * tempB - 4.0 * tempA * dfV0  // SUNUP.BAS 610

        if (tempD < 0.0) {
            // Break iteration and proceed to test next hour
            dfA0 = dfA2 // SUNUP.BAS 250
            dfD0 = dfD2 // SUNUP.BAS 250
            continue; // SUNUP.BAS 610
        }

        tempD = Math.sqrt(tempD) // SUNUP.BAS 620

        // Determine occurence of sunrise or sunset.

        // Flags to identify occurrence during this day are
        // bSunriseToday and bSunsetToday, and are initialized false.
        // These are set true only if sunrise or sunset occurs
        // at any point in the hourly loop. Never set to false.

        // Flags to identify occurrence during this hour:
        bSunrise = false // reset before test
        bSunset = false // reset before test

        if (dfV0 < 0.0 && dfV2 > 0.0) {   // sunrise occurs this hour
            bSunrise = true // SUNUP.BAS 640
            //bSunriseToday = true // sunrise occurred today
        }

        if (dfV0 > 0.0 && dfV2 < 0.0) {   // sunset occurs this hour
            bSunset = true // SUNUP.BAS 660
            //bSunsetToday = true // sunset occurred today
        }

        tempE = (tempD - tempB) / (2.0 * tempA)
        if (tempE > 1.0 || tempE < 0.0)    // SUNUP.BAS 670, 680
            tempE = (-tempD - tempB) / (2.0 * tempA)


        // Set values of hour and minute of sunset or sunrise
        // only if sunrise/set occurred this hour.
        if (bSunrise) {
            dfHourRise = Math.floor(dfC0 + tempE + 1.0 / 120.0)
            dfMinRise = Math.floor((dfC0 + tempE + 1.0 / 120.0 - dfHourRise) * 60.0)
        }

        if (bSunset) {
            dfHourSet = Math.floor(dfC0 + tempE + 1.0 / 120.0)
            dfMinSet = Math.floor((dfC0 + tempE + 1.0 / 120.0 - dfHourSet) * 60.0)
        }

        // Change settings of variables for next loop
        dfA0 = dfA2 // SUNUP.BAS 250
        dfD0 = dfD2 // SUNUP.BAS 250
    }   // end of loop testing each hour for an event

    // After having checked all hours, set flags if no rise or set
    // bSunUpAllDay and bSundownAllDay are initialized as false    
    /*
    if (!bSunriseToday && !bSunsetToday) {
        if (dfV2 < 0.0)
            bSunDownAllDay = true
        else
            bSunUpAllDay = true
    }
    */
    valorOrtoOcaso.dia = dd
    valorOrtoOcaso.mes = mm
    valorOrtoOcaso.ortoHora = dfHourRise
    valorOrtoOcaso.ortoMin = dfMinRise
    valorOrtoOcaso.ocasoHora = dfHourSet
    valorOrtoOcaso.ocasoMin = dfMinSet

    /*
    orto(mm, dd).hora = dfHourRise
    orto(mm, dd).min = dfMinRise
    ocaso(mm, dd).hora = dfHourSet
    ocaso(mm, dd).min = dfMinSet	/* */
    return (valorOrtoOcaso)
}

// Devuelve True si el d�a es de horario de verano
function compr_horario_verano(mmes, ddia, ddia_sem, hhora) // As Integer, ddia As Integer, ddia_sem As Integer, hhora As Integer) As Boolean
{
    //    compr_horario_verano = true
    if ((mmes > 3) && (mmes < 10))
        return true		// VERANO
    if ((mmes < 3) || (mmes > 10))
        return false	// INVIERNO
    if (mmes === 10) {
        if (ddia < 25)
            return true
        // DEL 25 AL 31 DE OCTUBRE
        if (ddia_sem === 7) {    // DOMINGO
            if (hhora >= 3)
                return false // INVIERNO
            return true			// VERANO
        }
        if ((ddia - ddia_sem) > 24)  // NO ES DOMINGO
            return false
        return (true);           // VERANO
    }
    // MES DE MARZO
    if (ddia < 25)
        return false // INVIERNO
    if (ddia_sem === 7) {   // DOMINGO
        if (hhora < 2)
            return false // INVIERNO
        return (true);           // VERANO			
    }
    if ((ddia - ddia_sem) > 24)
        return (true);           // VERANO
    return false // INVIERNO		
    //compr_horario_verano = False: Exit Function     ' INVIERNO
}


const diaSemana = (fecha) => {
    var diaSem // As Byte
    diaSem = fecha.getDay() //      ' Dom = 0, Lun = 1, Mar = 2
    if (diaSem === 0)
        return 7
    return (diaSem)
}

export const calc_too = {

    calcular_too2: function (fecha, GPSLat, GPSLong, despHora) // As Date, GPSLat As Double, GPSLong As Double, esCanarias As Boolean)
    {
        var desp // As Integer
        var esVerano // As Boolean
        var dd, mm, aa // As Integer, mm As Integer, aa As Integer
        var diaSem // As Integer

        dd = fecha.getDate()
        mm = fecha.getMonth() + 1
        aa = fecha.getFullYear()
        diaSem = diaSemana(fecha)         // debe ser 1 si es lunes, .. 7 si es domingo
        //diaSem = Weekday(fecha)

        esVerano = compr_horario_verano(mm, dd, diaSem, 5)  // hora = 5 para que de el cambio del domingo
        if (esVerano)
            desp = 2
        else
            desp = 1


        desp += despHora		// Controla esCanarias
        /*
        if (esCanarias )
            desp--; /* */

        var valorOrtoOcaso = calcular_too(dd, mm, aa, desp, GPSLat, GPSLong)
        return valorOrtoOcaso;
    },

    probar_too: function () {
        var fecha;
        //fecha	= new Date(2016, 1, 1);

        fecha = new Date();
        // for (i= 0 ;  i < 365 ; i++ )
        //{
            var valorOrtoOcaso1 = calc_too.calcular_too2(fecha, 40.4086, -3.6922, 0)		// 0 = 0 desp Madrid
            var valorOrtoOcaso2 = calc_too.calcular_too2(fecha, 39.417902, 3.2640254, 0)		// 0 = 0 desp Felanitx

            console.log("Orto: " + valorOrtoOcaso1.dia + "/" + valorOrtoOcaso1.mes + " " + valorOrtoOcaso1.ortoHora + ":" + valorOrtoOcaso1.ortoMin);
            console.log("Ocaso: " + valorOrtoOcaso2.dia + "/" + valorOrtoOcaso2.mes + " " + valorOrtoOcaso2.ocasoHora + ":" + valorOrtoOcaso2.ocasoMin);

            fecha.setHours(fecha.getHours() + 24);	// Avanzar 1 dia = 24 horas
        //}

    },

}
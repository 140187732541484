import 'leaflet/dist/leaflet.css';
import 'leaflet-easybutton';
import 'leaflet-easybutton/src/easy-button.css';
import { Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import { useEffect, useState } from 'react';

import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

const DefaultIcon = L.icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIconRetina,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

function GeoPosMap() {
  const map = useMap();
  const [userPosition, setUserPosition] = useState(null);

  useEffect(() => {

    const geoButton = L.easyButton({
        states: [{
            stateName: 'set-location', 
            icon: '<i class="fa-solid fa-location-crosshairs"></i>',
            title: '¿Dónde estoy?', 
            onClick: function(btn, map) {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                      (position) => {
                        const { latitude, longitude } = position.coords;
                        setUserPosition([latitude, longitude]);
                        map.flyTo([latitude, longitude], 13);
                      },
                      (error) => {
                        console.error("Error al obtener la posición:", error);
                      }
                    );
                  } else {
                    alert("Geolocalización no soportada en este navegador.");
                  }
            }
        }]
    })

    geoButton.addTo(map);

    return () => {
      geoButton.remove();
    };
  }, [map]);

  return userPosition ? <Marker position={userPosition} /> : null;  
}
export default GeoPosMap;
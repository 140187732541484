



export const selectContracts = (state) => state.contracts.contracts;
export const selectActiveContract = (state) => state.contracts.activeContract;
export const selectPermissions = (state) => state.contracts.permissions;
export const selectContractType = (state) => state.contracts.type;
export const selectOnlyIp = (state) => state.contracts.onlyIp;
export const selectMaintenance = (state) => state.contracts.maintenance;
export const selectU6me2 = (state) => state.contracts.u6me2;
export const selectCodedMains = (state) => state.contracts.coded_mains;
export const selectSuperAdminContracts = (state) => state.contracts.superAdminContracts;



/*
export const selectContracts = (state) => state.contracts.contracts;
export const selectActiveContracts = (state) => state.contracts.activeContracts;
export const selectPermissions = (state) => state.contracts.permissions;
export const selectContractType = (state) => state.contracts.type;
export const selectOnlyIp = (state) => state.contracts.onlyIp;
export const selectMaintenance = (state) => state.contracts.maintenance;
export const selectU6me2 = (state) => state.contracts.u6me2;
export const selectCodedMains = (state) => state.contracts.coded_mains;
export const selectSuperAdminContracts = (state) => state.contracts.superAdminContracts;
*/



import React, { useState, useEffect, useMemo } from 'react';
import { AppBar, Tabs, Tab, Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
import { TabPanel } from '../../common/TabPanel/TabPanel';
import { NodesList } from '../../common/Tables/SelectedLists/NodesList/NodesList';
import { PanelsList } from '../../common/Tables/SelectedLists/PanelsList/PanelsList';
import theme from '../../../material-ui/theme';
import { GatewaysList } from '../../common/Tables/SelectedLists/GatewaysList/GatewaysList';

const a11yProps = (index) => {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
};

export const InventoryDevices = () => {
  const activePanelsItem  = useSelector((state) => state.panels.active);
  const panels = useSelector((state) => state.panels.panels);
  const activeNodesItem = useSelector((state) => state.nodes.active);
  const nodes  = useSelector((state) => state.nodes.nodes);
  const activeGatewaysItem = useSelector((state) => state.gateways.active);
  const gateways = useSelector((state) => state.gateways.gateways);

  const [value, setValue] = useState(0);


  const selectActiveItems = (allItems, activeItems) => {
    const activeIds = activeItems.map(item => item.id);
    

    const activeItemsWithFullInfo = activeIds
      .map(id => allItems.find(item => item.id === id))
      .filter(item => item);  


    const nonActiveItems = allItems.filter(item => !activeIds.includes(item.id));
    
    return [...activeItemsWithFullInfo, ...nonActiveItems];
  };

  const dataPanels = useMemo(() => {
    return selectActiveItems(panels, activePanelsItem);
  }, [panels, activePanelsItem]);

  const dataNodes = useMemo(() => {
    return selectActiveItems(nodes, activeNodesItem);
  }, [nodes, activeNodesItem]);

  const dataGateways = useMemo(() => {
    return selectActiveItems(gateways, activeGatewaysItem);
  }, [gateways, activeGatewaysItem]);

  const panelColumns = [
    { id: 'select', label: '' },
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'Nombre' },
    { id: 'firmware_version', label: 'Versión de Firmware' },
    { id: 'serial_number_ucc', label: 'Serial Number UCC' },
    { id: 'last_download', label: 'Last Download' },
    { id: 'address', label: 'Address' },
    { id: 'active_alarms', label: 'Active Alarms' },
    { id: 'number_of_circuits', label: 'Number of Circuits' },
    { id: 'status_flag', label: 'Status Flag' },
    { id: 'alarm_send', label: 'Alarm Send' },
    { id: 'identification', label: 'Identification' },
    { id: 'deleteAndSummary', label: '' }
  ];

  const nodeColumns = [
    { id: 'select', label: '' },
    { id: 'id', label: 'ID' },
    { id: 'name', label: 'Nombre' },
    { id: 'firmware_version', label: 'Versión de Firmware' },
    { id: 'ip', label: 'Dirección IP' },
    { id: 'last_gateway_connection', label: 'Last Gateway Connection' },
    { id: 'status_flag', label: 'Status Flag' },
    { id: 'serial_number', label: 'Serial Number' },
    { id: 'icc', label: 'ICC' },
    { id: 'eui', label: 'EUI' },
    { id: 'deleteAndSummary', label: '' }
  ];

  const gatewayColumns = [
    { id: 'select', label: '' },
    { id: 'id', label: 'ID' },
    { id: 'gateway_number', label: 'Gateway Number' },
    { id: 'status_date', label: 'Status Date' },
    { id: 'dimming_profile_id', label: 'Dimming Profile ID' },
    { id: 'exception_profile_id', label: 'Exception Profile ID' },
    { id: 'status_flag', label: 'Status Flag' },
    { id: 'serial_number', label: 'Serial Number' },
    { id: 'deleteAndSummary', label: '' }
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const tabs = useMemo(() => [
    {
      title: 'Centros de mando',
      icon: <Avatar src={theme.devicesIcons?.panel?.avatar} sx={{ borderRadius: 0 }} />,
      panel: <PanelsList data={dataPanels} columns={panelColumns} />
    },
    {
      title: 'Nodos',
      icon: <Avatar src={theme.devicesIcons?.node?.avatar} sx={{ borderRadius: 0 }} />,
      panel: <NodesList data={dataNodes} columns={nodeColumns} />
    },
    {
      title: 'Gateways',
      icon: <Avatar src={theme.devicesIcons?.gateway?.avatar} sx={{ borderRadius: 0 }} />,
      panel: <GatewaysList data={dataGateways} columns={gatewayColumns} />
    }
  ], [dataPanels, dataNodes, dataGateways]);

  useEffect(() => {
    if (value >= tabs.length) {
      setValue(0);
    }
  }, [value, tabs]);

  return (
    <div style={{ flexGrow: 1, width: '100%', backgroundColor: theme.palette.background.paper }}>
      <AppBar position="static" color="default" sx={{ marginBottom: theme.spacing(1) }}>
        <Tabs
          value={value >= tabs.length ? 0 : value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          {tabs.map((e, i) => (
            <Tab key={e.title} label={e.title} icon={e.icon} {...a11yProps(i)} />
          ))}
        </Tabs>
      </AppBar>

      {tabs.map((e, i) => (
        <TabPanel key={e.title} value={value} index={i}>
          {e.panel}
        </TabPanel>
      ))}
    </div>
  );
};

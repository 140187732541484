import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CodeIcon from '@mui/icons-material/Code';
import UploadIcon from '@mui/icons-material/Upload';
import { useForm } from '../../hooks/useForm';
import { useSocketIo } from '../../hooks/useSocketIo';
import { color } from '../../helpers/socket';
import { CustomTitle } from '../common/CustomTitle/CustomTitle';
import { uiFinishLoading, uiStartLoading } from '../../reducers/ui/uiReducer';
import {
    Avatar,
    Container,
    Grid,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    Button,
    Divider,
    Checkbox,
    Tooltip
} from '@mui/material';
import { NodesTelechargeNBIoTResult } from './NodesTelechargeNBIoTResult/NodesTelechargeNBIoTResult';
import { NodesTelechargeNBIoTCommand } from './NodesTelechargeNBIoTCommand/NodesTelechargeNBIoTCommand';

export const NodesTelechargeNBIoTScreen = () => {
    const topicsReady = useRef(false);

    const [values, handleInputChange, reset] = useForm({
        command: 'LIST',
        path: '',
        payload: '',
        topic: 'files'
    })

    const { result, status, send, subscribe, history, handleChangeResult } = useSocketIo()

    const [check, setCheck] = useState(false)
    const loading = useSelector(state => state.ui.loading)
    const onlyIp = useSelector(state => state.contracts.onlyIp)
    const activeContract = useSelector(state => state.contracts.activeContract)

    const dispatch = useDispatch()


    // Enviar eventos al servidor Socket.io
    const handleSend = async () => {
        dispatch(uiStartLoading());
        if (values.topic === 'files') {
            await send(values.topic, {
                command: values.command,
                path: values.path,
                payload: values.payload
            })
        }
    }

    const handleCancel = async () => {
        await dispatch(uiFinishLoading())
    }

    useEffect(() => {
        if (!topicsReady.current && status === "connected") {
            topicsReady.current = true;
            subscribe(
                [
                    `contracts/${activeContract.id}/files`
                ],
                () => {
                    dispatch(uiStartLoading());
                    setTimeout(async () => {
                        await send("files", {
                            command: "LIST",
                            path: "",
                            payload: ""
                        })
                    }, 2000);
                }
            );
        }
    }, [activeContract, subscribe, status, send, dispatch]);

    useEffect(() => {
        dispatch(uiFinishLoading());
    }, [result, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(uiFinishLoading());
        };
    }, [dispatch]);

    return (
        <Container maxWidth={false}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <CustomTitle
                        title="Telecarga Nodos NBIoT"
                        icon={
                            <Avatar>
                                <UploadIcon />
                            </Avatar>
                        }
                    />
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                title={`Telecarga Nodos NBIoT - ${status}`}
                                subheader='Lee, modifica, crea y borra archivos del sistema.'
                                avatar={
                                    <Avatar style={{ backgroundColor: color(status) }}>
                                        <CodeIcon />
                                    </Avatar>
                                }
                            />
                            <Divider />
                            <CardContent>

                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} lg={6}>
                                        <NodesTelechargeNBIoTResult result={result} reset={reset} handleChangeResult={handleChangeResult} send={send} history={history} />
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={6} lg={6}>
                                        <NodesTelechargeNBIoTCommand handleInputChange={handleInputChange} result={result} values={values}/>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <Divider />
                            <CardActions>
                                <Tooltip title={"¿Estas segur@? La operación que realice puede repercutir negativamente en el buen funcionamiento del sistema."}>
                                    <Checkbox checked={check} onChange={() => setCheck(c => !c)} color="success" ></Checkbox>
                                </Tooltip>
                                <Button
                                    onClick={handleSend}
                                    disabled={!onlyIp || loading || !check}
                                    color='primary'
                                    variant='contained'
                                    fullWidth
                                >
                                    Enviar
                                </Button>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    disabled={!onlyIp || !loading}
                                    onClick={handleCancel}
                                >
                                    Cancelar
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Container >
    )
}



export const loadStatusNodeText = (flag, node) => {
    const status_flag_text = [
        "",
        "{{name}} is being evaluated and sent to", { name: node.name },
        "¡{{name}} pending elimination!", { name: node.name },
    ]
    return status_flag_text[flag];
}

export const testFlagNode = (flag) => (flag === 0);

export const canIUpdateNode = (flag) => {
    if(testFlagNode(flag)){
        return true;
    } else {
        return false;
    }
}
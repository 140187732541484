

export const loadStatusPanelText = (flag, panel) => {
    const status_flag_text = [
        "",
        ("{{name}} is being evaluated and sent to", { name: panel.name }),
        ("¡{{name}} pending elimination!", { name: panel.name }),
    ]
    return status_flag_text[flag];
}

export const testFlagPanel = (flag) => (flag === 0);

export const canIUpdatePanel = (flag) => {
    if(testFlagPanel(flag)){
        return true;
    } else {
        return false;
    }
}


import { createAsyncThunk } from '@reduxjs/toolkit';
import { io } from "socket.io-client";
import moment from "moment";
import { Buffer } from 'buffer';
import { api } from "../../api/api";
import { socketConnect, socketDisconnect, socketSetHistory, socketSetResult, socketSetStatus, socketSetTopic } from './socketReducer';
import Swal from 'sweetalert2';
import { purpleTheme } from '../../material-ui/theme';

let socket = null;

// Acción para cennectar el Socket
export const startSocketConnect = createAsyncThunk(
    'socket/connect',
    async (_, { getState, dispatch }) => {
        try {
            //console.log('SOCKET 0 : ', socket)
            if (socket) socket.disconnect(); // Desconecta si ya existe una conexión
            const activeContract = getState().contracts.activeContract; // Obtén el contrato activo
            const token = localStorage.getItem("token") || "";

            socket = io(`${api().data.replace('/api', '')}`, {
                path: '/api/mqtt',
                transports: ['websocket'],
                auth: {
                    username: token,
                    password: '',
                    contract_id: activeContract.id,
                },
                reconnection: true,
                reconnectionAttempts: 50,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
            });
            //console.log('SOCKET 1 : ', socket)

            socket.on("connect", () => {
                //console.log('SOCKET 2 : ', socket)
                dispatch(socketConnect())
            });
            socket.on("disconnect", () => {
                //console.log("SOCKET 3 : ", socket)
                dispatch(socketDisconnect())
            });
            socket.on("error", (error) => dispatch(socketSetStatus("error")));
            socket.on("reconnect", (attempt) => dispatch(socketSetStatus(`reconnect ${attempt}`)));
            socket.on("reconnect_failed", () => dispatch(socketSetStatus("reconnect_failed")));
        } catch (err) {
            console.error(err)
        }
    }
);

// Acción para enviar un mensaje por el socket
export const startSocketSend = createAsyncThunk(
    'socket/send',
    async ({ topic, data }) => {
        if (socket) {
            socket.emit(topic, data);
        } else {
            console.warn("No socket connection available to send data.");
        }
    }
);



// Acción para desconectar el socket
export const startSocketDisconnect = createAsyncThunk(
    'socket/disconnect',
    async (_, { dispatch }) => {
        if (socket) {
            socket.disconnect();
            socket = null;
        }
        dispatch(socketDisconnect());
    }
);

// Acción para activar un usuario por ID
export const startSocketSubscribe = createAsyncThunk(
    'socket/subscribe',
    async ({ topics, callback, handleResult }, { dispatch, getState }) => {
        const currentTopics = getState().socket.topics
        try {
            const status = getState().socket.status; // Obtén el contrato activo
            if (!socket) {
                await dispatch(startSocketConnect())
            }

            if (socket && status === "connected") {
                topics.filter(e => currentTopics.indexOf(e) === -1).forEach((topic) => {
                    socket.on(topic, (lastJsonMessage) => {
                        let result;
                        if (Buffer.isBuffer(lastJsonMessage)) {
                            result = lastJsonMessage.toString("utf8");
                        } else if (lastJsonMessage instanceof ArrayBuffer) {
                            const buffer = Buffer.from(lastJsonMessage);
                            result = buffer.toString("utf8");
                        } else {
                            result = JSON.parse(JSON.stringify(lastJsonMessage));
                        }

                        const data = {
                            topic,
                            typeof: typeof result,
                            date: moment().format("YYYY-MM-DD HH:mm:ss"),
                            result,
                        };
                        if (handleResult) handleResult(data)
                        dispatch(socketSetResult(data)); // Actualiza el resultado
                        dispatch(socketSetHistory(data)); // Guarda el historial
                    });
                });
                dispatch(socketSetTopic(topics.filter(e => currentTopics.indexOf(e) === -1)))
                if (callback) callback(); // Llama al callback si está definido
            }
        } catch (err) {
            console.error(err)
        }
    }
);

// Acción para activar un usuario por email
export const startSocketUnSubscribe = createAsyncThunk(
    'socket/unsubscribe',
    async ({ topics, callback }, { dispatch, getState }) => {
        if (socket) {
            const currentTopics = getState().socket.topics
            topics.filter(e => currentTopics.indexOf(e) !== -1).forEach((topic) => {
                socket.off(topic, callback);
            });
            dispatch(socketSetTopic(topics.filter(e => currentTopics.indexOf(e) !== -1)))
        }
    }
);

// Acción para activar un usuario por ID
export const startChangeResult = createAsyncThunk(
    'socket/changeResult',
    async (result, { dispatch }) => {
        if (socket) {
            dispatch(socketSetResult(result));
        }
    }
);


// Acción para activar un usuario por ID
export const startSocketPing = createAsyncThunk(
    'socket/changeResult',
    async ({ ip, name }, { dispatch, getState }) => {
        const topics = getState().socket.topics
        const topic = `/ping/${ip}`
        if (socket) {
            if (topics.indexOf(topic) === -1) {
                await dispatch(startSocketSubscribe({
                    topics: [topic],
                    callback: async () => {
                        Swal.fire({
                            toast: true,
                            position: "bottom-left",
                            title: 'Loading...',
                            text: 'Please wait',
                            allowOutsideClick: false,
                            showConfirmButton: true,
                            willOpen: () => {
                                Swal.showLoading()
                            },
                            confirmButtonColor: purpleTheme.palette.secondary.main,
                            confirmButtonText: "FINALIZAR PINGs",
                            showClass: {
                                popup: '',  // Sin animación para mostrar el popup
                            },
                            hideClass: {
                                popup: '',  // Sin animación para ocultar el popup
                            },
                            didOpen: () => {
                                // Aplicamos el estilo en línea directamente al botón
                                const confirmButton = document.querySelector('.swal2-confirm');
                                if (confirmButton) {
                                    confirmButton.style.width = '100%'; // Establece el ancho completo del botón
                                }
                            },
                        }).then(async (result) => {
                            // Este bloque se ejecuta cuando el usuario interactúa con el botón
                            if (result.isConfirmed) {
                                // Acción al confirmar
                                await dispatch(startSocketSend({ topic: '/ping', data: "kill" }))
                                await dispatch(startSocketUnSubscribe({
                                    topics: [topic], callback: () => {
                                        console.log("UNSUBSCRIBED")
                                    }
                                }))
                                Swal.fire('Acción realizada', 'Los PING han finalizado', 'success');
                            }
                        });                            
                        await dispatch(startSocketSend({ topic: '/ping', data: ip }))
                    },
                    handleResult: (result) => {
                        Swal.fire({
                            toast: true,
                            position: "bottom-left",
                            titleText: `PING ==> ${name}`,
                            text: result?.result,
                            confirmButtonColor: purpleTheme.palette.secondary.main,
                            confirmButtonText: "FINALIZAR PINGs",
                            showClass: {
                                popup: '',  // Sin animación para mostrar el popup
                            },
                            hideClass: {
                                popup: '',  // Sin animación para ocultar el popup
                            },
                            didOpen: () => {
                                // Aplicamos el estilo en línea directamente al botón
                                const confirmButton = document.querySelector('.swal2-confirm');
                                if (confirmButton) {
                                    confirmButton.style.width = '100%'; // Establece el ancho completo del botón
                                }
                            },
                        }).then(async (result) => {
                            // Este bloque se ejecuta cuando el usuario interactúa con el botón
                            if (result.isConfirmed) {
                                // Acción al confirmar
                                await dispatch(startSocketSend({ topic: '/ping', data: "kill" }))
                                await dispatch(startSocketUnSubscribe({
                                    topics: [topic], callback: () => {
                                        console.log("UNSUBSCRIBED")
                                    }
                                }))
                                Swal.fire('Acción realizada', 'Los PING han finalizado', 'success');
                            }
                        });
                    }
                }))
            } else {
                await dispatch(startSocketSend({ topic: '/ping', data: ip }))
            }
        }
    }
);

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PanelsMarker from './PanelsMarker/PanelsMarker'
import MarkerClusterGroup from 'next-leaflet-cluster'
import validator from 'validator'
import { mapFinishLoadingPanelsLayer, mapStartLoadingPanelsLayer } from '../../../../reducers/map/mapReducer'
import { thereIsAFail } from '../../../../helpers/common'

const PanelsLayer = React.memo(() => {
  const panels = useSelector(state => state.panels.panels)
  const active = useSelector(state => state.panels.active)
  const [renderedMarkersCount, setRenderedMarkersCount] = useState(0);
  const failure_window_in_hours_panels = useSelector(
    state => state.contracts.activeContract.failure_window_in_hours_panels
  )
  const dispatch = useDispatch()

  const filteredPanels = useMemo(
    () =>
      panels
        .map(e => ({
          ...e,
          possition: [parseFloat(e.latitude), parseFloat(e.longitude)]
        }))
        .filter(e => {
          return validator.isLatLong(`(${e.possition[0]}, ${e.possition[1]})`)
        }),
    [panels]
  )

  useEffect(() => {
    if (renderedMarkersCount >= filteredPanels.length) {
      dispatch(mapFinishLoadingPanelsLayer());
    } else {
      dispatch(mapStartLoadingPanelsLayer());
    }
  }, [renderedMarkersCount, filteredPanels.length, dispatch]);

  const handleMarkerRendered = useCallback(() => {
    setRenderedMarkersCount(prevCount => prevCount + 1);
  }, [setRenderedMarkersCount]);

  return (
    <MarkerClusterGroup
      disableClusteringAtZoom={17}
      chunkedLoading
      maxClusterRadius={50}
      spiderfyOnMaxZoom={true}
      showCoverageOnHover={true}
    >
      {filteredPanels &&
        filteredPanels.map(
          (panel, index) =>
            panel && (
              <PanelsMarker
                key={index}
                possition={panel.possition}
                data={panel}
                active={
                  active.find(e => parseInt(e.id) === parseInt(panel.id))
                    ? true
                    : false
                }
                fail={thereIsAFail(
                  panel.last_download,
                  failure_window_in_hours_panels
                )}
                alarm={panel.active_alarms !== 0}
                onRender={handleMarkerRendered}
              />
            )
        )}
    </MarkerClusterGroup>
  )
})

export default PanelsLayer



import { Alert } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { Button, ButtonGroup, Chip, Grid, Typography } from '@mui/material';
import {
  getChips,
  ifError
} from '../../../helpers/panels/telechargeStatus/telechargeStatus';
import { panelsActive, panelsRemoveActive } from '../../../reducers/panels/panelsReducer';
import { PanelsOptionsMenu } from '../../../components/common/Options/PanelsOptionsMenu/PanelsOptionsMenu';

const DisplayResponseStatus = React.memo(({ result }) => {
  const panels = useSelector((state) => state.panels.panels);
  const dispatch = useDispatch();
  let render = false;
  let summary = {
    allDevicesInvolved: [],
    successDevices: [],
    errorDevices: [],
  };

  if (
    result &&
    result.result &&
    Array.isArray(result.result.msg) && // Validamos que msg es un array
    result.topic.indexOf('status') !== -1
  ) {
    render = true;
    summary.allDevicesInvolved = panels.filter((e) =>
      result.result.msg.find((item) => item.ip === e.ip)
    );
    summary.successDevices = summary.allDevicesInvolved.filter(
      (e) => !ifError(result.result.msg.find((item) => item.ip === e.ip)?.mascara)
    );
    summary.errorDevices = summary.allDevicesInvolved.filter((e) =>
      ifError(result.result.msg.find((item) => item.ip === e.ip)?.mascara)
    );
  }

  const handleSelectAllDevices = () => {
    dispatch(panelsRemoveActive());
    dispatch(panelsActive(summary.allDevicesInvolved.map((e) => ({ id: e.id }))));
  };

  const handleSelectAllSuccessDevices = () => {
    dispatch(panelsRemoveActive());
    dispatch(panelsActive(summary.successDevices.map((e) => ({ id: e.id }))));
  };

  const handleSelectAllErrorDevices = () => {
    dispatch(panelsRemoveActive());
    dispatch(panelsActive(summary.errorDevices.map((e) => ({ id: e.id }))));
  };

  return (
    <>
      {render && (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h4">
              Resultados ({summary.successDevices.length}/{summary.allDevicesInvolved.length}):
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <ButtonGroup>
              <Button onClick={handleSelectAllDevices}>Seleccionar equipos implicados</Button>
              {summary.successDevices.length > 0 && (
                <Button onClick={handleSelectAllSuccessDevices}>Seleccionar equipos exitosos</Button>
              )}
              {summary.errorDevices.length > 0 && (
                <Button onClick={handleSelectAllErrorDevices}>Seleccionar equipos no exitosos</Button>
              )}
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} style={{ overflow: 'auto', maxHeight: 500 }}>
            {Array.isArray(result.result?.msg) ? (
              result.result.msg.map((e, i) => {
                const device = panels.find((item) => item.ip === e.ip);
                if (!device) {
                  return null;
                }
                return (
                  <Alert
                    severity={ifError(e?.mascara) ? 'error' : 'success'}
                    key={i}
                    style={{ margin: 3 }}
                  >
                    {device?.name}, {device?.ip}: {e.result}{' '}
                    {getChips(e.mascara).map((chip, indx) => (
                      <Chip
                        key={`${i}-${indx}`}
                        size="small"
                        label={chip.name}
                        style={{ margin: 3 }}
                        icon={<SystemUpdateAltIcon />}
                        disabled={!chip.status}
                      />
                    ))}
                    <PanelsOptionsMenu id={device.id} />
                  </Alert>
                );
              })
            ) : (
              <Typography variant="body1" color="error">
                {result.result?.msg || 'No hay datos disponibles.'}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
});

export default DisplayResponseStatus;



/*
import { Alert } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt'
import { Button, ButtonGroup, Chip, Grid, Typography } from '@mui/material';
import {
  getChips,
  ifError
} from '../../../helpers/panels/telechargeStatus/telechargeStatus'
import { panelsActive, panelsRemoveActive } from '../../../reducers/panels/panelsReducer'
import { PanelsOptionsMenu } from '../../../components/common/Options/PanelsOptionsMenu/PanelsOptionsMenu'

const DisplayResponseStatus = React.memo(({ result }) => {
  const panels = useSelector(state => state.panels.panels);
  const dispatch = useDispatch();
  let render = false;
  let summary = {
    allDevicesInvolved: [],
    successDevices: [],
    errorDevices: []
  };

  if (
    result &&
    result.result &&
    result.result.msg &&
    result.result.msg.length &&
    result.topic.indexOf('status') !== -1
  ) {
    render = true;
    summary.allDevicesInvolved = panels.filter(e =>
      result.result.msg.find(item => item.ip === e.ip)
    );
    summary.successDevices = summary.allDevicesInvolved.filter(
      e => !ifError(result.result.msg.find(item => item.ip === e.ip).mascara)
    );
    summary.errorDevices = summary.allDevicesInvolved.filter(e =>
      ifError(result.result.msg.find(item => item.ip === e.ip).mascara)
    );
  }

  const handleSelectAllDevices = () => {
    dispatch(panelsRemoveActive());
    dispatch(panelsActive(summary.allDevicesInvolved.map(e => ({ id: e.id }))));
  };

  const handleSelectAllSuccessDevices = () => {
    dispatch(panelsRemoveActive());
    dispatch(panelsActive(summary.successDevices.map(e => ({ id: e.id }))));
  };

  const handleSelectAllErrorDevices = () => {
    dispatch(panelsRemoveActive());
    dispatch(panelsActive(summary.errorDevices.map(e => ({ id: e.id }))));
  };

  return (
    <>
      {render && (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant='h4'>
              Resultados ({summary.successDevices.length}/{summary.allDevicesInvolved.length}):
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <ButtonGroup>
              <Button onClick={handleSelectAllDevices}>Seleccionar equipos implicados</Button>
              {summary.successDevices.length > 0 && (
                <Button onClick={handleSelectAllSuccessDevices}>Seleccionar equipos exitosos</Button>
              )}
              {summary.errorDevices.length > 0 && (
                <Button onClick={handleSelectAllErrorDevices}>Seleccionar equipos no exitosos</Button>
              )}
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} style={{ overflow: 'auto', maxHeight: 500 }}>
            {result.result.msg.map((e, i) => {
              const device = panels.find(item => item.ip === e.ip);
              if (!device) {
                return null;
              }
              return (
                <Alert
                  severity={ifError(e?.mascara) ? 'error' : 'success'}
                  key={i}
                  style={{ margin: 3 }}
                >
                  {device?.name}, {device?.ip}: {e.result}{' '}
                  {getChips(e.mascara).map((chip, indx) => (
                    <Chip
                      key={`${i}-${indx}`}
                      size='small'
                      label={chip.name}
                      style={{ margin: 3 }}
                      icon={<SystemUpdateAltIcon />}
                      disabled={!chip.status}
                    />
                  ))}
                  <PanelsOptionsMenu id={device.id} />
                </Alert>
              );
            })}
          </Grid>
        </Grid>
      )}
    </>
  );
});

export default DisplayResponseStatus;
*/
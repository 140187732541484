


import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';


const RootDiv = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <RootDiv
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
    </RootDiv>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



import React, { useState, useMemo } from 'react';
import { Grid, TextField, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CustomTable } from '../../common/Tables/CustomTable/CustomTable';


export const ContractsList = ({ onSelectedContractsChange, showSearch = true }) => {
  // Obtener contratos desde Redux
  const contracts = useSelector((state) => state.contracts.contracts);

  const [search, setSearch] = useState('');
  const [selectedContracts, setSelectedContracts] = useState([]);

  const handleSelect = (contract, isChecked) => {
    setSelectedContracts((prevSelected) =>
      isChecked
        ? [...prevSelected, contract]
        : prevSelected.filter((selected) => selected.id !== contract.id)
    );
    onSelectedContractsChange &&
      onSelectedContractsChange(
        isChecked
          ? [...selectedContracts, contract]
          : selectedContracts.filter((selected) => selected.id !== contract.id)
      );
  };

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredData = useMemo(() => {
    return contracts.filter((contract) =>
      search.length === 0
        ? true
        : ['name', 'type', 'id'].some((key) =>
            String(contract[key] || '')
              .toLowerCase()
              .includes(search.toLowerCase())
          )
    );
  }, [contracts, search]);

  const allSelected = useMemo(() => {
    return (
      filteredData.length > 0 &&
      filteredData.every((contract) =>
        selectedContracts.some((selected) => selected.id === contract.id)
      )
    );
  }, [filteredData, selectedContracts]);

  const columns = [
    { id: 'select', label: '' },
    { id: 'id', label: 'ID Contrato' },
    { id: 'name', label: 'Nombre del Contrato' },
    { id: 'type', label: 'Tipo de Contrato' },
  ];

  return (
    <Grid container spacing={2} className="animate__animated animate__fadeIn animate__fast">
      {showSearch && (
        <Grid item sm={5}>
          <TextField
            id="search"
            label="Buscar"
            type="search"
            name="search"
            autoComplete="off"
            value={search}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
      )}

      <Grid item style={{ width: '100%', marginTop: '16px' }}>
        <CustomTable
          rows={filteredData.map((contract) => ({
            ...contract,
            select: (
              <Checkbox
                checked={selectedContracts.some((selected) => selected.id === contract.id)}
                onChange={(event) => handleSelect(contract, event.target.checked)}
              />
            ),
          }))}
          columns={columns}
        />
      </Grid>
    </Grid>
  );
};

ContractsList.propTypes = {
  onSelectedContractsChange: PropTypes.func,
  showSearch: PropTypes.bool,
};

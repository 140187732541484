

import React, { useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Tooltip } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { startLogout } from '../../reducers/auth/authActions';
import logo from '../../assets/png/tellink1920.png';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTonightSunsetSunrise } from '../../hooks/useTonightSunsetSunrise';
import { AdvancedList } from './AdvancedList/AdvancedList';
import { UsersMenu } from './UsersMenu/UsersMenu';


const drawerWidth = 350;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    variants: [
        {
            props: ({ open }) => open,
            style: {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
    ],
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        variants: [
            {
                props: ({ open }) => open,
                style: {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': openedMixin(theme),
                },
            },
            {
                props: ({ open }) => !open,
                style: {
                    ...closedMixin(theme),
                    '& .MuiDrawer-paper': closedMixin(theme),
                },
            },
        ],
    }),
);

export const NavBar = ({ children }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { sunrise, sunset } = useTonightSunsetSunrise(true);
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        dispatch(startLogout());
        navigate('/login');
    };

    const handleLogoClick = () => {
        navigate('/dashboard');
    };

    
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    

    const handleMenuClose = useCallback(
        () => {
            setAnchorEl(null);
        },
        [setAnchorEl],
    );

    const menuId = 'primary-search-account-menu';

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}
                style={{
                    backgroundColor: 'white'
                }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={[
                            {
                                marginRight: 5,
                            },
                            open && { display: 'none' },
                        ]}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img
                        src={logo}
                        alt="Logo"
                        onClick={handleLogoClick}
                        style={{ height: '40px', cursor: 'pointer' }}
                    />
                    {(sunset && sunrise) && (
                        <Tooltip title="Ocaso/Orto" style={{ marginLeft: 'auto' }}>
                            <div>
                                <Typography variant='caption' display='block' align='right' color='textPrimary'>
                                    {`🌚 ${sunset.format('HH:mm')}`}
                                </Typography>
                                <Typography variant='caption' display='block' align='right' color='textPrimary'>
                                    {`🌞 ${sunrise.format('HH:mm')}`}
                                </Typography>
                            </div>
                        </Tooltip>
                    )}

                    <div>
             

                        <Tooltip title="Opciones"> 
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen} 
                            >
                                <AccountCircleIcon />
                            </IconButton>
                        </Tooltip>

                        <UsersMenu
                            anchorEl={anchorEl}
                            menuId={menuId}
                            isMenuOpen={isMenuOpen}
                            handleMenuClose={handleMenuClose} 
                        />
                    

                    <UsersMenu anchorEl={anchorEl} menuId={menuId} isMenuOpen={isMenuOpen} handleMenuClose={handleMenuClose} />

                    </div>

                    <Tooltip title="Cerrar sesión">
                        <LogoutIcon
                            variant="contained"
                            onClick={handleLogout}
                            style={{ marginLeft: '15px', cursor: 'pointer' }}
                        />
                    </Tooltip>

                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <AdvancedList />
            </Drawer>


            {children}


        </Box>
    );
}
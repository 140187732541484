


import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  nodes: [],
  active: [],
  activeItem: [], // Aunque no se use más, se mantiene por compatibilidad estructural.
};

const nodesSlice = createSlice({
  name: 'nodes',
  initialState,
  reducers: {
    nodesLoad: (state, action) => {
      const newNodes = action.payload.filter(
        newNode => !state.nodes.some(existingNode => existingNode.id === newNode.id)
      );
      state.nodes = [...state.nodes, ...newNodes];
    },

    nodesActive: (state, action) => {
      state.active = [
        ...action.payload
          .filter(element => !state.active.some(e => e.id === Number(element.id)))
          .map(element => ({ id: Number(element.id) })),
        ...state.active.filter(element => !action.payload.some(e => Number(e.id) === element.id))
      ];
    },

    nodesUpdate: (state, action) => {
      const updatedNodes = action.payload;
      state.nodes = state.nodes.map(n =>
        updatedNodes.find(up => up.id === n.id) || n
      );
      state.active = state.active.map(n =>
        updatedNodes.find(up => up.id === n.id) || n
      );
    },

    nodesRemoveActive: (state) => {
      state.active = [];
    },

    nodesRemoveActiveById: (state, action) => {
      const itemId = Number(action.payload);
      state.active = state.active.filter(item => item.id !== itemId);
    },

    nodesActiveItem: (state, action) => {
      const newItem = { id: Number(action.payload.id) };
      const exists = state.active.some(item => item.id === newItem.id);

      if (!exists) {
        state.active = [...state.active, newItem];
      }
    },

    nodesRemoveActiveItemById: (state, action) => {
      const itemId = Number(action.payload);
      state.active = state.active.filter(item => item.id !== itemId);
    },

    nodesRemoveActiveItem: (state) => {
      state.active = [];
    },

    nodesLogoutCleaning: () => initialState,
  },
});

export const {
  nodesLoad,
  nodesActive,
  nodesUpdate,
  nodesRemoveActive,
  nodesActiveItem,
  nodesRemoveActiveItemById,
  nodesRemoveActiveItem,
  nodesLogoutCleaning,
} = nodesSlice.actions;

export default nodesSlice.reducer;





/*
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  nodes: [],
  active: [],
  activeItem: [], // Cambiado de null a array para mantener múltiples selecciones
};

const nodesSlice = createSlice({
  name: 'nodes',
  initialState,
  reducers: {
    nodesLoad: (state, action) => {
      const newNodes = action.payload.filter(
        newNode => !state.nodes.some(existingNode => existingNode.id === newNode.id)
      );
      state.nodes = [...state.nodes, ...newNodes];
    },
    nodesActive: (state, action) => {
      state.active = [
        ...action.payload.filter(element => !state.active.find(e => e.id === element.id)),
        ...state.active.filter(element => !action.payload.find(e => e.id === element.id))
      ];
    },
    nodesUpdate: (state, action) => {
      const updatedNodes = action.payload;
      state.nodes = state.nodes.map(n =>
        updatedNodes.find(up => up.id === n.id) || n
      );
      state.active = state.active.map(n =>
        updatedNodes.find(up => up.id === n.id) || n
      );
    },
    nodesRemoveActive: (state) => {
      state.active = [];
    },
    nodesActiveItem: (state, action) => {
      const newItem = action.payload;
      const exists = state.activeItem.some(item => item.id === newItem.id);
      
      // Si no existe, agregarlo al array
      if (!exists) {
        state.activeItem = [...state.activeItem, newItem];
      }
    },
    nodesRemoveActiveItemById: (state, action) => {
      const itemId = action.payload;
      state.activeItem = state.activeItem.filter(item => item.id !== itemId);
    },
    nodesRemoveActiveItem: (state) => {
      state.activeItem = [];
    },
    nodesLogoutCleaning: () => initialState,
  },
});

export const {
  nodesLoad,
  nodesActive,
  nodesUpdate,
  nodesRemoveActive,
  nodesActiveItem,
  nodesRemoveActiveItemById,
  nodesRemoveActiveItem,
  nodesLogoutCleaning,
} = nodesSlice.actions;

export default nodesSlice.reducer;
*/















